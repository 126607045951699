import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles, Button, Grid, Hidden } from "@material-ui/core"
import ModalComponent from "../../utils/components/modal"
import { EmptyCard } from "../../utils/components/misc"
import AstrologerListCard from "./components/listCard"
import AstrologerListFilter from "./components/filter"
import AstrologerTopBar from "./components/topBar"
import { prioritizeAstrologers } from "../../utils/functions"

class AstrologerList extends Component {
  state = {
    modalOpen: false,
    filter: {
      name: "",
      city: [],
      language: [],
    },
    data: [],
  }

  componentDidMount() {
    const { astrologers } = this.props
    const FIVE_MINUTES = 300000
    const temp = prioritizeAstrologers(astrologers)
    this.setState({ data: temp })

    this.refreshIntervalID = setInterval(() => {
      const temp = prioritizeAstrologers(astrologers)
      this.setState({ data: temp })
    }, FIVE_MINUTES)
  }

  componentWillMount() {
    clearInterval(this.refreshIntervalID)
  }

  _setState = (newState) => this.setState(newState)

  applyStringFilter = (name, val) => {
    const { filter } = this.state
    filter[name] = val
    this.setState({ filter })
  }

  applyArrayFilter = (name, val) => {
    const { filter } = this.state
    const index = filter[name].indexOf(val)
    if (index > -1) filter[name].splice(index, 1)
    else filter[name].push(val)
    this.setState({ filter })
  }

  renderFilter = () => {
    const { filter } = this.state

    return (
      <AstrologerListFilter
        applyStringFilter={this.applyStringFilter}
        applyArrayFilter={this.applyArrayFilter}
        filter={Object.assign({}, filter)}
      />
    )
  }

  renderList = () => {
    const { classes } = this.props
    const { data } = this.state
    const { name, city, language } = this.state.filter
    const filteredData = data.filter((astrologer) => {
      if (
        (name === "" ||
          astrologer.first_name.toLowerCase().includes(name.toLowerCase()) ||
          astrologer.last_name.toLowerCase().includes(name.toLowerCase())) &&
        (!city.length || city.includes(astrologer.city)) &&
        (!language.length ||
          language.some((r) => astrologer.languages.includes(r)))
      ) {
        return astrologer
      }
    })

    if (!filteredData.length) {
      return <EmptyCard msg="No astrologers found." />
    }

    if (filteredData.length) {
      return (
        <Grid container spacing={0}>
          {filteredData.map((astrologer) => {
            return (
              <Grid
                item
                key={astrologer.id}
                xs={12}
                md={6}
                className={classes.lsitItem}
              >
                <AstrologerListCard id={astrologer.id} />
              </Grid>
            )
          })}
        </Grid>
      )
    }

    return (
      <Grid container spacing={0}>
        {data.map((astrologer) => {
          return (
            <Grid
              item
              key={astrologer.id}
              xs={12}
              md={6}
              className={classes.lsitItem}
            >
              <AstrologerListCard id={astrologer.id} />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  renderContent = () => (
    <Grid container spacing={16}>
      <Hidden mdDown>
        <Grid item xs={12} md={3}>
          {this.renderFilter()}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9}>
        <AstrologerTopBar
          applyStringFilter={this.applyStringFilter}
          applyArrayFilter={this.applyArrayFilter}
          filter={Object.assign({}, this.state.filter)}
          _setState={this._setState}
        />
        {this.renderList()}
      </Grid>
    </Grid>
  )

  renderFilterModal = () => (
    <div>
      <ModalComponent
        _setState={this._setState}
        modalOpen={this.state.modalOpen}
        title="Filters"
        content={() => (
          <div>
            {this.renderFilter()}
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ modalOpen: false })}
            >
              Close
            </Button>
          </div>
        )}
      />
    </div>
  )

  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderFilterModal()}
      </div>
    )
  }
}

const styles = (theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "#fff",
    border: "1px solid #efe2e2",
  },
  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  lsitItem: {
    padding: 0,
    marginBottom: 5,
    [theme.breakpoints.up("md")]: {
      padding: 5,
      marginBottom: 0,
    },
  },
})

const mapStateToProps = ({ astrologers }) => {
  const data = Object.values(astrologers.rows).map((astrologer) => astrologer)

  return {
    rows: astrologers.rows,
    favorites: astrologers.favorites,
    astrologers: data,
  }
}
export default connect(mapStateToProps)(withStyles(styles)(AstrologerList))
