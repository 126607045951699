let initialState = {
  isLoading: true,
  rows: {},
  total: 0,
  ids: [],
  error: false,
  lastFetch: null,
  lastFetchValid: false,
}

const enumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ENUMS":
      let today = new Date()
      let diffMs = state.lastFetch != null ? today - state.lastFetch : false
      let diffMins = diffMs
        ? Math.round(((diffMs % 86400000) % 3600000) / 60000)
        : false
      let lastFetchValid = !state.error && diffMins !== false && diffMins <= 30
      return {
        ...state,
        isLoading: true,
        error: false,
        lastFetchValid,
      }
    case "FETCH_ENUMS_FULFILLED":
    case "FETCH_ENUMS_REJECTED":
      const { res, enums: rows } = action.payload
      let ids = []
      const error =
        res !== 1 ||
        res === undefined ||
        rows === undefined ||
        !Object.keys(rows).length

      if (rows !== undefined) {
        ids = Object.keys(rows)
      }

      return {
        ...state,
        rows,
        ids,
        isLoading: false,
        error,
        lastFetch: error ? null : new Date(),
        lastFetchValid: !error,
      }
    case "UPDATE_ENUMS_COLUMN":
      let column = action.payload.column
      let column_val = action.payload.column_val
      return {
        ...state,
        [column]: column_val,
      }
    default:
      return state
  }
}
export default enumsReducer
