import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"

class DrawerComponent extends React.Component {
  toggleDrawer = (open) => () => {
    this.props._setState({
      drawerOpen: open,
    })
  }

  render() {
    let { classes, content, drawerOpen, anchor, variant } = this.props
    return (
      <Drawer
        anchor={anchor || "bottom"}
        variant={variant || "persistent"}
        open={drawerOpen}
        onClose={this.toggleDrawer(false)}
      >
        <div className={classes.drawerHeader}>
          <Button onClick={this.toggleDrawer(false)}>close</Button>
        </div>
        <Divider />
        <div className={classes.content}>{content && content()}</div>
      </Drawer>
    )
  }
}

const styles = (theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {},
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    padding: 20,
  },
})

export default withStyles(styles)(DrawerComponent)
