import React, { Component, useEffect, useState } from "react"
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
  Typography,
  withStyles,
  CircularProgress,
  Link,
} from "@material-ui/core"
import PersonIcon from "@material-ui/icons/Person"
import { apiv2Instance } from "../../utils"
import { connect } from "react-redux"
import Modal from "./components/Modal"
import ChatScreen from "./components/ChatScreen"
import { withRouter } from "react-router"

function Chats(props) {
  const { id, classes, history } = props
  const [isLoading, setIsLoading] = useState(true)
  const [chats, setChats] = useState([])

  useEffect(() => {
    async function getChats() {
      const { data } = await apiv2Instance.get(`/chat/rooms?userId=${id}`)
      setChats(data.chatRooms)
      setIsLoading(false)
    }
    getChats()
  }, [])

  function Chat(props) {
    const { chat } = props
    const [isChatModalOpen, setIsChatModalOpen] = useState(false)

    return (
      <>
        <ListItem button onClick={() => setIsChatModalOpen(true)}>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={chat.astrologerName}
            style={{
              padding: 0,
              paddingBottom: "5px",
              borderBottom: "1px solid #BDBDBD",
              maxWidth: "200px",
              marginLeft: "10px",
            }}
          />
        </ListItem>{" "}
        <Modal
          isChatModalOpen={isChatModalOpen}
          setIsChatModalOpen={setIsChatModalOpen}
          classes={classes}
          astrologerName={chat.astrologerName}
          content={() => <ChatScreen userId={id} chatRoomId={chat._id} />}
        />
      </>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
      </div>
    )
  }

  if (chats.length === 0) {
    return (
      <div className={classes.noChatsRoot}>
        <Typography variant="h5">No chats...</Typography>
        <Typography>
          Try{" "}
          <Link
            className={classes.astrologerLink}
            onClick={() => history.push("/astrologers")}
            to="/astrologers"
          >
            Live Chat
          </Link>{" "}
          with astrologers
        </Typography>
      </div>
    )
  }

  return (
    <Grid container component={List}>
      {chats.map((chat) => (
        <Grid key={chat._id} item xs={12}>
          <Chat chat={chat} />
        </Grid>
      ))}
    </Grid>
  )
}

class ChatsWrapper extends Component {
  render() {
    return <Chats {...this.props} />
  }
}

const styles = (theme) => ({
  noChatsRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  loadingRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  astrologerLink: {
    cursor: "pointer",
  },
})

const mapStateToProps = (state, _ownProps) => {
  const { user } = state.auth
  const { id } = user
  return { id }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ChatsWrapper))
)
