import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import { instance, timeAgo } from "../../../utils/functions"
import { PaginationComponent, Progress } from "../../../utils/components"
import { EmptyCard } from "../../../utils/components/misc"

const API = `notification/notification_data/get_list`
const API_READ = `notification/notification_data/read/`
const API_ALL_READ = `notification/notification_data/mark_all_read/`

class NotificationList extends Component {
  state = {
    isLoading: false,
    readingAll: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 10,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    this.setState({ isLoading: true })
    let { page, limit } = this.state
    let start = page * limit
    let { user_id } = this.props
    instance.post(API, { user_id, start, limit }).then((result) => {
      let { rows, total } = result
      this.setState({ rows: rows || [], isLoading: false, total })
    })
  }

  readAll = () => {
    this.setState({ readingAll: true })
    let { user_id } = this.props
    instance.post(API_ALL_READ, { user_id }).then(() => {
      this.setState({ readingAll: false }, this.fetchRows)
    })
  }

  renderPagination = () => {
    return (
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    )
  }

  renderRow = (x) => (
    <React.Fragment key={x.notification_id}>
      <Card
        onClick={() => {
          instance.post(`${API_READ}${x.notification_id}`)
          this.props.history.push(`/${x.notification_link}`)
          this.props._setState({ modalOpen: false })
        }}
        style={{
          backgroundColor: `${x.notification_read == 1 ? "#f3f1f1" : "#fff"}`,
        }}
      >
        <CardContent>
          <Typography
            color={x.notification_read ? "textSecondary" : "secondary"}
            dangerouslySetInnerHTML={{ __html: x.notification_text }}
          />
          <Typography variant="caption">
            {" "}
            {timeAgo(x.notification_join_date)}{" "}
          </Typography>
        </CardContent>
      </Card>
      <Divider />
    </React.Fragment>
  )

  renderClearButton = () => (
    <div style={{ textAlign: "right" }}>
      <Button
        size="small"
        onClick={this.readAll}
        disabled={this.state.readingAll}
      >
        Mark all as read
      </Button>
    </div>
  )

  render() {
    let { rows, isLoading } = this.state
    let cont = rows.length ? (
      rows.map((x) => this.renderRow(x))
    ) : isLoading ? (
      <Progress />
    ) : (
      <EmptyCard card />
    )
    return (
      <React.Fragment>
        {rows.length > 0 && this.renderClearButton()}
        {cont}
        {this.renderPagination()}
      </React.Fragment>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ auth }) => {
  let user_id = auth.user.id
  return { user_id }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(NotificationList))
)
