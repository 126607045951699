import { instance } from "../../utils"
const FETCH_ROWS_API = `astrologer/astrologer_public/list_data`

export const fetchRows = (data = {}) => (dispatch) => {
  return dispatch({
    type: "FETCH_ASTROLOGERS",
    payload: instance({
      method: "post",
      url: FETCH_ROWS_API,
      data: data,
    }),
  })
}

export const favorite = (id) => (dispatch, getState) => {
  let existing = getState().astrologers.favorites.includes(id)
  return dispatch({
    type: "UPDATE_FAVORITE",
    payload: { id },
  })
}
