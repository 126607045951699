import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography"

import "./style.css"
import HomeBannerSlider from "./bannerSlider"
import HomeAstrologers from "./astrologers"
import register from "./register.jpg"
import recharge from "./recharge.jpg"
import chatCall from "./chat-call.jpg"

class HomePage extends Component {
  renderHead = (text) => (
    <Typography
      variant="subheading"
      color="primary"
      style={{ margin: 10, marginTop: 10 }}
    >
      {text}
    </Typography>
  )

  renderBody = () => {
    return (
      <div style={{ maxWidth: 1170, margin: "0 auto" }}>
        <HomeAstrologers />
      </div>
    )
  }

  renderFeutres = () => (
    <section className="strip-wrapper">
      <div className="strip">
        <img src={register} alt="Register on Monkvyasa" className="strip-img" />
        <Typography variant="caption">Register</Typography>
      </div>

      <div className="strip">
        <img src={recharge} alt="Recharge on Monkvyasa" className="strip-img" />
        <Typography variant="caption">Recharge</Typography>
      </div>

      <div className="strip">
        <img src={chatCall} alt="Chat - Call" className="strip-img" />
        <Typography variant="caption">Chat / Call</Typography>
      </div>
    </section>
  )

  render = () => {
    return (
      <div style={{ maxWidth: 1170, margin: "0 auto" }}>
        <HomeBannerSlider history={this.props.history} />
        {this.renderFeutres()}
        {this.renderHead("Our Astrologers")}
        <HomeAstrologers />
      </div>
    )
  }
}

const styles = (theme) => ({
  videoText: {
    margin: "35px 0 0",
    fontWeight: 400,
    color: "#666",
    fontSize: 14,
  },
  videoBtn: {
    marginTop: 20,
    background: "#f3dec9",
    color: "#f6851f",
    fontSize: 14,
    fontWeight: 400,
  },
})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(HomePage))
)
