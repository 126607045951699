import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withStyles, Avatar, Grid, Typography } from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import StarIcon from "@material-ui/icons/Star"
import { getUploadImge, createUrl } from "../../../utils/functions"
/*
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Favorite from "@material-ui/icons/Favorite"
import Hidden from "@material-ui/core/Hidden"
import { pushMsg } from "../../../navigation/actions"
import { favorite } from "../actions"
*/

class AstrologerMainCard extends Component {
  showProfile = () => {
    const { id, history } = this.props
    history.push(createUrl("/astrologer", { id }))
  }

  renderRating = () => {
    let reviewCount = (
      <div className={this.props.classes.ratingCount}>
        {this.props.row.rating_count} Reviews
      </div>
    )

    if (this.props.row.isNew) {
      reviewCount = <div className={this.props.classes.ratingNew}>New</div>
    }

    return (
      <div>
        <div className={this.props.classes.rating}>
          {parseFloat(this.props.row.rating).toFixed(1)}
          <StarIcon className={this.props.classes.ratingStar} />
        </div>
        {reviewCount}
      </div>
    )
  }

  render() {
    const {
      classes,
      row,
      professions,
      languages,
      //id,
      //isFavorite,
      //dispatch,
      rightGridExtra,
    } = this.props

    return (
      <Grid container justify="center" className={classes.mainGrid}>
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            src={getUploadImge(row.image, "user/avatar")}
            imgProps={{
              alt: `${row.first_name} ${row.last_name} - Monkvyasa Astrologer`,
            }}
            classes={{ img: classes.avatarImg }}
            className={classes.avatar}
            onClick={this.showProfile}
          />
          {this.renderRating()}
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <div className={classes.content}>
            <Typography className={classes.name} onClick={this.showProfile}>
              {`${row.first_name} ${row.last_name}`}
            </Typography>

            <Typography
              className={classes.city}
              color="textSecondary"
              gutterBottom
            >
              <LocationOnIcon
                style={{ fontSize: 12, marginRight: 5, color: "#777171" }}
              />
              {row.city}
            </Typography>
            <Typography>
              {row.professions.split(",").map((item) => (
                <span key={item} className={classes.profession}>
                  {professions[item] ? professions[item] : ""}{" "}
                </span>
              ))}
            </Typography>
            <Typography>
              {row.languages.split(",").map((item) => (
                <span key={item} className={classes.language}>
                  {languages[item] ? languages[item] : ""}{" "}
                </span>
              ))}
            </Typography>
            {rightGridExtra && rightGridExtra()}
          </div>
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme) => ({
  mainGrid: {
    minHeight: 130,
  },
  leftGrid: {
    alignSelf: "center",
    textAlign: "center",
  },
  rightGrid: {
    alignSelf: "flex-start",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingTop: 20,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  avatar: {
    margin: "0 auto",
    marginBottom: 4,
    marginTop: 4,
    width: 60,
    height: 60,
  },
  avatarImg: {
    //width : 200
  },
  button: {
    margin: theme.spacing.unit,
    width: "90%",
    borderRadius: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  city: {
    fontSize: 13,
    color: "#000",
  },
  name: {
    cursor: "pointer",
    color: "#31708f",
    fontSize: 15,
  },
  profession: {
    marginRight: 10,
    color: "#0000008a",
    fontSize: 12,
  },
  language: {
    marginRight: 10,
    color: "#274e61",
    fontSize: 13,
  },
  actions: {
    textAlign: "center",
    margin: 0,
  },
  rating: {
    backgroundColor: "#37be5f",
    color: "#f7f7f7",
    fontSize: 12,
    display: "inline-block",
    margin: 0,
    padding: "3px 7px",
  },
  ratingCount: {
    color: "#999",
    fontSize: 11,
    textAlign: "center",
  },
  ratingStar: {
    fontSize: 14,
    marginLeft: 2,
  },
  ratingNew: {
    color: "#f52020",
    fontSize: 11,
    textAlign: "center",
    padding: 2,
    margin: "0 auto",
    border: "1px solid #ccc",
    marginTop: 2,
    background: "#dcb6b6",
    width: 60,
  },
  favButton: {
    float: "right",
    display: "inline-block",
  },
})

const mapStateToProps = ({ astrologers, enums }, { id }) => {
  let row = astrologers.rows[id]
  row.isNew = row.new
  const favorites = astrologers.favorites
  const isFavorite = favorites.includes(id)

  return {
    row,
    isFavorite,
    languages: enums.rows.languages.vt,
    professions: enums.rows.professions.vt,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(AstrologerMainCard))
)
