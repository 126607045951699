let initialState = {
  isLoading: true,
  rows: {},
  total: 0,
  ids: [],
}

let rows, row, id, ids, index

const birthDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_BD":
      return {
        isLoading: false,
        rows: {},
        ids: [],
        total: 0,
      }
    case "PUSH_BD_ROW":
      row = action.payload.row
      id = row.birth_id
      ids = [...state.ids]
      if (!state.ids.includes(id)) ids.push(id)
      return {
        ...state,
        rows: {
          ...state.rows,
          [id]: row,
        },
        ids,
        isLoading: false,
      }
    case "UPDATE_BD_ROW":
      row = action.payload.row
      id = row.birth_id
      return {
        ...state,
        rows: {
          ...state.rows,
          [id]: row,
        },
      }
    case "REMOVE_BD_ROW":
      id = action.payload
      let { [id]: _, ...rest } = state.rows
      return {
        ...state,
        rows: rest,
        ids: state.ids.filter((x) => x !== id),
      }
    case "UPDATE_BD_COLUMN":
      let rowId = action.payload.rowId
      let { value, field } = action.payload
      return {
        ...state,
        rows: {
          ...state.rows,
          [rowId]: {
            ...state.rows[rowId],
            [field]: value,
          },
        },
      }
    case "CLEAR_BD_NOT_SAVED":
      return {
        ...state,
        rows: {},
        ids: [],
      }
    case "UPDATE_BIRTH_ID":
      let { id_new, id_old } = action.payload
      let newRow = { ...state.rows[id_old] }
      newRow.isLoading = false
      newRow.birth_id = id_new
      let { [id_old]: x, ...newRows } = state.rows
      ids = state.ids.filter((x) => x !== id_old)
      ids.push(id_new)
      return {
        ...state,
        rows: {
          ...newRows,
          [id_new]: newRow,
        },
        ids,
      }
    default:
      return state
  }
}

export default birthDetailsReducer
