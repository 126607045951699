import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

import { pushBooking, clearUnsavedBookings } from "../actions"
import { guid, createUrl } from "../../../utils/functions"
import { pushMsg } from "../../../navigation/actions"
import RequirementSample from "./sample"

class ReportSelectServices extends Component {
  state = {
    bookings: [],
  }

  componentWillMount = () => this.props.dispatch(clearUnsavedBookings())

  updateBooking = (mr_service_id, my_report_id, operation = "increment") => {
    let index = this.findBooking(mr_service_id, my_report_id)
    let bookings = this.state.bookings

    if (operation == "increment") {
      bookings[index].count++
    }

    if (operation == "check") {
      if (index == -1) {
        bookings.push({
          mr_service_id,
          my_report_id,
          count: 1,
        })
      } else bookings.splice(index, 1)
    }

    if (operation == "decrement") {
      bookings[index].count--
      if (bookings[index].count == 0) bookings.splice(index, 1)
    }
    this.setState({ bookings })
  }

  findBooking = (mr_service_id, my_report_id) => {
    let bookings = this.state.bookings
    return bookings.findIndex(
      (x) => x.mr_service_id == mr_service_id && x.my_report_id == my_report_id
    )
  }

  findService = (mr_service_id) => {
    let { astrologer } = this.props
    return astrologer.report_services.find(
      (x) => x.mr_service_id == mr_service_id
    )
  }

  getTotal = () => {
    let total = 0
    let bookings = this.state.bookings
    bookings.map((x) => {
      let service = this.findService(x.mr_service_id)
      total += parseFloat(service.mr_service_rate * x.count)
    })
    return total
  }

  renderRow = (row, my_report_id = null) => {
    let {
      report_services,
      my_report_services,
      astrologer,
      classes,
    } = this.props
    let label = my_report_services[my_report_id]
      ? my_report_services[my_report_id].text
      : report_services[row.mr_service_id].text
    let index = this.findBooking(row.mr_service_id, my_report_id)
    let checked = index > -1
    let booking = this.state.bookings[index]
    return (
      <div key={label}>
        <div className={classes.row}>
          <div className={classes.column} style={{ flexGrow: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  disableRipple
                  checked={checked}
                  onChange={() =>
                    this.updateBooking(row.mr_service_id, my_report_id, "check")
                  }
                />
              }
              label={label}
            />
          </div>
          <div
            className={`${classes.column} ${classes.columnRate}`}
            style={{ flexGrow: 1 }}
          >
            {`${row.mr_service_rate} ${astrologer.currency}`}
          </div>
        </div>
        {checked && (
          <div className={classes.row} style={{ paddingLeft: 50 }}>
            <div className={classes.column} style={{ flexGrow: 4 }}>
              <Button
                variant="text"
                onClick={() =>
                  this.updateBooking(
                    row.mr_service_id,
                    my_report_id,
                    "decrement"
                  )
                }
              >
                <RemoveCircleOutlineIcon />
              </Button>
              {booking.count}
              <Button
                variant="text"
                onClick={() =>
                  this.updateBooking(
                    row.mr_service_id,
                    my_report_id,
                    "increment"
                  )
                }
              >
                <AddCircleOutlineIcon />
              </Button>
            </div>
            <div
              className={`${classes.column} ${classes.columnRate}`}
              style={{
                flexGrow: 1,
                border: `1px solid #dcbef3`,
                padding: "10px 0",
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {`${row.mr_service_rate * booking.count} ${astrologer.currency}`}
            </div>
          </div>
        )}
      </div>
    )
  }

  renderContent = () => {
    let { classes, my_report_services, astrologer } = this.props
    let listRows = []

    astrologer.report_services.map((row) => {
      if (row.mr_service_id == 1) {
        Object.values(my_report_services).map((mr_row) =>
          listRows.push(this.renderRow(row, mr_row.val))
        )
      } else listRows.push(this.renderRow(row))
    })

    let total = this.getTotal()

    return (
      <div>
        {listRows}
        {total > 0 && (
          <Typography
            style={{ margin: 10 }}
            variant="subtitle1"
            align="center"
            color="secondary"
          >
            Total : {`${total} ${astrologer.currency}`}{" "}
          </Typography>
        )}
        <Button
          variant={total ? "contained" : "outlined"}
          color="secondary"
          fullWidth
          onClick={this.createBookings}
          className={total ? classes.proceedButton : ` `}
        >
          {total ? "proceed" : "Select services"}
        </Button>
      </div>
    )
  }

  createBookings = () => {
    let { dispatch, astrologer, id, history } = this.props
    let { bookings } = this.state
    if (!bookings.length) {
      dispatch(pushMsg("Please select services!", "error"))
      return
    }
    const requirement_group_ref = guid()
    bookings.map((row) => {
      for (var i = row.count - 1; i >= 0; i--) {
        let rowExtend = {
          requirement_id: guid(),
          astrologer_id: id,
          requirement_bds: null,
          requirement_requirement: "",
          requirement_languages: astrologer.languages,
          requirement_group_ref: requirement_group_ref,
          requirement_status: 1,
          isSaved: false,
        }
        dispatch(pushBooking(Object.assign({}, row, rowExtend)))
      }
      history.push(createUrl("/requirement/create", { requirement_group_ref }))
    })
  }

  render() {
    return (
      <React.Fragment>
        <RequirementSample />
        <Card>
          <CardContent>{this.renderContent()}</CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ astrologers, enums }, { id }) => {
  return {
    astrologer: astrologers.rows[id],
    report_services: enums.rows.report_services.raw,
    my_report_services: enums.rows.my_report_services.raw,
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  proceedButton: {
    backgroundColor: "#37be5f",
  },
  row: {
    display: "flex",
    padding: "2px 10px",
    alignItems: "center",
    justifyItems: "center",
    borderBottom: "1px solid #f6f2f9",
  },
  columnRate: {
    textAlign: "right",
  },
})

ReportSelectServices.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ReportSelectServices))
)
