import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#954bb4",
      main: "#7b1fa2",
      dark: "#561571",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffb233",
      main: "#ff9f00",
      dark: "#b26f00",
      contrastText: "#fff",
    },
  },
})

export default theme
