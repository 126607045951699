import React, { Component } from "react"
import { connect } from "react-redux"
import {
  withStyles,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import { _toFixed } from "../../../utils/functions"

class WalletPaymentTopCard extends Component {
  getAmount = (amount) => {
    const { constants } = this.props
    amount = constants.ROAMING
      ? parseFloat(amount) / parseFloat(constants.USD_RATE)
      : amount
    return _toFixed(parseFloat(amount))
  }

  renderRow = (txtLeft, txtRight) => (
    <div>
      <Grid item xs={6}>
        {txtLeft}
      </Grid>
      <Grid item xs={6} className={this.props.classes.amount}>
        {txtRight}
      </Grid>
    </div>
  )

  renderContent = () => {
    const { auth, constants, trn_amount, couponValue } = this.props
    const { wallet } = auth.user
    const payment_service_rate = this.getAmount(
      trn_amount * constants.SERVICE_RATE * 0.01
    )
    const pay_amount = parseFloat(payment_service_rate) + parseFloat(trn_amount)
    const totalTalkTime = parseFloat(trn_amount) + parseFloat(couponValue)

    const rows = {
      "Available balance": `${_toFixed(wallet)} ${constants.CURRENCY}`,
      "Recharge amount": `${_toFixed(trn_amount)} ${constants.CURRENCY}`,
      [`GST (${constants.SERVICE_RATE}%)`]: `${payment_service_rate} ${constants.CURRENCY}`,
      "Total Payable": `${_toFixed(pay_amount)} ${constants.CURRENCY}`,
      "Total Talk Time": `${_toFixed(totalTalkTime)} ${constants.CURRENCY}`,
    }

    return (
      <table width="100%">
        <tbody>
          {Object.keys(rows).map((key) => (
            <tr key={key}>
              <td>
                <Typography variant="caption">{key}</Typography>
              </td>
              <td>
                <Typography variant="caption" color="primary" align="right">
                  {rows[key]}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <Card>
        <CardContent>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={3} className={classes.leftGrid}>
              <AccountBalanceWalletIcon className={classes.icon} />
            </Grid>
            <Grid item xs={9} className={classes.rightGrid}>
              <div className={classes.content}>{this.renderContent()}</div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

const styles = () => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  icon: {
    fontSize: 70,
    margin: "0 auto",
  },
  balance: {
    fontSize: 32,
    color: "blue",
  },
  amount: {
    textAlign: "right",
    paddingRight: 20,
    color: "blue",
  },
})

const mapStateToProps = ({ auth, enums }, { trn_amount }) => {
  const constants = enums.rows.constants
  return { auth, constants, trn_amount }
}

export default connect(mapStateToProps)(
  withStyles(styles)(WalletPaymentTopCard)
)
