import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import ModalComponent from "../modal"
import ReportSteps from "./reportSteps"

class ReportHelp extends Component {
  state = {
    modalOpen: false, //false
    isAvailbale: false,
  }

  _setState = (newState) => this.setState(newState)

  render() {
    return (
      <div>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="How to talk"
          content={() => <ReportSteps />}
        />
        <Button
          size="small"
          variant="text"
          color="primary"
          fullWidth
          onClick={() => this.setState({ modalOpen: true })}
        >
          To get Report
        </Button>
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportHelp))
