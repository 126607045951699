import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"

import { updateField } from "../actions"

class RequirementLanguages extends Component {
  handleChange = (event, checked) => {
    let { requirement_languages, requirement_id } = this.props
    if (checked) requirement_languages.push(event.target.value)
    else
      requirement_languages = requirement_languages.filter(
        (x) => x != event.target.value
      )
    this.props.dispatch(
      updateField(
        requirement_id,
        "requirement_languages",
        requirement_languages
      )
    )
  }

  render() {
    let {
      languages,
      astrologer_languages,
      classes,
      requirement_languages,
      canEdit,
    } = this.props
    if (canEdit)
      return (
        <div>
          <Typography className={classes.name}>
            This astrologer can reply in these languages <br />
            (Change preference if needed)
          </Typography>
          {astrologer_languages.split(",").map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  color="default"
                  checked={requirement_languages.includes(item)}
                  onChange={this.handleChange}
                  value={item}
                />
              }
              label={languages[item]}
            />
          ))}
        </div>
      )
    return (
      <div>
        <Typography className={classes.name}>language preferences</Typography>
        {astrologer_languages.split(",").map(
          (item) =>
            requirement_languages.includes(item) && (
              <Typography key={item} className={classes.language}>
                {languages[item]}
              </Typography>
            )
        )}
      </div>
    )
  }
}

const styles = () => ({
  name: {
    color: "#31708f",
    fontSize: 12,
  },
  language: {
    color: "#04486d",
    fontSize: 13,
    marginRight: 20,
    float: "left",
  },
})

const mapStateToProps = (
  { enums, astrologers, requirement: requirementList },
  { requirement_id }
) => {
  let requirement = requirementList.rows[requirement_id]
  let { astrologer_id, requirement_languages } = requirement
  let astrologer = astrologers.rows[astrologer_id]
  let astrologer_languages = astrologer.languages
  let languages = enums.rows.languages.vt
  return { astrologer_languages, languages, requirement_languages }
}
export default connect(mapStateToProps)(
  withStyles(styles)(RequirementLanguages)
)
