import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrShareButton,
  TumblrIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

class AstrologerShareButtons extends React.Component {
  render() {
    let shareUrl = window.location.href
    let { title, classes } = this.props
    return (
      <div>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className={classes.shareButton}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className={classes.shareButton}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    )
  }
}

const styles = (theme) => ({
  shareButton: {
    display: "inline-block",
    marginRight: 5,
  },
})

export default withStyles(styles)(AstrologerShareButtons)
