import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import LinearProgress from "@material-ui/core/LinearProgress"

import { FormResult } from "../../../utils/form"
import { instance } from "../../../utils"

const API = `phonecall/init`

class PhonecallInteractionInit extends Component {
  state = {
    isLoading: true,
    completed: 0,
    buffer: 10,
  }

  componentWillMount = () => this.initCall()

  componentDidMount() {
    this.timer = setInterval(this.progress, 500)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    if (completed > 100) {
      this.setState({ completed: 0, buffer: 10 })
    } else {
      const diff = Math.random() * 10
      const diff2 = Math.random() * 10
      this.setState({
        completed: completed + diff,
        buffer: completed + diff + diff2,
      })
    }
  }

  initCall = () => {
    let { row, updateRow } = this.props
    let { phonecall_id } = row
    instance({
      method: "post",
      url: API,
      data: { filter: { phonecall_id }, phonecall_id },
    }).then((result) => {
      let { res } = result
      this.setState({ isLoading: false })
      if (res != 1) {
        this.setState({ result })
        return
      } else {
        let { call_sid, call_status, roaming } = result
        updateRow({ call_sid, call_status, roaming })
      }
    })
  }

  renderLoader = () => (
    <div>
      <Typography color="secondary">Calling..</Typography>
      <LinearProgress
        color="secondary"
        variant="buffer"
        value={this.state.completed}
        valueBuffer={this.state.buffer}
      />
    </div>
  )

  render() {
    let { caller_id, row } = this.props
    const { isLoading, result } = this.state
    return (
      <div>
        <Card style={{ margin: "10px 0", textAlign: "center" }}>
          <CardContent>
            <Typography color="textSecondary" variant="subtitle2">
              You will shortly receive a call from
            </Typography>
            <Typography color="primary" variant="title">
              {caller_id}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2">
              To your phone
            </Typography>
            <Typography color="primary" variant="subtitle1">
              {`+${row.country_code}-${row.call_phone_number}`}
            </Typography>
            <br />
            {isLoading && this.renderLoader()}
            <FormResult result={result} />
          </CardContent>
        </Card>
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ enums }) => {
  let { constants } = enums.rows
  let { ROAMING, DOMESTIC_CALLER_ID, INTERNATION_CALLER_ID } = constants
  let caller_id = ROAMING ? INTERNATION_CALLER_ID : DOMESTIC_CALLER_ID
  return { caller_id }
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallInteractionInit)
)
