import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"

import { convertMysqlDateTime } from "../../../utils/functions"
import { instance, apiv2Instance } from "../../../utils"
import { callDuration } from "../../../utils/functions"
import DisputeRoot from "../../dispute"
import ReviewRoot from "../../review"
import PhonecallInteractionNextOptions from "./nextOptions"
import PhonecallUpdator from "../components/updator"

const API = `phonecall/update_field`

class PhonecallInteractionDetails extends Component {
  renderDispute = () => {
    let { phonecall_id, dispute_status, astrologer_id } = this.props.row
    return (
      <div>
        <DisputeRoot
          dispute_status={dispute_status}
          dispute_ref_id={phonecall_id}
          service_id={1}
          astrologer_id={astrologer_id}
          onSuccess={this.onDisputeSuccess}
        />
      </div>
    )
  }

  onDisputeSuccess = () => {
    let { updateRow } = this.props
    updateRow({ dispute_status: 2 })
    let data = {
      field: "dispute_status",
      value: 2,
      id: this.props.row.phonecall_id,
    }
    instance({
      method: "post",
      url: API,
      data,
    })
  }

  renderReview = () => {
    let { phonecall_id, astrologer_id } = this.props.row
    return (
      <div>
        <Typography
          variant="subheading"
          color="secondary"
          style={{ margin: 10, marginTop: 20 }}
        >
          REVIEW
        </Typography>
        <ReviewRoot
          reference_id={phonecall_id}
          service_id={1}
          astrologer_id={astrologer_id}
        />
      </div>
    )
  }

  renderContent = () => {
    let { row } = this.props
    return (
      <Card style={{ margin: "10px 0", textAlign: "left" }}>
        <CardContent>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              Date
            </Grid>
            <Grid item xs={8}>
              {convertMysqlDateTime(row.call_join_date, true)}
            </Grid>
            {row.call_duration > 0 && (
              <React.Fragment>
                <Grid item xs={4}>
                  Duration
                </Grid>
                <Grid item xs={8}>
                  {callDuration(row.call_duration)}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </CardContent>
      </Card>
    )
  }

  componentDidMount = async () => {
    const { astrologer_id } = this.props.row
    await apiv2Instance.put(`/astrologer/status`, {
      id: astrologer_id,
      isAvailable: true,
    })
  }

  render() {
    let { row, updateRow, fetchRow } = this.props
    return (
      <React.Fragment>
        {this.renderContent()}
        <PhonecallUpdator
          visualise
          call_sid={row.call_sid}
          updateRow={(row) => updateRow(row.phonecall_id, row)}
          onComplete={() => fetchRow && fetchRow()}
        />
        <PhonecallInteractionNextOptions row={row} />
        {row.call_duration > 60 && this.renderDispute()}
        {row.call_duration > 60 * 1 && this.renderReview()}
      </React.Fragment>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallInteractionDetails)
)
