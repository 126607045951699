import React, { useState, useRef, useEffect } from "react"
import { useKeenSlider } from "keen-slider/react"
import { shuffle } from "../../../utils/functions"
import { apiv2Instance } from "../../../utils"
import "./style.css"
import "keen-slider/keen-slider.min.css"

import Prashant from "./Prashant Joshi.png"
import Kaml from "./Kaml Kant Gouri.png"
import Chetan from "./Chetan Bhanot.png"

const bannerImages = shuffle([
  {
    bannerId: "8487",
    bannerName: "Prashant Joshi",
    src: Prashant,
  },
  {
    bannerId: "9824",
    bannerName: "Kaml Kant Gouri",
    src: Kaml,
  },
  {
    bannerId: "9757",
    bannerName: "Chetan Bhanot",
    src: Chetan,
  },
])

function HomeBannerSlider(props) {
  const { history } = props
  const [pause, setPause] = useState(false)
  const timer = useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 5000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <div ref={sliderRef} className="keen-slider">
      {bannerImages.map((image, index) => {
        return (
          <div
            key={image.bannerId}
            className={`keen-slider__slide number-slide${index + 1}`}
            style={{ cursor: "pointer" }}
            onClick={async (_e) => {
              history.push(`/astrologer?id=${image.bannerId}`)
              await apiv2Instance.post(`/admin/analytics/banner/click`, {
                bannerId: image.bannerId,
                bannerName: image.bannerName,
              })
            }}
          >
            <img
              src={image.src}
              alt={image.bannerName}
              width="95%"
              height="100%"
            />
          </div>
        )
      })}
    </div>
  )
}

export default HomeBannerSlider
