import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"

import { COUNTRIES as countries } from "../../../constants"

class PhoneInput extends Component {
  render() {
    let {
      classes,
      onCountryChange,
      onPhoneChange,
      phone,
      country_code,
    } = this.props
    return (
      <div>
        <Grid container>
          <Grid item xs={4}>
            <Select
              className={classes.input}
              value={country_code || 91}
              onChange={onCountryChange}
              fullWidth
            >
              {Object.keys(countries).map((key) => (
                <MenuItem
                  key={key}
                  value={countries[key].phone}
                >{`${key} +${countries[key].phone}`}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              className={classes.input}
              value={phone || ``}
              onChange={onPhoneChange}
              placeholder="Enter phone number"
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = () => ({
  input: {},
})

const mapStateToProps = ({}) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneInput))
