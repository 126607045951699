import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"

import RequirementBlock from "../requirement/block"
import ChatRoot from "../chat"
import ReviewRoot from "../review"
import DisputeRoot from "../dispute"
import SolutionRoot from "./solution"
import ReportCompleteAction from "./components/completeAction"
import ReportCancelButton from "./components/cancelButton"
import AstrologerLeftCard from "../astrologer/components/leftCard"

import { instance } from "../../utils"
import { fetchReqRowDB } from "../requirement/actions"
import { getQueryStringValue } from "../../utils/functions"
import { Progress } from "../../utils/components"

const API_PING = `requirement/ping`

class ReportRoot extends Component {
  componentDidMount = () => this.fetchRow()

  fetchRow = () => {
    let { requirement_id, dispatch } = this.props
    dispatch(fetchReqRowDB(requirement_id))
  }

  sectionHeader = (text, color) => (
    <Typography
      color={color || `primary`}
      variant="subheading"
      style={{ margin: 10 }}
    >
      {text}
    </Typography>
  )

  pingRequirement = () =>
    instance.post(API_PING, {
      requirement_id: this.props.requirement_id,
      set_null: 1,
    })

  renderContent = () => {
    const {
      requirement_id,
      requirement,
      solutionAdded,
      completed,
      dispatch,
    } = this.props
    if (!solutionAdded)
      return (
        <Card style={{ padding: 10 }}>
          <CardContent>
            <Typography color="secondary">
              Please be patient astrologer will reply soon!
            </Typography>
            {this.renderCancelSection()}
          </CardContent>
        </Card>
      )
    if (requirement.requirement_status == 10)
      return (
        <Card style={{ padding: 10 }}>
          <CardContent>
            <Typography color="error">
              This booking has been cacelled.
            </Typography>
          </CardContent>
        </Card>
      )
    return (
      <React.Fragment>
        <SolutionRoot requirement_id={requirement_id} />
        <ChatRoot
          onSuccess={this.pingRequirement}
          chat_reference={`report_${requirement_id}`}
          receiver_id={requirement.astrologer_id}
          canEdit={!completed}
          notification={{
            text: "Report booking consultation",
            url: `report-booking?requirement_id=${requirement_id}`,
          }}
          defaultMsg="Type here if you need any clarification on this report."
        />
        {!completed && (
          <ReportCompleteAction
            requirement_id={requirement_id}
            onSuccess={() => {
              dispatch(fetchReqRowDB(requirement_id))
            }}
            requirement={requirement}
          />
        )}
        {completed && (
          <React.Fragment>
            <ReviewRoot
              reference_id={requirement_id}
              service_id={2}
              astrologer_id={requirement.astrologer_id}
            />
            <DisputeRoot
              dispute_ref_id={requirement_id}
              service_id={2}
              astrologer_id={requirement.astrologer_id}
              url={`report-booking?requirement_id=${requirement_id}`}
              notification=" Report booking consulation "
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderCancelSection = () => {
    if (!this.props.requirement.can_cancel) return null
    return (
      <Fragment>
        <Divider style={{ margin: 20 }} />
        <Typography variant="caption" color="textSecondary">
          If you are not received the report from the astrologer yet, can cancel
          this report. In that case, the amount will be refunded to your MV
          wallet and you can book the other astrologer OR you can wait till this
          astrologer submit the report.
        </Typography>
        <ReportCancelButton
          requirement_id={this.props.requirement.requirement_id}
          onSuccess={this.fetchRow}
          dispatch={this.props.dispatch}
        />
      </Fragment>
    )
  }

  renderGrid = () => {
    let { classes, requirement_id, requirement, solutionAdded } = this.props

    return (
      <Grid container spacing={16} className={classes.content}>
        <Grid item md={4} xs={12}>
          <AstrologerLeftCard id={requirement.astrologer_id} />
        </Grid>
        <Grid item md={8} xs={12}>
          {this.sectionHeader(`Requirement`)}
          <RequirementBlock requirement_id={requirement_id} />
          <ChatRoot
            onSuccess={this.pingRequirement}
            chat_reference={`requirement_${requirement_id}`}
            receiver_id={requirement.astrologer_id}
            canEdit={!solutionAdded}
            notification={{
              text: "Report booking consultation",
              url: `report-booking?requirement_id=${requirement_id}`,
            }}
          />
          <br />
          {this.sectionHeader(`Report`)}
          {this.renderContent()}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { requirement } = this.props
    if (!requirement || !requirement.astrologer_id) return <Progress />
    return this.renderGrid()
  }
}

const styles = () => ({})

const mapStateToProps = ({ requirement: requirementList }) => {
  const requirement_id = getQueryStringValue("requirement_id")
  let requirement = requirementList.rows[requirement_id]
  let solutionAdded = requirement && requirement.requirement_status > 5
  let completed = requirement && requirement.requirement_status >= 9
  return { requirement, requirement_id, solutionAdded, completed }
}
export default connect(mapStateToProps)(withStyles(styles)(ReportRoot))
