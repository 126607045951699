import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle"

import { instance } from "../../../utils"

const API = `phonecall/get_available_numbers`

class AvailableNumbers extends Component {
  state = {
    isLoading: true,
    rows: [],
  }

  componentDidMount = () => {
    instance.get(API).then((result) => {
      let { rows } = result
      this.setState({ rows })
    })
  }

  render() {
    let { rows } = this.state
    let { onSelect, row } = this.props
    let { call_phone_number, country_code } = row
    let rowsFiltered = rows.filter(
      (x) =>
        !(
          x.country_code == country_code &&
          x.call_phone_number == call_phone_number
        )
    )
    if (!rowsFiltered.length) return null

    return (
      <React.Fragment>
        <List dense>
          {rowsFiltered.map((x) => (
            <ListItem
              key={`${x.country_code} ${x.call_phone_number}`}
              divider
              onClick={() => onSelect(x.country_code, x.call_phone_number)}
            >
              <ListItemText
                primary={`${x.country_code} ${x.call_phone_number}`}
              />
              <ListItemSecondaryAction
                onClick={() => onSelect(x.country_code, x.call_phone_number)}
              >
                <SwapVerticalCircleIcon />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  input: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  confirmButton: {
    margin: theme.spacing.unit,
  },
})

const mapStateToProps = ({ phonecall }, ownProps) => {
  let row = phonecall.row
  return { row }
}
export default connect(mapStateToProps)(withStyles(styles)(AvailableNumbers))
