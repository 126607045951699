import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import { timeAgo } from "../../../utils/functions"
import { CardWrapper } from "../../../utils/hoc"
import { instance } from "../../../utils"
import { Progress } from "../../../utils/components"
import fields from "./fields"
import ReportRow from "./row"
import SolutionMatchScore from "./matchScore"
import ReportSolutionLifetime from "./lifetime"
import ReportSolutionYear from "./year"

const API = `report/get_report`

class ReportSolution extends Component {
  state = {
    isLoading: true,
    row: null,
    result: null,
    row_fields: {},
    submitIsLoading: false,
    report: {},
    onEdit: false,
  }

  componentWillMount = () => {
    let { requirement } = this.props
    let { mr_service_id } = requirement
    let row_fields = fields[mr_service_id]
    this.setState({ row_fields }, this.fetchRow)
  }

  fetchRow = () => {
    let { requirement_id } = this.props
    let { row_fields } = this.state
    this.setState({ isLoading: true, result: null })
    instance({
      url: API,
      data: { requirement_id },
      method: "post",
    }).then((result) => {
      let { res, row, rows } = result
      if (res == 2) {
        this.setState({ result })
      }
      if (res == 1) {
        if (rows !== null && rows !== undefined) row = rows[0] || {}
        let report = {}
        if (row_fields) {
          Object.keys(row_fields).map((x) => (report[x] = row[x] || ""))
        }
        this.setState({ row, rows, report })
      }
      this.setState({ isLoading: false, onEdit: !this.props.solutionAdded })
    })
  }

  renderContent = () => {
    let { requirement, solutionAdded, requirement_id } = this.props
    let { row, rows, row_fields, report } = this.state
    let { mr_service_id } = requirement

    if (mr_service_id == 3)
      return (
        <ReportSolutionYear
          row={row}
          requirement_id={requirement_id}
          solutionAdded={solutionAdded}
          fetchRow={this.fetchRow}
        />
      )

    if (mr_service_id == 4)
      return (
        <ReportSolutionLifetime
          rows={rows}
          requirement_id={requirement_id}
          solutionAdded={solutionAdded}
          fetchRow={this.fetchRow}
        />
      )

    let rowsList = []

    Object.keys(row_fields).map((k) => {
      if (report[k] == "") return null
      if (k === "horoscope_match_score")
        rowsList.push(
          <SolutionMatchScore
            onEdit={false}
            key={k}
            title={row_fields[k]}
            value={report ? report[k] : null}
          />
        )
      else
        rowsList.push(
          <ReportRow
            key={k}
            title={row_fields[k]}
            text={report ? report[k] : null}
          />
        )
    })

    return (
      <div>
        {rowsList}
        {requirement.report_join_date && (
          <Typography align="right" variant="body1" color="secondary">
            submitted {timeAgo(requirement.report_join_date)}
          </Typography>
        )}
      </div>
    )
  }

  render() {
    let { isLoading } = this.state
    if (isLoading) return <Progress />

    return <div>{this.renderContent()}</div>
  }
}

const styles = () => ({})

const mapStateToProps = (
  { requirement: requirementList },
  { requirement_id }
) => {
  let requirement = requirementList.rows[requirement_id]
  let solutionAdded = requirement.requirement_status > 5
  return { requirement, solutionAdded }
}
export default connect(mapStateToProps)(
  withStyles(styles)(CardWrapper(ReportSolution))
)
