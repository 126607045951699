import React from "react"
import { Button, Typography, withStyles } from "@material-ui/core"

function FormFour(props) {
  const { classes, closeModal } = props

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.text}>
          Your chat session with astrologer has ended.
        </Typography>
        <br />
        <Button color="primary" variant="contained" onClick={closeModal}>
          Go to Home
        </Button>
      </div>
    </div>
  )
}

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  text: {
    fontSize: "18px",
  },
})

export default withStyles(styles)(FormFour)
