import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"

import { _toFixed } from "../../../utils/functions"
import WalletForm from "./form"

class WalletLeftCard extends Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  renderContent = () => {
    const { classes, auth, constants } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={6}>
          Available balance
        </Grid>
        <Grid item xs={6} className={classes.amount}>
          {_toFixed(auth.user.wallet)} {constants.CURRENCY}
        </Grid>
      </Grid>
    )
  }

  addButton = () => (
    <Button
      style={{ marginTop: 20 }}
      color="secondary"
      variant="outlined"
      size="small"
      onClick={() => this.setState({ modalOpen: true })}
    >
      <CheckCircleIcon style={{ marginRight: 10, color: "green" }} />
      Recharge wallet
    </Button>
  )

  renderForm = () => (
    <Drawer
      anchor="bottom"
      open={this.state.modalOpen}
      onClose={() => this.setState({ modalOpen: false })}
    >
      <div
        tabIndex={0}
        role="button"
        //onClick={()=>this.setState({modalOpen:false})}
        //onKeyDown={()=>this.setState({modalOpen:false})}
      >
        <Typography
          color="primary"
          align="center"
          variant="subheading"
          style={{ padding: 10 }}
        >
          RECHARGE WALLET
        </Typography>
        <Divider />
        <div style={{ padding: 20 }}>
          <WalletForm />
        </div>
      </div>
    </Drawer>
  )

  render() {
    const { classes } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4} className={classes.leftGrid}>
          <AccountBalanceWalletIcon className={classes.icon} />
        </Grid>
        <Grid item xs={8} className={classes.rightGrid}>
          <div className={classes.content}>
            {this.renderContent()}
            {this.addButton()}
            {this.renderForm()}
          </div>
        </Grid>
      </Grid>
    )
  }
}

const styles = () => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  icon: {
    fontSize: 100,
    margin: "0 auto",
  },
  balance: {
    fontSize: 32,
    color: "blue",
  },
  amount: {
    textAlign: "right",
    paddingRight: 20,
    color: "blue",
  },
})

const mapStateToProps = ({ auth, enums }) => {
  let constants = enums.rows.constants
  return { auth, constants }
}
export default connect(mapStateToProps)(withStyles(styles)(WalletLeftCard))
