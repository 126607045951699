import { instance } from "../../utils"
const FETCH_ROWS_API = `utils/utils_enums/list_json`

export function fetchRows(data = {}) {
  return (dispatch) => {
    return dispatch({
      type: "FETCH_ENUMS",
      payload: instance({
        method: "post",
        url: FETCH_ROWS_API,
        data: data,
      }),
    })
  }
}
