import React, { useState, useEffect, useRef } from "react"
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  withStyles,
  Slide,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SendIcon from "@material-ui/icons/Send"
import { socketClient } from "../../../../utils"
import { useBeforeunload } from "react-beforeunload"

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function ModalComponent(props) {
  const chatRoomInfo = JSON.parse(sessionStorage.getItem("chatRoomInfo"))
  const { setChatSession, classes, content, chatSession, setFormStep } = props
  const [dialogOpen, setDialogOpen] = useState(false)
  const [messageInput, setMessageInput] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const [isUserDisconnected, setIsUserDisconnected] = useState(false)
  const isTypingTimeout = useRef(null)
  const inputRef = useRef(null)

  function handleClose() {
    setDialogOpen(true)
  }

  function handleExit() {
    setDialogOpen(false)
    setChatSession(false)
  }

  function handleEndChat() {
    const { hasChatEnded = null } = JSON.parse(
      sessionStorage.getItem("chatRoomInfo")
    )
    if (!hasChatEnded) {
      socketClient.emit("END_CHAT", { chatRoomId: chatRoomInfo.chatRoomId })
    }
    sessionStorage.removeItem("chatRoomInfo")
    setTimeout(() => socketClient.disconnect(), 3000)
  }

  function sendMessage(e) {
    e.preventDefault()
    socketClient.emit("SEND_MESSAGE", {
      from: chatRoomInfo.userId,
      to: chatRoomInfo.astrologerId,
      chatRoomId: chatRoomInfo.chatRoomId,
      message: messageInput,
    })
    setMessageInput("")
    const getBottom = document.querySelector("#scrollable")
    getBottom.scrollTop = getBottom.scrollHeight
    inputRef.current.focus()
  }

  useEffect(() => {
    socketClient.on("TYPING", function () {
      clearTimeout(isTypingTimeout.current)
      setIsTyping(true)
      isTypingTimeout.current = setTimeout(() => {
        setIsTyping(false)
      }, 500)
    })

    return () => {
      socketClient.off("TYPING")
    }
  }, [])

  useEffect(() => {
    socketClient.on("USER_DISCONNECT", () => {
      setIsUserDisconnected(true)
    })
    socketClient.on("RECONNECTED", () => {
      setIsUserDisconnected(false)
    })

    return () => {
      socketClient.off("USER_DISCONNECT")
      socketClient.off("RECONNECTED")
    }
  }, [isUserDisconnected])

  useBeforeunload((e) => {
    if (chatRoomInfo) {
      e.preventDefault()
      handleClose()
    }
  })

  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget()
    }

    return () => {
      handleEndChat()
      if (window.Tawk_API) {
        window.Tawk_API.showWidget()
      }
      setFormStep(4)
    }
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={chatSession}
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div className={classes.flex}>
              <Typography variant="subtitle1" color="inherit">
                {chatRoomInfo.astrologerName}
              </Typography>
              {!isUserDisconnected ? (
                <Typography variant="caption" color="inherit">
                  {isTyping ? "typing..." : "online"}
                </Typography>
              ) : null}
            </div>
            <IconButton color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent id="scrollable" className={classes.scrollableDiv}>
          <div>{content && content()}</div>
        </DialogContent>
        <DialogActions className={classes.inputBox}>
          <form onSubmit={sendMessage} className={classes.inputMsgForm}>
            <TextField
              inputRef={inputRef}
              variant="outlined"
              value={messageInput}
              placeholder="Type your message"
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={(_e) =>
                socketClient.emit("TYPING", {
                  chatRoomId: chatRoomInfo.chatRoomId,
                })
              }
              className={classes.inputMsg}
              autoFocus
            />
            <IconButton
              color="primary"
              onClick={sendMessage}
              disabled={!messageInput}
            >
              <SendIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </form>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        disableEscapeKeyDown
      >
        <DialogContent>
          <DialogContentText>
            Your chat session may end, Do you want to exit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(_e) => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleExit} color="primary" autoFocus>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    background: "#f8f8f8",
    overflowX: "hidden",
    padding: theme.spacing.unit,
    minHeight: "92vh",
  },
  flex: {
    flex: 1,
  },
  scrollableDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputMsgForm: {
    flex: 1,
    display: "flex",
  },
  inputMsg: {
    flex: 1,
  },
})

export default withStyles(styles)(ModalComponent)
