let initialState = {
  isLoading: true,
  rows: {},
  total: 0,
  ids: [],
  favorites: [],
  error: false,
  lastFetch: null,
  lastFetchValid: false,
}

let id

const astrologersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ASTROLOGERS":
      let today = new Date()
      let diffMs = state.lastFetch != null ? today - state.lastFetch : false
      let diffMins = diffMs
        ? Math.round(((diffMs % 86400000) % 3600000) / 60000)
        : false
      let lastFetchValid = !state.error && diffMins !== false && diffMins <= 30
      return {
        ...state,
        isLoading: true,
        error: false,
        lastFetchValid,
      }
    case "FETCH_ASTROLOGERS_FULFILLED":
    case "FETCH_ASTROLOGERS_REJECTED":
      const { rows: rowsArray, res } = action.payload
      const rows = {}
      let ids = []
      const error =
        res !== 1 ||
        res === undefined ||
        rowsArray === undefined ||
        !rowsArray.length

      if (rowsArray !== undefined) {
        for (const value of rowsArray) {
          rows[value.id] = value
          ids.push(value.id)
        }
      }

      return {
        ...state,
        rows,
        ids,
        isLoading: false,
        error,
        lastFetch: error ? null : new Date(),
        lastFetchValid: !error,
      }

    case "UPDATE_ASTROLOGERS_COLUMN":
      let column = action.payload.column
      let column_val = action.payload.column_val
      return {
        ...state,
        [column]: column_val,
      }
    case "UPDATE_FAVORITE":
      id = action.payload.id
      let favorites = [...state.favorites]
      if (!favorites.includes(id)) favorites.push(id)
      else favorites = favorites.filter((x) => x !== id)
      return {
        ...state,
        favorites,
      }

    default:
      return state
  }
}
export default astrologersReducer
