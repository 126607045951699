import React, { Component } from "react"
import { connect } from "react-redux"
import {
  withStyles,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  Fab,
  TextField,
} from "@material-ui/core"
import { getQueryStringValue, _toFixed } from "../../../utils/functions"
import { instance } from "../../../utils"
import { FormResult } from "../../../utils/form"
import { Progress } from "../../../utils/components"
import { COUPONS } from "../../../constants"
import WalletPaymentTopCard from "./topCard"
import WalletPaymentDecoder from "./decoder"
import Dialog from "../../../components/Dialog"

const CREATE_TRN_API = `wallet/transaction/create`

class WalletPayment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      result: null,
      rzp1: null,
      razorpay_order_id: null,
      razorpay_payment_id: null,
      razorpay_signature: null,
      paymentRetry: false,
      trn_id: null,
      transaction_created: false,
      amount: _toFixed(this.props.trn_amount),
      isCouponApplied: false,
      couponApplied: "",
      addedCouponValue: 0,
      couponInput: "",
      couponError: false,
      couponErrorMsg: "An error occured, try again later",
      isDialogOpen: false,
    }

    this.handleCouponChange = this.handleCouponChange.bind(this)
    this.handleCouponSubmit = this.handleCouponSubmit.bind(this)
    this.handleDialogState = this.handleDialogState.bind(this)
  }

  handleDialogState(_event) {
    this.setState((prevState) => ({
      isDialogOpen: !prevState.isDialogOpen,
    }))
  }

  handleCouponChange(event) {
    this.setState({ couponInput: event.target.value })
  }

  handleCouponSubmit(event) {
    event.preventDefault()
    const { couponInput, amount, isCouponApplied, couponApplied } = this.state

    if (couponInput === "") {
      this.setState({
        couponError: true,
        couponErrorMsg: `Enter a coupon code first.`,
      })
      return
    }

    if (isCouponApplied) {
      this.setState({
        couponError: true,
        couponErrorMsg: `Coupon ${couponApplied} already applied!`,
      })
      return
    }

    const matchedCoupons = COUPONS.filter(
      (coupon) => coupon.code === couponInput.toLowerCase()
    )
    if (matchedCoupons.length !== 0) {
      const { code, minimumRechargeValue, value } = matchedCoupons[0]
      if (code === "mvdouble") {
        if (amount >= minimumRechargeValue) {
          this.setState({
            isCouponApplied: true,
            couponApplied: code,
            couponError: false,
            addedCouponValue: parseFloat(amount),
            isDialogOpen: true,
          })
          return
        } else {
          this.setState({
            couponError: true,
            couponErrorMsg: `Minimum recharge amount for mvdouble is ${minimumRechargeValue}.`,
          })
          return
        }
      } else {
        if (amount >= minimumRechargeValue) {
          this.setState({
            isCouponApplied: true,
            couponApplied: code,
            couponError: false,
            addedCouponValue: parseFloat(value),
            isDialogOpen: true,
          })
          return
        } else {
          this.setState({
            couponError: true,
            couponErrorMsg: `Minimum recharge amount for ${couponInput} is ${minimumRechargeValue}.`,
          })
          return
        }
      }
    } else {
      this.setState({
        couponError: true,
        couponErrorMsg: "Code does not exist.",
      })
    }
  }

  createTransaction = () => {
    const { redirect, constants } = this.props
    const {
      amount,
      addedCouponValue,
      couponInput,
      isCouponApplied,
    } = this.state

    this.setState({ isLoading: true })
    instance({
      url: CREATE_TRN_API,
      method: "post",
      data: {
        trn_amount: amount,
        redirect,
        trn_roaming: constants.ROAMING ? 1 : 0,
        addedCouponValue,
        totalTalkTime: parseFloat(amount) + parseFloat(addedCouponValue),
        couponCode: isCouponApplied ? couponInput : null,
      },
    }).then((result) => {
      this.setState({ isLoading: false })
      const { trn_id, res, order_id } = result
      if (res === 1 && order_id != null) {
        const gtag_report_conversion = window.gtag_report_conversion
        gtag_report_conversion &&
          gtag_report_conversion(
            amount,
            constants.CURRENCY,
            `${trn_id}-before-payment`
          )
        this.setState({ order_id, trn_id }, this.setUpPayment)
      } else {
        this.setState({ result })
      }
    })
  }

  loadRazorpay = () => {
    const src = "https://checkout.razorpay.com/v1/checkout.js"
    return new Promise((resolve) => {
      const script = document.createElement("script")
      script.setAttribute("src", src)
      script.setAttribute("id", "mvRazorpay")
      script.onload = () => resolve(true)
      script.onerror = () => resolve(false)
      document.body.appendChild(script)
    })
  }

  setUpPayment = () => {
    this.loadRazorpay()
      .then((_res) => {
        const { user } = this.props
        const { order_id, amount } = this.state
        const { first_name, email, phone } = user
        const options = {
          order_id,
          key: "rzp_live_jsDLDdhxLI5cpr",
          amount,
          name: "Monkvyasa",
          description: "Wallet Recharge",
          modal: {
            ondismiss: () => this.setState({ paymentRetry: true }),
          },
          prefill: {
            name: first_name,
            contact: phone,
            email,
          },
          handler: this.paymentHandler,
        }
        const rzp1 = new window.Razorpay(options)
        this.setState({ rzp1, transaction_created: true }, this.showPayment)
      })
      .catch((e) => {
        console.error(e)
        this.setState({ paymentRetry: true })
      })
  }

  paymentHandler = (response) => {
    const {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
    } = response
    this.setState({
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
    })
  }

  showPayment = () => {
    const { rzp1 } = this.state

    if (rzp1) {
      this.setState({ paymentRetry: false, result: null }, () => rzp1.open())
    } else {
      this.setState({ paymentRetry: true })
    }
  }

  renderPaymentButton = () => {
    const { transaction_created } = this.state

    return (
      <div style={{ width: "100%", padding: 20, textAlign: "center" }}>
        <Button
          variant="outlined"
          style={{ backgroundColor: "#1fc055", color: "#fff" }}
          onClick={
            transaction_created ? this.showPayment : this.createTransaction
          }
        >
          {transaction_created ? `Retry Payment` : `Confirm Payment`}
        </Button>
      </div>
    )
  }

  render() {
    const {
      result,
      isLoading,
      trn_id,
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
      amount,
      couponError,
      couponErrorMsg,
      addedCouponValue,
      isCouponApplied,
      isDialogOpen,
    } = this.state
    const { redirect, classes } = this.props

    let cont = !isLoading ? (
      this.renderPaymentButton()
    ) : (
      <Progress loading={isLoading} />
    )

    if (razorpay_payment_id)
      cont = (
        <WalletPaymentDecoder
          redirect={redirect}
          trn_id={trn_id}
          rzparams={{
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
          }}
        />
      )

    return (
      <Grid container spacing={16}>
        <Grid item md={4} xs={12}>
          <WalletPaymentTopCard
            trn_amount={amount}
            couponValue={addedCouponValue}
          />
          <form
            style={{ textAlign: "center", paddingTop: "20px" }}
            onSubmit={this.handleCouponSubmit}
          >
            <FormControl error={couponError}>
              <TextField
                variant="outlined"
                id="component-error"
                placeholder="Enter Coupon"
                onChange={this.handleCouponChange}
                disabled={isCouponApplied}
                classes={{ root: classes.couponInput }}
              />
              {couponError ? (
                <FormHelperText id="component-error-text">
                  {couponErrorMsg}
                </FormHelperText>
              ) : null}
            </FormControl>
            <Fab
              type="submit"
              color="primary"
              disabled={isCouponApplied}
              variant="extended"
              className={classes.couponSubmitBtn}
            >
              {isCouponApplied ? "Applied" : "Apply"}
            </Fab>
          </form>
          <Dialog
            isOpen={isDialogOpen}
            handleClose={this.handleDialogState}
            title="Coupon Redeemed!"
            content="You have received additional talktime!"
          />
        </Grid>
        <Grid item md={8} xs={12}>
          {cont}
          <FormResult result={result} />
        </Grid>
      </Grid>
    )
  }
}

const styles = () => ({
  iframeWrapper: {
    height: "60vh",
    padding: 10,
  },
  couponInput: {
    width: "250px",
    borderRadius: "24px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "&$cssFocused": {
      borderRadius: "24px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  couponSubmitBtn: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: "56px",
    width: "75px",
    marginLeft: "-3px",
    marginTop: "-1px",
  },
})

const mapStateToProps = ({ auth, enums }) => {
  const constants = enums.rows.constants
  const trn_amount = _toFixed(getQueryStringValue("amount"))
  const redirect = getQueryStringValue("redirect")
  const user = auth.user

  return { trn_amount, redirect, constants, user }
}

export default connect(mapStateToProps)(withStyles(styles)(WalletPayment))
