import axios from "axios"
import { api_base } from "../../config"
import { objectToFormData } from "../functions/objectFunctions"

var instance = axios.create()
instance.defaults.timeout = 25000
instance.defaults.withCredentials = true

const searchParams = (params) =>
  Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    })
    .join("&")

instance.interceptors.request.use(function (config) {
  config.url = `${api_base}${config.url}`
  config.headers["Content-Type"] = "application/x-www-form-urlencoded"
  if (config.data && config.data !== null && typeof config.data === "object") {
    if (config.method === "get") {
      let get_params = searchParams(config.data)
      config.url = `${config.url}?${get_params}`
      delete config.data
    } else {
      config.data = objectToFormData(config.data)
    }
  }
  return config
})

instance.interceptors.response.use(
  function (response) {
    if (response.status !== 200)
      return { res: 2, msg: "Server Error! Something went wrong." }
    else return response.data
  },
  function (error) {
    let msg = "Error! Something went wrong - Please retry."
    return { res: 2, msg }
  }
)

export default instance
