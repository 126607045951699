import React from "react"
import Header from "./shared/Header"
import DisplayData from "./shared/DisplayData"

const DATA = `
<p class="strong">At Monkvyasa, we are reimagining the way people access Astrology Service and connect with Astrologers globally. We form one of the best market place to connect our customers and astrologers, ensuring best quality services, support, predictions and astrology solutions.</p>

<p class="strong">Monkvyasa has seen exponential growth with 100s of astrologer and 1000s of customers, accessing the portal and consulting our certified astrologers for day to day resolutions.</p>

<p class="strong">Monkvyasa bridges the gap between traditional services and new age technology for a high-quality solution in the Astrotech space. We maintain absolute privacy of data and information of all our customers, and keeping data privacy as one of our priorities.</p>

<h5 class="mt20 text-secondary">OUR VISION</h5>
<p>To help people across the world find the right astrology solution in their life and lead a happy one. We wish to create a community of Astrologers and Customers, helping each other with various other solutions including Tarot, Numerology, Reiki, Spiritual Healing and Mundane Astrology.
</p>

<h5 class="mt20 text-secondary">OUR MISSION</h5>
<p>We are working to build the world's largest community of people who seek, learn and share astrological, spiritual and well-being experiences on a single platform in a transparent, trustworthy and credible manner.
</p>

<p>Call Us :- 24x7 <br />
+91-777065664 <br />
hello@monkvyasa.com</p>
`

function AboutUs() {
  return (
    <>
      <Header title="About Us" />
      <DisplayData data={DATA} />
    </>
  )
}

export default AboutUs
