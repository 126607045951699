import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import GroupAddIcon from "@material-ui/icons/GroupAdd"

import BirthDetailsForm from "./components/form"
import BirthDetailsBlock from "./components/block"
import ModalComponent from "../../utils/components/modal"
import { updateRequirementBD } from "../requirement/actions"
import { fetchRows } from "./actions"
import { getBookingServiceDetails } from "../requirement/functions"

const styles = (theme) => ({})

class BirthDetails extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  componentDidMount = () => this.fetchRows()

  fetchRows = () => {
    if (this.props.auth.isLoggedIn) this.props.dispatch(fetchRows())
  }

  renderRows = () => {
    let { ids, requirement_id, canSelectMore, canEdit } = this.props
    return (
      <div>
        {ids.map((x) => (
          <div style={{ marginTop: 20, marginBottom: 10 }} key={x}>
            <BirthDetailsBlock
              requirement_id={requirement_id}
              birth_id={x}
              canSelectMore={canSelectMore}
              canEdit={canEdit}
            />
          </div>
        ))}
      </div>
    )
  }

  addButton = () => (
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      onClick={() => this.setState({ modalOpen: true })}
      fullWidth
    >
      <GroupAddIcon style={{ marginRight: 10, color: "green" }} /> Add new birth
      details
    </Button>
  )

  handleBDSuccess = (birth_id) => {
    this.setState({ modalOpen: false })
    let { canSelectMore, dispatch, requirement_id } = this.props
    if (canSelectMore) dispatch(updateRequirementBD(requirement_id, birth_id))
  }

  render() {
    let { bds_mx, bds_min, showForm, rows, canSelectMore, canEdit } = this.props
    return (
      <div>
        {this.renderRows()}
        {!showForm && canEdit && this.addButton()}
        {showForm && (
          <BirthDetailsForm
            canSelectMore={canSelectMore}
            onSuccess={this.handleBDSuccess}
          />
        )}
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Add new birth details"
          content={() => (
            <Card>
              <CardContent>
                <BirthDetailsForm onSuccess={this.handleBDSuccess} />
              </CardContent>
            </Card>
          )}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  { enums, birthDetails, astrologers, requirement: requirementList, auth },
  { requirement_id }
) => {
  let requirement = requirementList.rows[requirement_id]
  let { requirement_bds, mr_service_id, my_report_id } = requirement
  let my_report_services = enums.rows.my_report_services.raw
  let report_services = enums.rows.report_services.raw
  let astrologer = astrologers.rows[requirement.astrologer_id]

  let { bds_min, bds_mx } = getBookingServiceDetails(
    requirement,
    astrologer,
    my_report_services,
    report_services
  )
  let showForm = bds_min > Object.values(birthDetails.rows).length
  let canSelectMore = bds_min > requirement_bds.length
  return {
    rows: birthDetails.rows,
    ids: birthDetails.ids,
    selected: requirement_bds,
    bds_mx,
    bds_min,
    showForm,
    canSelectMore,
    auth,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(BirthDetails))
