import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import { guid, convertToBDTime } from "../../../utils/functions"
import { updateBD } from "../actions"
import { FormResult } from "../../../utils/form"

class BirthDetailsForm extends Component {
  state = {
    bd: {},
  }

  componentWillMount = () => {
    this.setState({
      bd: this.props.bd,
      error: null,
    })
  }

  handleChange = (name) => (event) => {
    let bd = { ...this.state.bd }
    bd[name] = event.target.value
    this.setState({ bd })
  }

  handleSubmit = () => {
    let bd = this.state.bd
    let error = {}
    if (!bd.birth_name) error.birth_name = "Please enter birth name"
    if (!bd.birth_pob) error.birth_pob = "Please enter birth place"
    if (!bd.birth_dob) error.birth_dob = "Please enter birth time"
    this.setState({ error })
    if (Object.values(error).length) return
    this.props.dispatch(updateBD(bd))
    /*if( !this.props.onEdit )
			this.props.dispatch( pushBd( bd ,false ) )	
		else {
			if( this.props.auth.isLoggedIn ){}
			this.props.dispatch( updateBd( bd ) )
		}*/
    this.props.onSuccess && this.props.onSuccess(bd.birth_id)
  }

  render() {
    let { classes } = this.props
    return (
      <div>
        <TextField
          label="Name"
          fullWidth
          className={classes.textField}
          value={this.state.bd.birth_name}
          onChange={this.handleChange("birth_name")}
          margin="normal"
        />
        <RadioGroup
          row
          aria-label="Gender"
          name="birth_gender"
          className={classes.group}
          value={`${this.state.bd.birth_gender}`}
          onChange={this.handleChange("birth_gender")}
        >
          <FormControlLabel value="1" control={<Radio />} label="Male" />
          <FormControlLabel value="2" control={<Radio />} label="Female" />
        </RadioGroup>
        <TextField
          fullWidth
          label="Birth Time"
          type="datetime-local"
          className={classes.textField}
          value={this.state.bd.birth_dob}
          onChange={this.handleChange("birth_dob")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Birth Place"
          fullWidth
          className={classes.textField}
          value={this.state.bd.birth_pob}
          onChange={this.handleChange("birth_pob")}
          margin="normal"
        />
        <FormResult result={{ error: this.state.error }} />
        <Button
          variant="outlined"
          className={classes.saveButton}
          fullWidth
          onClick={this.handleSubmit}
        >
          {this.props.onEdit ? `Update Birth details` : `Add Birth details`}
        </Button>
      </div>
    )
  }
}

const styles = (theme) => ({
  textField: {},
  saveButton: {
    margin: `0 ${theme.spacing.unit * 2}px`,
  },
  saveButton: {
    margin: `${theme.spacing.unit * 2}px 0px `,
  },
})

const mapStateToProps = ({ requirement, birthDetails, auth }, { id }) => {
  let onEdit = false
  let d = new Date()
  let n = d.getTime()
  let birth_id = guid() + n
  let bd = {
    birth_id,
    birth_name: "",
    birth_pob: "",
    birth_dob: convertToBDTime(new Date()), //'2017-05-24T10:30'
    birth_gender: 1,
    isSaved: false,
  }
  if (id) {
    bd = birthDetails.rows[id]
    onEdit = true
  }
  return { bd, onEdit, auth }
}

export default connect(mapStateToProps)(withStyles(styles)(BirthDetailsForm))
