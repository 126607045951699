import React, { useEffect, useRef, useState } from "react"
import { apiv2Instance, socketClient } from "../../../../utils"
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"

function ChatScreen(props) {
  const chatRoomInfo = JSON.parse(sessionStorage.getItem("chatRoomInfo"))
  const { closeModal } = props
  const [dialog, setDialog] = useState({
    isOpen: false,
    text: "",
    showActions: false,
  })
  const [isUserDisconnected, setIsUserDisconnected] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [nextPageRef, setNextPageRef] = useState(1)
  const [lastMessageRef, setLastMessageRef] = useState(null)
  const [messages, setMessages] = useState([])
  const userDisconnectTimer = useRef(null)
  const userDisconnectTimer2 = useRef(null)
  const chatTimeoutRef = useRef(null)

  async function loadMoreChats() {
    const { data } = await apiv2Instance.get(
      `chat?chatRoomRef=${chatRoomInfo.chatRoomId}&lastMessageId=${lastMessageRef}&page=${nextPageRef}`
    )
    const { chats, hasMore, nextPage } = data
    const { _id } = chats[chats.length - 1]
    setMessages([...messages, ...chats])
    setHasMore(hasMore)
    setNextPageRef(nextPage)
    setLastMessageRef(_id)
  }

  useEffect(() => {
    async function getChats() {
      const { data } = await apiv2Instance.get(
        `chat?chatRoomRef=${chatRoomInfo.chatRoomId}`
      )
      const { chats, hasMore, nextPage } = data
      const { _id } = chats[chats.length - 1]
      setMessages([
        ...messages,
        {
          _id: "automated-msg",
          from: "astrologer",
          sentAt: new Date(),
          body:
            "Thanks for connecting, please wait 2 minutes while the astrologer prepares your results",
          type: "text",
        },
        ...chats,
      ])
      setHasMore(hasMore)
      setNextPageRef(nextPage)
      setLastMessageRef(_id)
    }
    getChats()
  }, [])

  useEffect(() => {
    socketClient.on("RECEIVE_MESSAGE", function (payload) {
      setMessages([{ ...payload }, ...messages])
      const getBottom = document.querySelector("#scrollable")
      getBottom.scrollTop = getBottom.scrollHeight
    })

    return () => {
      socketClient.off("RECEIVE_MESSAGE")
    }
  }, [messages])

  useEffect(() => {
    if (!isUserDisconnected) {
      socketClient.on("USER_DISCONNECT", function () {
        setDialog({
          text: "Astrologer disconnected, trying to reconnect...",
          isOpen: true,
          showActions: false,
        })
        setIsUserDisconnected(true)
        userDisconnectTimer.current = setTimeout(() => {
          setDialog({
            ...dialog,
            text: "Ending chat, astrologer didn't reconnect",
            showActions: false,
          })
        }, 60000)
        userDisconnectTimer2.current = setTimeout(() => {
          closeModal()
        }, 62000)
      })
    } else {
      socketClient.on("RECONNECTED", function () {
        clearTimeout(userDisconnectTimer.current)
        clearTimeout(userDisconnectTimer2.current)
        userDisconnectTimer.current = null
        userDisconnectTimer2.current = null
        setDialog({ ...dialog, text: "User reconnected!", showActions: true })
        setIsUserDisconnected(false)
      })
    }

    return () => {
      if (!isUserDisconnected) {
        socketClient.off("USER_DISCONNECT")
      } else {
        socketClient.off("RECONNECTED")
      }
    }
  }, [isUserDisconnected])

  useEffect(() => {
    socketClient.on("CHAT_ENDED", function () {
      setDialog({
        isOpen: true,
        text: "Chat session has been ended.",
        showActions: false,
      })
      const chatRoomInfo = JSON.parse(sessionStorage.getItem("chatRoomInfo"))
      chatRoomInfo.hasChatEnded = true
      sessionStorage.setItem("chatRoomInfo", JSON.stringify(chatRoomInfo))
      setTimeout(() => {
        closeModal()
      }, 3000)
    })

    chatTimeoutRef.current = setTimeout(() => {
      socketClient.emit("END_CHAT", { chatRoomId: chatRoomInfo.chatRoomId })
    }, parseInt(chatRoomInfo.chatTimeInMins) * 60000)

    return () => {
      clearTimeout(chatTimeoutRef.current)
      socketClient.off("CHAT_ENDED")
    }
  }, [])

  function Message(props) {
    const { body, from, sentAt } = props.message
    const parsedDate = Date.parse(sentAt)
    const timestamp = new Date(parsedDate)
    const { userId } = chatRoomInfo

    return (
      <div
        style={{
          whiteSpace: "pre-wrap",
          padding: "10px",
          borderRadius: "4px",
          maxWidth: "90%",
          marginTop: "10px",
          marginLeft: from === userId ? "auto" : null,
          marginRight: from !== userId ? "auto" : null,
          backgroundColor: from === userId ? "#7b1fa2" : "#dedede",
        }}
      >
        <Typography
          style={{
            color: from === userId ? "white" : null,
          }}
        >
          {body}
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: from === userId ? "#c9c9c9" : null,
            textAlign: from === userId ? "right" : null,
          }}
        >
          {`${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString()}`}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <InfiniteScroll
        dataLength={messages.length}
        next={loadMoreChats}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        hasMore={hasMore}
        loader={
          <CircularProgress style={{ margin: "0 auto", marginTop: "20px" }} />
        }
        scrollableTarget="scrollable"
        scrollThreshold={0.8}
      >
        {messages.map((message) => (
          <Message key={message._id} message={message} />
        ))}
      </InfiniteScroll>
      <Dialog open={dialog.isOpen} disableBackdropClick disableEscapeKeyDown>
        <DialogContent>
          <Typography>{dialog.text}</Typography>
        </DialogContent>
        {dialog.showActions ? (
          <DialogActions>
            <Button
              type="contained"
              color="primary"
              onClick={(_e) => setDialog({ ...dialog, isOpen: false })}
            >
              Ok
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  )
}

export default ChatScreen
