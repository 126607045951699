import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CloudOffIcon from "@material-ui/icons/CloudOff"

const styles = {
  card: {
    minWidth: 275,
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}

function NotFound(props) {
  const { classes } = props
  const bull = <span className={classes.bullet}>•</span>

  return (
    <Card className={classes.card}>
      <CardContent>
        <CloudOffIcon
          className={classes.icon}
          color="disabled"
          style={{ fontSize: 100, margin: 20 }}
        />

        <Typography variant="h5" component="h2">
          Page not Found
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Somethig went wrong!
        </Typography>
        <Typography component="p">
          If you think it is bug
          <br />
          {'"Please let us know."'}
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => props.history.replace("/")}
          style={{ marginTop: 20 }}
        >
          Go Home
        </Button>
      </CardContent>
    </Card>
  )
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(NotFound))
