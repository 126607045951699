import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import AstrologerListCard from "../../astrologer/components/listCard"
import { prioritizeAstrologers } from "../../../utils/functions"

function AstrologerSlick(props) {
  const { classes, astrologers } = props
  const [data, setData] = useState([])

  useEffect(() => {
    const FIVE_MINUTES = 300000
    const temp = prioritizeAstrologers(astrologers)
    setData(temp)

    const refreshInterval = setInterval(() => {
      const temp = prioritizeAstrologers(astrologers)
      setData(temp)
    }, FIVE_MINUTES)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  return (
    <Grid container spacing={0}>
      {data.map((astrologer) => (
        <Grid
          item
          key={astrologer.id}
          xs={12}
          md={6}
          className={classes.lsitItem}
        >
          <AstrologerListCard id={astrologer.id} />
        </Grid>
      ))}
    </Grid>
  )
}

const styles = (theme) => ({
  slide: {
    [theme.breakpoints.up("md")]: {
      margin: 5,
    },
  },
})

const mapStateToProps = ({ astrologers }) => {
  const data = Object.values(astrologers.rows).map((astrologer) => astrologer)

  return {
    astrologers: data,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(AstrologerSlick))
