import { instance } from "../../utils"
import { convertToBDTime, convertMysqlDateTime } from "../../utils/functions"
const SAVE_BD_API = `birthdetail/birth_detail/update`
const REMOVE_BD_API = `birthdetail/birth_detail/remove`
const FETCH_BD_API = `birthdetail/birth_detail/get_rows`

export const updateBD = (row) => (dispatch, getState) => {
  let birth_id = `${row.birth_id}`
  let existing = getState().birthDetails.ids.includes(birth_id)
  let isSaved = row.birth_id == parseInt(row.birth_id)
  if (existing) dispatch(updateBDRow(row))
  else dispatch(pushBDRow(row))
  if (isSaved) dispatch(saveBDRowDB(row))
}

export const pushBDRow = (row) => (dispatch) => {
  row.birth_id = `${row.birth_id}`
  return dispatch({
    type: "PUSH_BD_ROW",
    payload: { row },
  })
}

export const updateBDRow = (row) => (dispatch) => {
  return dispatch({
    type: "UPDATE_BD_ROW",
    payload: { row },
  })
}

export const saveBDRowDB = (row) => (dispatch) => {
  dispatch(updateField(row.birth_id, "isLoading", true))
  dispatch({
    type: "SAVE_BD",
    payload: instance({
      method: "post",
      url: SAVE_BD_API,
      data: row,
    }).then((result) => {
      let { birth_id_new, birth_id_old } = result
      if (birth_id_new == birth_id_old) {
        dispatch(updateField(row.birth_id, "isLoading", false))
        return
      }
      dispatch({
        type: "UPDATE_BIRTH_ID",
        payload: { id_old: `${birth_id_old}`, id_new: `${birth_id_new}` },
      })
      dispatch({
        type: "REPLACE_REQUIREMENT_BD",
        payload: {
          birth_id_new: `${birth_id_new}`,
          birth_id_old: `${birth_id_old}`,
        },
      })
    }),
  })
}

export const removeBDRow = (id) => (dispatch, getState) => {
  let isSaved = id == parseInt(id)
  if (isSaved)
    instance({
      method: "post",
      url: REMOVE_BD_API,
      data: { birth_id: id },
    })

  dispatch({
    type: "REMOVE_REQUIREMENT_BD",
    payload: { birth_id: id },
  })

  dispatch({
    type: "REMOVE_BD_ROW",
    payload: id,
  })
}

export const updateField = (rowId, field, value) => (dispatch) => {
  return dispatch({
    type: "UPDATE_BD_COLUMN",
    payload: { rowId, field, value },
  })
}

export const fetchRows = () => (dispatch) => {
  dispatch({
    type: "FETCH_BD_ROWS",
    payload: instance({
      method: "post",
      url: FETCH_BD_API,
    }).then(({ rows }) => {
      rows.map((row) => {
        row.birth_dob = convertToBDTime(convertMysqlDateTime(row.birth_dob))
        dispatch(pushBDRow(row, true))
      })
    }),
  })
}
