import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import AstrologerMainCard from "./mainCard"

class AstrologerLeftCard extends Component {
  render() {
    let { id } = this.props
    return <AstrologerMainCard id={id} />
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(AstrologerLeftCard))
