import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import { FormResult } from "../../utils/form"
import { instance } from "../../utils"
import { getQueryStringValue } from "../../utils/functions"
import { Progress } from "../../utils/components"

import AstrologerLeftCard from "../astrologer/components/leftCard"
import PhonecallInteractionInit from "./interaction/init"
import PhonecallInteractionTracker from "./interaction/tracker"
import PhonecallInteractionDetails from "./interaction/details"

const API = `phonecall/get_row`
class PhonecallRoot extends Component {
  state = {
    isLoading: true,
    result: false,
    row: {},
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.phonecall_id !== prevProps.phonecall_id) this.fetchRow()
  }

  componentWillMount = () => this.fetchRow()

  updateRow = (newRow) => {
    let { row } = this.state
    row = Object.assign({}, row, newRow)
    this.setState({ row })
  }

  fetchRow = () => {
    let { phonecall_id } = this.props
    instance({
      method: "post",
      url: API,
      data: { filter: { phonecall_id } },
    }).then((result) => {
      let { row, res } = result
      this.setState({ isLoading: false, row })
      if (row == null || res != 1) {
        this.setState({ result })
        return
      } else {
        let { phonecall_id } = row
        row.call_status = parseInt(row.call_status)
        this.setState({ row, phonecall_id })
      }
    })
  }

  renderContent = () => {
    let { phonecall_id, row } = this.state
    let call_status = row.call_status
    if (call_status == 1)
      return (
        <PhonecallInteractionInit
          phonecall_id={phonecall_id}
          row={row}
          updateRow={this.updateRow}
          onComplete={this.fetchRow}
        />
      )
    if (call_status > 1 && call_status < 4)
      return (
        <PhonecallInteractionTracker
          phonecall_id={phonecall_id}
          row={row}
          updateRow={this.updateRow}
          onComplete={this.fetchRow}
        />
      )
    else
      return (
        <PhonecallInteractionDetails
          phonecall_id={phonecall_id}
          row={row}
          updateRow={this.updateRow}
          fetchRow={this.fetchRow}
        />
      )
  }

  render() {
    let { isLoading, result, row } = this.state
    let { phone_call_status } = this.props
    if (isLoading) return <Progress loading={true} />
    if (row == null) return <FormResult result={result} />
    return (
      <Grid container spacing={16}>
        <Grid item md={3} xs={12}>
          <AstrologerLeftCard id={row.astrologer_id} />
        </Grid>
        <Grid item md={9} xs={12}>
          <Typography variant="subtitle1" color="secondary">
            {phone_call_status[row.call_status]}
          </Typography>
          {this.renderContent()}
        </Grid>
      </Grid>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ enums }) => {
  let phonecall_id = getQueryStringValue("phonecall_id")
  let phone_call_status = enums.rows.phone_call_status.vt
  return { phonecall_id, phone_call_status }
}
export default connect(mapStateToProps)(withStyles(styles)(PhonecallRoot))
