import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import ReportRow from "../row"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

class ReportSolutionYear extends Component {
  state = {
    isLoading: true,
    row: this.props.row || {},
    result: null,
    report: {},
    onEdit: !this.props.solutionAdded,
    tabVal: null,
    showAgeForm: !this.props.solutionAdded,
    submitIsLoading: false,
  }

  componentWillMount = () => {
    if (this.props.row) return
    var d = new Date()
    let row = { year_year: d.getFullYear(), year_month_: d.getMonth() + 1 }
    this.setState({ row })
  }

  renderRow = (name, title, val) => (
    <ReportRow key={name} title={title} text={val} />
  )

  renderRows = () => {
    let { row } = this.state
    let { year_month_, year_year } = row
    let rows = []
    for (var i = 1; i <= 12; i++) {
      if (year_month_ == 13) {
        year_month_ = 1
        year_year++
      }
      let yrmnth = year_month_ - 1
      let monthName = monthNames[yrmnth]
      rows.push(
        this.renderRow(
          year_month_,
          `${monthName} - ${year_year}`,
          row[year_month_] || null
        )
      )
      year_month_++
    }
    rows.push(
      this.renderRow(
        "year_conclusion",
        "Conclusion",
        row.year_conclusion || null
      )
    )
    return <React.Fragment>{rows}</React.Fragment>
  }

  render() {
    return <div>{this.renderRows()}</div>
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReportSolutionYear))
