import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  withStyles,
} from "@material-ui/core"
import LeftMenu from "./leftMenu"
import Notification from "../../notification"
import { _toFixed } from "../../../../utils/functions"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import PersonIcon from "@material-ui/icons/Person"

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: 0,
  },
  appBar: {
    paddingLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
  },
  title: {
    display: "block",
    fontSize: 20,
    fontWeight: 400,
    cursor: "pointer",
    marginTop: "3px",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  subMenu: {
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  globeButton: {
    marginRight: 0,
    paddingRight: 0,
    color: "#fff",
  },
  menuItem: {
    color: "white",
    textDecoration: "none",
    marginRight: "13px",
    marginTop: "2px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  walletText: {
    color: "white",
    display: "contents",
    cursor: "pointer",
  },
  icon: {
    fontSize: 24,
    margin: "0 auto",
  },
})

const menuItemsDefault = [
  {
    text: "Home",
    icon: "home",
    href: "/",
    external: false,
  },
  {
    text: "Astrologers",
    icon: "people",
    href: "/astrologers",
    external: false,
  },
  /*
  {
    text: "My Favorites",
    icon: "favorite",
    href: "/astrologers?favorite=1",
  },
  */
  {
    text: "About Us",
    icon: "info",
    href: "about",
    external: false,
  },
  {
    text: "Blog",
    icon: "book",
    href: "https://blog.monkvyasa.com",
    external: true,
  },
  {
    text: "Contact Us",
    icon: "contacts",
    href: "/contact-us",
    external: false,
  },
]

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.handleMobileMenuClose()
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  render() {
    const { classes, auth, history } = this.props

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftMenu}>
            <LeftMenu />
          </div>
          <Typography
            onClick={() => history.push("/")}
            className={classes.title}
            variant="h6"
            color="inherit"
            noWrap
          >
            Monkvyasa
          </Typography>
          <div className={classes.grow} />
          <React.Fragment>
            {menuItemsDefault.map((menuItem) =>
              menuItem.external ? (
                <Button
                  key={menuItem.text}
                  className={classes.menuItem}
                  href={menuItem.href}
                  target="_blank"
                >
                  {menuItem.text}
                </Button>
              ) : (
                <Button
                  key={menuItem.text}
                  className={classes.menuItem}
                  onClick={(_event) => history.push(menuItem.href)}
                >
                  {menuItem.text}
                </Button>
              )
            )}
            {auth.isLoggedIn && (
              <Typography
                className={classes.walletText}
                onClick={(_event) => history.push("/wallet")}
              >
                <AccountBalanceWalletIcon className={classes.icon} />
                <p style={{ padding: "2px 0px 0px 3px" }}>
                  {`${_toFixed(auth.user.wallet)}`}{" "}
                  {this.props.constants.CURRENCY}
                </p>
              </Typography>
            )}
            {auth.isLoggedIn && <Notification />}
            {(!auth.isLoggedIn && window.innerWidth < 500) ||
            window.innerWidth > 500 ? (
              <Button
                variant="text"
                onClick={() => history.push("/profile")}
                color="inherit"
              >
                <PersonIcon />
              </Button>
            ) : null}
          </React.Fragment>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth, enums }) => {
  const constants = enums.rows.constants
  return { auth, constants }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Header)))
