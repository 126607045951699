import React, { Component } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { withStyles, Typography, Grid, Card, Divider } from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import {
  getQueryStringValue,
  convertMysqlDateTime,
} from "../../../utils/functions"
import { instance } from "../../../utils"
import AstrologerLeftCard from "../components/leftCard"
import AstrologerShareButtons from "../components/shareButtons"
import ReportBookingButton from "../../requirement/components/button"
import PhonecallButton from "../../phonecall/components/button"
import LiveChatButton from "../../../components/live-chat/button"
import CustomSelectServices from "../../custom/components/selectServices"

const API_REVIEWS = `review/review_public/get_rows`

class AstrologerProfile extends Component {
  state = {
    reviews: [],
  }

  componentWillMount = () => this.fetchReviews()

  fetchReviews = () => {
    instance({
      method: "post",
      url: API_REVIEWS,
      data: { filter: { astrologer_id: this.props.id, "review_text!=": "" } },
    }).then(({ rows }) => {
      this.setState({ reviews: rows ? Object.values(rows) : [] })
    })
  }

  renderButtons = () => {
    const { phone_service_rate } = this.props.row.phone_service
    const { classes, row } = this.props
    const { report_service_rate, currency } = row
    const service_rate = report_service_rate ? (
      <span>
        {report_service_rate} <span style={{ color: "#999" }}>{currency}</span>
      </span>
    ) : (
      `-NA-`
    )
    const phonecall_rate = phone_service_rate ? (
      <span>
        {row.phone_service_rate}{" "}
        <span style={{ color: "#999" }}>{currency}</span>/min{" "}
      </span>
    ) : (
      `-NA-`
    )
    const chat_rate = phone_service_rate ? (
      <span>
        {row.phone_service_rate}{" "}
        <span style={{ color: "#999" }}>{currency}</span>/min{" "}
      </span>
    ) : (
      `-NA-`
    )

    return (
      <div className={classes.actions}>
        <div className={classes.actionItem}>
          {service_rate}
          <ReportBookingButton id={this.props.id} />
        </div>
        <div className={classes.actionItem}>
          {phonecall_rate}
          <PhonecallButton id={this.props.id} />
        </div>
        <div className={classes.actionItem}>
          {chat_rate}
          <LiveChatButton id={this.props.id} />
        </div>
      </div>
    )
  }

  renderHead = (text) => (
    <Typography variant="subheading" color="primary" style={{ marginTop: 20 }}>
      {text}
    </Typography>
  )
  renderCard = (content) => (
    <Card style={{ margin: "5px 0", padding: 10 }}>{content}</Card>
  )

  renderReviews = () => {
    const { reviews } = this.state
    const reviews_length = reviews.length
    if (!reviews_length) return null
    const content = (
      <div>
        {reviews.map((x, index) => {
          if (x.review_text === "") return null
          return (
            <div key={x.review_id} style={{ marginBottom: 20 }}>
              <div className={this.props.classes.rating}>
                {Math.ceil(x.review_rating)}
                <StarIcon className={this.props.classes.ratingStar} />
              </div>
              <Typography variant="subtitle1" color="primary">
                {x.first_name_user}
              </Typography>
              {x.review_join_date && (
                <Typography variant="caption">
                  {convertMysqlDateTime(x.review_join_date, true)}
                </Typography>
              )}
              <Typography paragraph>{x.review_text}</Typography>
              {reviews_length !== index + 1 && <Divider />}
            </div>
          )
        })}
      </div>
    )
    return (
      <div>
        {this.renderHead("Reviews & Ratings")}
        {this.renderCard(content)}
      </div>
    )
  }

  renderProfileRow = (lText, rText) => (
    <Grid container spacing={16}>
      <Grid item xs={6}>
        <Typography color="secondary" style={{ marginLeft: 20 }}>
          {" "}
          {lText}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{rText}</Typography>
      </Grid>
    </Grid>
  )

  renderProfile = () => {
    const { row, doing_astrology_as, learn_by } = this.props
    return (
      <div>
        <Typography
          dangerouslySetInnerHTML={{ __html: row.biography }}
          paragraph
        />
        <Divider style={{ margin: "15px 0" }} />
        {this.renderProfileRow(
          "Doing astrology as",
          doing_astrology_as[row.doing_astrology_as]
        )}
        {this.renderProfileRow("Learn by", learn_by[row.learn_by])}
        {this.renderProfileRow("Guru name", row.guru_name)}
        {this.renderProfileRow("Experience", `${row.experience} Years`)}
      </div>
    )
  }

  renderContent = () => {
    const { classes, id, row } = this.props

    return (
      <Grid container className={classes.content}>
        <Grid item md={4} xs={12}>
          <AstrologerLeftCard id={id} />
          {this.renderButtons()}
        </Grid>
        <Grid item md={8} xs={12}>
          <div
            style={{
              width: "100%",
              float: "right",
              textAlign: "right",
              paddingRight: 30,
            }}
          >
            <AstrologerShareButtons
              title={`${row.first_name} ${row.last_name}`}
            />
          </div>
          {this.renderHead("Biography")}
          {this.renderCard(this.renderProfile())}
          {this.renderHead("Custom services offering")}
          <CustomSelectServices id={id} />
          {this.renderReviews()}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { row } = this.props
    return (
      <div>
        <Helmet>
          <title>{`${row.first_name} ${row.last_name || ""}`}</title>
          {row.biography && <meta name="description" content={row.biography} />}
        </Helmet>
        {this.renderContent()}
      </div>
    )
  }
}

const styles = (theme) => ({
  content: {
    marginBottom: "10vh",
  },
  actions: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "#fff",
    paddingTop: 10,
    marginLeft: -10,
    borderTop: "1px solid #e6d6f1",
    margin: 0,
    position: "fixed",
    zIndex: 100,
    bottom: 0,
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      zIndex: 1,
      border: "1px solid #e6d6f1",
    },
  },
  actionItem: {
    flexGrow: 1,
  },
  rating: {
    backgroundColor: "#37be5f",
    color: "#f7f7f7",
    fontSize: 12,
    display: "inline-block",
    margin: 0,
    padding: "3px 4px",
    float: "left",
    marginRight: 5,
  },
  ratingCount: {
    color: "#999",
    fontSize: 11,
    textAlign: "center",
  },
  ratingStar: {
    fontSize: 14,
    marginLeft: 2,
  },
})

const mapStateToProps = ({ astrologers, enums }) => {
  const id = getQueryStringValue("id")
  const row = astrologers.rows[id]
  const doing_astrology_as = enums.rows.doing_astrology_as.vt
  const learn_by = enums.rows.learn_by.vt
  return { row, id, doing_astrology_as, learn_by }
}

export default connect(mapStateToProps)(withStyles(styles)(AstrologerProfile))
