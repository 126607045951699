import React, { Component } from "react"
import { connect } from "react-redux"
import {
  withStyles,
  Typography,
  TextField,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

class AstrologerListFilter extends Component {
  state = {
    cityArray: {},
    languageArray: {},
  }

  componentWillMount = () => {
    const { rows, languages } = this.props
    let cityArray = {}
    let languageArray = {}
    Object.values(rows).map((row) => {
      cityArray[row.city] = row.city
      row.languages
        .split(",")
        .map((item) => (languageArray[item] = languages[item]))
    })
    this.setState({ cityArray, languageArray })
  }

  render() {
    const { cityArray, languageArray } = this.state
    const { applyStringFilter, applyArrayFilter, filter } = this.props

    return (
      <div style={{ padding: 30 }}>
        {false && (
          <TextField
            label="Search by Name"
            defaultValue={filter.name}
            onChange={(event) => applyStringFilter("name", event.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        <Typography variant="subtitle1" color="primary">
          Filter by City
        </Typography>
        <Divider />
        <FormGroup>
          {Object.values(cityArray).map((val) => (
            <FormControlLabel
              key={val}
              control={
                <Checkbox
                  value={val}
                  onChange={(event) =>
                    applyArrayFilter("city", event.target.value)
                  }
                  checked={filter.city.includes(val)}
                />
              }
              label={val}
            />
          ))}
        </FormGroup>
        <Typography variant="subtitle1" color="primary">
          Filter by Language
        </Typography>
        <Divider />
        <FormGroup>
          {Object.keys(languageArray).map((val) => (
            <FormControlLabel
              key={val}
              control={
                <Checkbox
                  value={val}
                  onChange={(event) =>
                    applyArrayFilter("language", event.target.value)
                  }
                  checked={filter.language.includes(val)}
                />
              }
              label={languageArray[val]}
            />
          ))}
        </FormGroup>
      </div>
    )
  }
}

const styles = (_theme) => ({})

const mapStateToProps = ({ astrologers, enums }) => {
  return {
    rows: astrologers.rows,
    languages: enums.rows.languages.vt,
    professions: enums.rows.professions.vt,
  }
}
export default connect(mapStateToProps)(
  withStyles(styles)(AstrologerListFilter)
)
