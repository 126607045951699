import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import LinearProgress from "@material-ui/core/LinearProgress"

import { FormResult } from "../../../utils/form"
import { instance } from "../../../utils"

const API = `phonecall/phonecall_tracking/status`

class PhonecallInteractionTracker extends Component {
  state = {
    result: null,
    isLoading: true,
    completed: 0,
    buffer: 10,
    call_status: null,
    track_count: 0,
    track_count_max: this.props.track_count || 20,
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 500)
    this.trackCall()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    //this.trackCall()
  }

  progress = () => {
    const { completed } = this.state
    if (completed > 100) {
      this.setState({ completed: 0, buffer: 10 })
    } else {
      const diff = Math.random() * 10
      const diff2 = Math.random() * 10
      this.setState({
        completed: completed + diff,
        buffer: completed + diff + diff2,
      })
    }
  }

  trackCall = () => {
    let { track_count, track_count_max } = this.state
    track_count++
    this.setState({ track_count })
    if (track_count >= track_count_max) return
    let { row, updateRow, onComplete } = this.props
    instance({
      method: "post",
      url: API,
      data: { call_sid: row.call_sid },
    }).then((result) => {
      if (result.res != 1) this.setState({ result })
      else {
        let { call_status } = result
        this.setState({ call_status })
        updateRow({ call_status })
        if (parseInt(call_status) < 4)
          setTimeout(() => this.trackCall(), track_count * 300)
        if (parseInt(call_status) >= 4) onComplete && onComplete()
      }
    })
  }

  restartTracking = () => {
    this.setState(
      {
        track_count: 0,
      },
      this.trackCall
    )
  }

  renderLoader = () => {
    let {
      completed,
      buffer,
      call_status,
      track_count,
      track_count_max,
    } = this.state
    let { phone_call_status } = this.props
    if (track_count >= track_count_max)
      return (
        <div>
          <Typography color="primary">Call updating is on hold</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.restartTracking}
          >
            Refresh
          </Button>
        </div>
      )
    return (
      <div>
        <Typography color="primary">
          {call_status ? phone_call_status[phone_call_status] : `tracking..`}
        </Typography>
        <LinearProgress
          color="primary"
          variant="buffer"
          value={completed}
          valueBuffer={buffer}
        />
      </div>
    )
  }

  render() {
    const { result } = this.state
    return (
      <div>
        <Card style={{ margin: "10px 0", textAlign: "center" }}>
          <CardContent>
            <FormResult result={result} />
            {this.renderLoader()}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ enums }) => {
  let phone_call_status = enums.rows.phone_call_status.vt
  return { phone_call_status }
}
export default connect(mapStateToProps)(
  withStyles(styles)(PhonecallInteractionTracker)
)
