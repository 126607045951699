import React, { Component } from "react"
import "typeface-roboto"
import { Helmet } from "react-helmet"
import Root from "./navigation/root"
import configureStore from "./store"
import AppUpdate from "./components/AppUpdate"

let { store } = configureStore()

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{`India's No.1 Website For Astrologer Consultation Online`}</title>
          <meta
            name="description"
            content="Best website for astrologer consultation online. Expert astrologer consultation on phone. Talk to expert astrologers. Horoscope report from expert astrologers online. Horoscope matching and one-year horoscope report online. "
          />
        </Helmet>
        <Root store={store} />
        <AppUpdate />
      </React.Fragment>
    )
  }
}

export default App
