import React from "react"
import Header from "./shared/Header"
import DisplayData from "./shared/DisplayData"

const DATA = `<p>Monkvyasa.com can collect personal information, including contact information (such as an email address), from its users in order to operate the Website in an efficient and effective manner and to provide users with information on astrology services & products that may be of interest to them. Furthermore, this data enables us to provide important astrological information to users.stand, protect and serve users in general.</p>
<p>You accept that your personal information can be used by Monkvyasa.com to contact you and to provide you with information that, in certain cases, is tailored to your interests or provides administrative notifications or correspondence related to your use of the Site. You freely consent to obtaining this information by implementing this Privacy Policy. We allow you to opt out of any further reception if you do not wish to receive these communications by following the opt-out provisions included in each such correspondence.
</p>
<p>For any reason, we do not sell the personal information of our users to others if the user has expressed a preference for us to keep the information private. Our clients decide for themselves how much contact information they want to show when placing an order for an astrology service.</p>

<p>However, all users should be aware the that information can be gathered and used by others when they willingly view or share personal information (such as their email address). This can result in third-party unsolicited messages for which Monkvyasa.com is not liable.Also, by following a connection from some other affiliate, you might have reached this website. If so, please be aware that Monkvyasa.com will share your data with that affiliate and instead of this one, the affiliate will use the information that is consistent with its privacy policy.</p>
<p> Monkvyasa.com can also disclose specific user information when we decide that such disclosure is appropriate to comply with the law, to cooperate with or request assistance from law enforcement or to protect the rights or safety of Monkvyasa.com or other users of the Site. Moreover, in the case of a transition of ownership or properties or a bankruptcy of Monkvyasa.com, personal details that we have acquired may be passed on to a third party.
</p>

<b>Credit card security</b>
<p>For safe credit card purchases, Monkvyasa.com employs encryption. We use 128-bit SSL encryption at Monkvyasa.com, the highest level of SSL encryption available to consumers at present. This guarantees the confidentiality and security of your purchases with a credit card.Basically, SSL ('Secure Sockets Layer') means that our server and your browser produce and agree on an encryption key that will only be used for that specific session. If created, all contact between our server and your browser is encrypted by this key.</p>

<b>Advertising</b>
<p>When you visit our Web site, we use third-party advertising companies to display ads. In order to provide advertising on this site and other sites about products and services of interest to you, these companies can use information about you and your visits to this and other websites.</p>

<b>What you should know.</b>
<p>
Monkvyasa.com cannot guarantee that, in ways not otherwise defined in this Privacy Policy, any of your private messages and other sensitive details will never be released. Therefore, while we are committed to protecting your privacy, we do not guarantee that your personal information or private messages will always stay private, and you should not expect that.Monkvyasa.com cannot guarantee that, in ways not otherwise defined in this Privacy Policy, any of your private messages and other sensitive details will never be released. Therefore, while we are committed to protecting your privacy, we do not guarantee that your personal information or private messages will always stay private, and you should not expect that.
</p>

<p>It's still an optional activity for you to log into Monkvyasa.com (although logging in does give you access to smarter astrology search tools such as my account, Kundali matcher, my horoscope , Vedic luck)
</p>`

function Privacy() {
  return (
    <>
      <Header title="Privacy Policy" />
      <DisplayData data={DATA} />
    </>
  )
}

export default Privacy
