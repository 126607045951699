import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"

import { getUploadImge } from "../../../../utils/functions"
const MARK_AS_READ_API = `chat/mark_as_read`

class ChatRow extends Component {
  renderAvatar = () => (
    <ListItemAvatar>
      <Avatar src={getUploadImge(this.props.user.image, "user/avatar")} />
    </ListItemAvatar>
  )

  renderContent = () => {
    let { user, msg, timeAgo, isUser, classes } = this.props
    return (
      <React.Fragment>
        {`" — ${msg}"`}
        <Typography
          component="span"
          className={classes.inline}
          color="textPrimary"
        >
          {`${timeAgo} minutes ago`}
        </Typography>
      </React.Fragment>
    )
  }

  render() {
    let { user, row, isUser, classes } = this.props
    let user_name = isUser
      ? `You`
      : `${user.first_name} ${user.last_name || ""}`
    return (
      <List className={classes.root}>
        <ListItem
          alignItems="flex-start"
          className={classes[isUser ? "ownChat" : "receiverChat"]}
        >
          {!isUser && this.renderAvatar()}
          <ListItemText secondary={this.renderContent()} primary={user_name} />
          {isUser && this.renderAvatar()}
        </ListItem>
      </List>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  ownChat: {
    //textAlign : 'right'
  },
})

const mapStateToProps = ({}, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ChatRow))
