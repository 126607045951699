import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import { FormButton, FormResult } from "../../../utils/form"
import { instance, convertMysqlDateTime } from "../../../utils/functions"
import { pushMsg } from "../../../navigation/actions"
const API = `requirement/complete`
class ReportCompleteAction extends Component {
  state = {
    isLoading: false,
    result: null,
  }

  handleClick = () => {
    this.setState({ isLoading: true })
    let { requirement_id, dispatch, onSuccess } = this.props
    instance.post(API, { requirement_id }).then((result) => {
      let { res, msg } = result
      if (res !== 1) {
        this.setState({ isLoading: false, result })
        return
      }
      dispatch(pushMsg(msg, "success"))
      onSuccess && onSuccess()
    })
  }

  render() {
    let { isLoading, result } = this.state
    let { requirement } = this.props
    return (
      <Card>
        <CardContent style={{ textAlign: "center" }}>
          <Typography variant="caption" color="secondary" align="center">
            If you received enough response from astrologer, please complete
            this booking here
          </Typography>
          <FormButton
            loading={isLoading}
            label="Complete booking"
            variant="outlined"
            color="primary"
            onClick={this.handleClick}
          />
          <FormResult result={result} />
          {requirement.requirement_ping_date != null && (
            <Typography variant="caption" color="textSecondary">
              This interaction will be automatically marked as completed in case
              of inactivity before
              {` `}
              {convertMysqlDateTime(requirement.requirement_ping_date, true)}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(ReportCompleteAction)
)
