import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import PhoneIcon from "@material-ui/icons/Phone"

import { instance } from "../../../utils"
import { Progress } from "../../../utils/components"
import { pushMsg } from "../../../navigation/actions"

const API = `phonecall/phonecall_public/validate_phone`

class PhoneMissedCall extends Component {
  state = {
    isLoading: false,
    whitelisted: false,
    checkCount: 0,
    maxCount: 2,
    autoDialing: true,
  }

  componentWillMount = () => this.validatePhone()

  validatePhone = () => {
    let { country_code, phone, dispatch, onSuccess } = this.props
    let { checkCount, maxCount, autoDialing } = this.state
    this.setState({ isLoading: true })
    instance.post(API, { country_code, phone }).then((result) => {
      let { res, msg, whitelisted, phone_valid } = result
      onSuccess && onSuccess(whitelisted && phone_valid)
      this.setState(
        {
          whitelisted,
          phone_valid,
          checkCount: checkCount + 1,
          autoDialing: checkCount < maxCount,
          isLoading: false,
        },
        () => {
          if (autoDialing) {
            setTimeout(this.validatePhone, 2000)
          } else dispatch(pushMsg(msg))
        }
      )
    })
  }

  render() {
    let { constants } = this.props
    let { whitelisted, isLoading, autoDialing } = this.state
    if (whitelisted)
      return (
        <Typography align="center" style={{ color: "green" }}>
          Verified phone number
        </Typography>
      )
    return (
      <React.Fragment>
        {isLoading && <Progress />}
        <Typography style={{ color: "red" }}>
          This phone number is not Verified. Please give a missed call to
        </Typography>
        <Button
          color="secondary"
          variant="text"
          onClick={() => window.open(`tel:${constants.WHITELIST_CALLER_ID}`)}
        >
          <PhoneIcon fontSize="small" style={{ marginRight: 10 }} />
          {constants.WHITELIST_CALLER_ID}
        </Button>
        <Typography variant="caption">
          This is one time process will not be asked again for the verified
          numbers
        </Typography>
        {!autoDialing && !isLoading && (
          <Button onClick={this.validatePhone}>
            Click after dialling the number given
          </Button>
        )}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  callerId: {
    fontSize: 22,
  },
})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  let constants = enums.rows.constants
  return { constants }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneMissedCall))
