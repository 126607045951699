import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"

import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"

const API = `dispute/update`
class DisputeForm extends Component {
  state = {
    isLoading: false,
    result: null,
    dispute_title: "",
    dispute_descritption: "",
  }

  handleSubmit = () => {
    let { service_id, dispute_ref_id, astrologer_id, onSuccess } = this.props
    let { dispute_descritption, dispute_title } = this.state
    this.setState({ isLoading: true, result: null })
    instance({
      method: "post",
      url: API,
      data: {
        service_id,
        dispute_ref_id,
        astrologer_id,
        dispute_descritption,
        dispute_title,
      },
    }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState({ result })
      else {
        onSuccess && onSuccess(result)
        this.props.dispatch(pushMsg(`Success !`, "success"))
      }
    })
  }

  renderForm = () => {
    let { classes, dispute_titles } = this.props
    let { dispute_descritption } = this.state
    return (
      <div>
        <Select
          native
          fullWidth
          value={this.state.dispute_title}
          onChange={(event) =>
            this.setState({ dispute_title: event.target.value })
          }
          inputProps={{
            name: "age",
          }}
        >
          <option value="">--SELECT TITLE--</option>
          {Object.keys(dispute_titles).map((key) => (
            <option value={key} key={key}>
              {dispute_titles[key]}
            </option>
          ))}
        </Select>
        <TextField
          label="Write your dispute reason here..."
          className={classes.textField}
          value={dispute_descritption}
          onChange={(event) =>
            this.setState({ dispute_descritption: event.target.value })
          }
          multiline
          rows="3"
        />
      </div>
    )
  }

  render() {
    let { comment_reference } = this.props
    let { isLoading, result } = this.state
    return (
      <div style={{ marginTop: 10 }}>
        {this.renderForm()}
        <FormResult result={result} />
        <FormButton
          loading={isLoading}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Raise dispute"
          onClick={this.handleSubmit}
        />
      </div>
    )
  }
}

const styles = (theme) => ({
  container: {
    padding: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
})

const mapStateToProps = ({ enums }, ownProps) => {
  let dispute_titles = enums.rows.dispute_titles.vt
  return { dispute_titles }
}

DisputeForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(withStyles(styles)(DisputeForm))
