import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import { instance, apiv2Instance } from "../../../utils"
import { createPhonecall } from "../actions"
import { FormButton, FormResult } from "../../../utils/form"
import { pushMsg } from "../../../navigation/actions"
import { getQueryStringValue, createUrl } from "../../../utils/functions"

import AstrologerLeftCard from "../../astrologer/components/leftCard"
import PhoneNumberConfirm from "./phoneNumberConfirm"
import PhonecallRechargeOptions from "./rechargeOptions"

const API = `phonecall/create`

class PhonecallCreatePage extends Component {
  state = {
    isLoading: false,
    result: null,
  }

  componentWillMount = () => {
    let { dispatch, astrologer_id } = this.props
    dispatch(createPhonecall(astrologer_id))
  }

  renderTalkTime = () => {
    let { user, currency, astrologer, talktime } = this.props
    return (
      <div style={{ margin: "25px 0", padding: 10, textAlign: "center" }}>
        <Typography color="primary" variant="subtitle1">
          A/c main balance : {user.wallet} {currency}
        </Typography>
        {talktime > 0 && (
          <Typography color="primary" variant="subtitle2">
            You can talk maximum of {talktime} minutes with astrologer{" "}
            {astrologer.first_name}
          </Typography>
        )}
        {talktime <= 0 && (
          <Typography color="error" variant="body1">
            Please recharge to talk with {astrologer.first_name}
          </Typography>
        )}
      </div>
    )
  }

  createCall = async () => {
    const { row, history } = this.props
    const { whitelisted } = row
    if (!whitelisted) {
      this.props.dispatch(pushMsg(`Please confirm Your phone number`, "info"))
      return
    }
    this.setState({ isLoading: true, result: null })
    const { data } = await apiv2Instance.get(
      `/astrologer/status?id=${this.props.astrologer_id}`
    )
    if (!data.isAvailable) {
      this.props.dispatch(
        pushMsg(
          "Astrologer not available at the moment, try other astrologers",
          "info"
        )
      )
      history.push("/astrologers")
      return
    }
    await apiv2Instance.put(`/astrologer/status`, {
      id: this.props.astrologer_id,
      isAvailable: false,
    })
    const result = await instance({
      url: API,
      data: row,
      method: "post",
    })
    this.setState({ isLoading: false, result })
    const { res, msg, phonecall_id } = result
    if (res === 1) {
      this.props.dispatch(pushMsg(msg, "success"))
      history.push(createUrl(`/phonecall/interaction`, { phonecall_id }))
    }
  }

  renderRight = () => {
    let { classes, astrologer_id, talktime } = this.props
    return (
      <Card style={{ marginTop: 5, marginBottom: 5 }}>
        <CardContent>
          <PhoneNumberConfirm />
          {this.renderTalkTime()}
          <PhonecallRechargeOptions astrologer_id={astrologer_id} />
        </CardContent>
      </Card>
    )
  }

  renderContent = () => {
    let {
      astrologer,
      currency,
      classes,
      astrologer_id,
      talktime,
      row,
    } = this.props
    let { result, isLoading } = this.state
    let { whitelisted } = row
    return (
      <Grid container spacing={16}>
        <Grid item md={3} xs={12}>
          <AstrologerLeftCard id={astrologer_id} />
        </Grid>
        <Grid item md={9} xs={12}>
          <div className={classes.callRate}>
            <Typography color="primary">
              Call rate : {astrologer.phone_service_rate} {currency}/minute
            </Typography>
          </div>
          {this.renderRight()}
          <FormResult result={result} />
          {talktime > 0 && whitelisted && (
            <FormButton
              label="Confirm Call"
              loading={isLoading}
              fullWidth
              color="secondary"
              className={classes.proceedButton}
              variant="contained"
              onClick={this.createCall}
              style={{ backgroundColor: "#37be5f" }}
            />
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    return <div>{this.renderContent()}</div>
  }
}

const styles = (theme) => ({
  callRate: {
    margin: "5px 0",
    padding: "20px 20px",
    background: "#f0e9f5",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  },
  proceedButton: {
    backgroundColor: "#37be5f",
  },
})

const mapStateToProps = (
  { auth, astrologers, enums, phonecall },
  { astrologer_id }
) => {
  let query_astrologer_id = getQueryStringValue("astrologer_id")
  if (query_astrologer_id) astrologer_id = query_astrologer_id
  let user = auth.user
  let astrologer = astrologers.rows[astrologer_id]
  let { constants } = enums.rows
  let currency = constants.CURRENCY
  let talktime =
    parseFloat(user.wallet) > 0
      ? Math.floor(
          parseFloat(user.wallet) / parseFloat(astrologer.phone_service_rate)
        )
      : 0
  let row = phonecall.row
  return { user, currency, talktime, astrologer, row, astrologer_id }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PhonecallCreatePage))
)
