import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { PersistGate } from "redux-persist/integration/react"

import theme from "./theme"
import AppRouter from "./routes"
import configureStore, { history } from "../store"

let { persistor } = configureStore()

const Root = ({ store }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <AppRouter />
        </ConnectedRouter>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export { history }

export default Root
