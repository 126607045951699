import { guid } from "../../../utils/functions"
let initialState = {
  rows: {},
  ids: [],
}

let id, rows

const msgReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PUSH_MSG":
      let { msg, type } = action.payload
      id = guid()
      return {
        ...state,
        rows: {
          ...state.rows,
          [id]: { id, msg, type },
        },
      }
    case "REMOVE_MSG":
      id = action.payload
      let rows = { ...state.rows }
      delete rows[id]
      return {
        ...state,
        rows,
      }

    default:
      return state
  }
}

export default msgReducer
