const my_report = {
  myreport_present: "Present",
  myreport_cause: "Cause",
  myreport_solution: "Solution",
  myreport_future: "Future",
}

const horoscope_matching = {
  horoscope_match_score: "Matching score",
  horoscope_sex: "Sex life",
  horoscope_health: "Health",
  horoscope_wealth: "Wealth",
  horoscope_career: "Career",
  horoscope_kids: "Kids",
  horoscope_happiness: "Happiness",
  horoscope_togetherness: "Togetherness",
  horoscope_life_span: "Life Span",
  horoscope_conclusion: "Conclusion",
}

const lifetime = {
  //lifetime_agefrom : "Age From",
  //lifetime_ageto : "Age To",
  lifetime_education: "Education",
  lifetime_family: "Family",
  lifetime_career: "Career",
  lifetime_health: "Health",
  lifetime_wealth: "Wealth",
  lifetime_love: "Love",
  lifetime_marriage: "Marriage",
}

const fields = {
  1: my_report,
  2: horoscope_matching,
  4: lifetime,
  5: my_report,
}

export default fields
