import React, { Component } from "react"
import Typography from "@material-ui/core/Typography"
import StarIcon from "@material-ui/icons/Star"

class FormResult extends Component {
  render() {
    let { result } = this.props
    if (!result || (!result.error && !result.msg)) return null
    return (
      <div style={{ padding: "10px 20px 0px" }}>
        {!result.error && <Typography color="error">{result.msg}</Typography>}
        {result.error &&
          Object.values(result.error).map((item, idx) => (
            <Typography key={idx} color="error">
              <StarIcon style={{ fontSize: 12, color: "#00000099" }} />
              {item}
            </Typography>
          ))}
      </div>
    )
  }
}

export default FormResult
