import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import GroupIcon from "@material-ui/icons/Group"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import PersonIcon from "@material-ui/icons/Person"

const menus = [
  {
    text: "Astrologers",
    icon: <GroupIcon />,
    url: "/astrologers",
  },
  {
    text: "Bookings",
    icon: <QuestionAnswerIcon />,
    url: "/report-booking-list",
  },
  {
    text: "Wallet",
    icon: <AccountBalanceWalletIcon />,
    url: "/wallet",
  },
  {
    text: "Profile",
    icon: <PersonIcon />,
    url: "/profile",
  },
]

class FooterMenu extends React.Component {
  render() {
    const { classes, history, isMobile, pathname } = this.props
    let excludePaths = ["/authentication", "/astrologer"]
    if (excludePaths.includes(`${pathname}`) || !isMobile) return null
    let value = null
    let tabs = []

    menus.map((x, key) => {
      if (x.url === pathname) value = key
      tabs.push(
        <BottomNavigationAction
          key={key}
          onClick={() => history.push(x.url)}
          label={x.text}
          icon={x.icon}
        />
      )
      return null
    })

    return (
      <React.Fragment>
        <br />
        <br />
        <br />
        <BottomNavigation value={value} showLabels className={classes.root}>
          {tabs}
        </BottomNavigation>
      </React.Fragment>
    )
  }
}

const styles = {
  root: {
    width: "100%",
    zIndex: 100,
    bottom: 0,
    position: "fixed",
    borderTop: " 1px solid #cfc1d8",
  },
  selected: {
    color: "red",
    background: "red",
  },
}

FooterMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ router }) => {
  const pathname = router.location.pathname
  const isMobile = window.innerWidth < 500
  return { isMobile, pathname }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(FooterMenu))
)
