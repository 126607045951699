import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import AssignmentIcon from "@material-ui/icons/Assignment"

import AstrologerLeftCard from "../../astrologer/components/leftCard"
import ReportSelectServices from "./selectServices"
import { pushMsg } from "../../../navigation/actions"
import ModalComponent from "../../../utils/components/modal"

class ReportBookingButton extends React.Component {
  state = {
    modalOpen: false, //this.props.id == 1238 //false,
  }

  _setState = (newState) => this.setState(newState)

  handleClickOpen = () => {
    let { first_name } = this.props.row
    let naMsg = `Sorry ${first_name} is not available now`
    let { isAvailbale, dispatch } = this.props
    if (!isAvailbale) {
      dispatch(pushMsg(naMsg))
      return
    }
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  renderContent = () => (
    <Grid container spacing={16}>
      <Grid item md={3} xs={12}>
        <AstrologerLeftCard id={this.props.id} />
      </Grid>
      <Grid item md={9} xs={12}>
        <ReportSelectServices id={this.props.id} />
      </Grid>
    </Grid>
  )

  renderModal = () => (
    <ModalComponent
      _setState={this._setState}
      modalOpen={this.state.modalOpen}
      title="Report Booking"
      content={() => this.renderContent()}
    />
  )

  render() {
    const { isAvailbale } = this.props
    return (
      <React.Fragment>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          className={this.props.classes.button}
          onClick={this.handleClickOpen}
          style={{ opacity: isAvailbale ? 1 : 0.5 }}
        >
          <AssignmentIcon className={this.props.classes.leftIcon} />
          Reports
        </Button>
        {this.renderModal()}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "hidden",
  },
  flex: {
    flex: 1,
  },
  leftIcon: {
    marginRight: 10,
    fontSize: 14,
  },
  button: {
    background: "linear-gradient(45deg, #ff9f00 30%, #ff9f00 90%)",
    borderRadius: 0,
    paddingRight: 10,
    width: "90%",
    margin: theme.spacing.unit,
  },
})

ReportBookingButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ astrologers }, { id }) => {
  let row = astrologers.rows[id]
  let { report_service_rate } = row
  let isAvailbale = parseInt(report_service_rate) > 0
  return { isAvailbale, row }
}
export default connect(mapStateToProps)(withStyles(styles)(ReportBookingButton))
