import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"

import { createUrl, getQueryStringValue } from "../../../utils/functions"
import { FormButton, FormResult } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"
import { saveReqRowDB } from "../actions"
const PAYMENT_API = `requirement/requirement_payment/proceed`

class RequirementPayment extends Component {
  state = {
    isLoading: false,
    result: null,
  }

  componentWillMount = () => {
    let {
      isLoggedIn,
      dispatch,
      from_payment,
      requirement_list,
      reportIsSaving,
    } = this.props
    if (from_payment) this.handlePayment()
    if (!isLoggedIn || reportIsSaving) return
    requirement_list.map((x) => dispatch(saveReqRowDB(x)))
  }

  handleProceed = () => {
    let { requirement_group_ref, history, isLoggedIn } = this.props
    let redirect = createUrl("/requirement/create", {
      requirement_group_ref,
      requirement_added: 1,
    })
    let auth_red = `/authentication`
    if (!isLoggedIn) history.push(createUrl(auth_red, { redirect }))
    else this.handlePayment()
  }

  handlePayment = () => {
    let { reportIsLoading, dispatch } = this.props
    if (reportIsLoading) {
      dispatch(pushMsg("Report is saving", "warning"))
      return
    }

    this.setState({ isLoading: true, result: null })
    let { requirement_group_ref, history } = this.props
    instance({
      url: PAYMENT_API,
      data: { requirement_group_ref },
      method: "post",
    }).then((result) => {
      this.setState({ isLoading: false, result })
      let { res, msg, amount } = result
      this.props.dispatch(pushMsg(msg))
      if (res == 1) {
        if (amount) {
          let redirect = createUrl("/requirement/create", {
            requirement_group_ref,
            requirement_added: 1,
            from_payment: 1,
          })
          history.push(createUrl(`/payment`, { amount, redirect }))
        } else this.handleSuccessPayment()
      }
    })
  }

  handleSuccessPayment = () => {
    let { history, dispatch } = this.props
    dispatch(pushMsg("Success", "success"))
    history.push("/report-booking-list")
  }

  renderButton = () => {
    let { reportIsLoading, isLoggedIn } = this.props
    if (reportIsLoading && isLoggedIn) return null
    return (
      <FormButton
        label="Confirm Booking"
        loading={this.props.isLoading || this.state.isLoading}
        fullWidth
        className={this.props.classes.proceedButton}
        variant="contained"
        color="secondary"
        onClick={this.handleProceed}
        style={{ backgroundColor: "#37be5f" }}
      />
    )
  }

  renderDetails = () => {
    let {
      constants,
      wallet,
      amount_to_pay,
      total,
      classes,
      requirement_list,
    } = this.props
    const currency = constants.CURRENCY
    return (
      <Card className={classes.content}>
        <Grid container spacing={16}>
          <Grid item md={8} xs={8}>
            Bookings
          </Grid>
          <Grid item md={4} xs={4}>
            {" "}
            : {requirement_list.length} Nos.{" "}
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item md={8} xs={8}>
            Amount
          </Grid>
          <Grid item md={4} xs={4}>
            {" "}
            : {total} {currency}{" "}
          </Grid>
        </Grid>
        {wallet > 0 && (
          <Grid container spacing={16}>
            <Grid item md={8} xs={8}>
              Wallet balance
            </Grid>
            <Grid item md={4} xs={4}>
              {" "}
              : {wallet} {currency}{" "}
            </Grid>
          </Grid>
        )}
        {wallet > 0 && (
          <Grid container spacing={16} style={{ color: "blue", padding: 20 }}>
            <Grid item md={12} xs={12}>
              {" "}
              Pay amount {amount_to_pay} {currency} to confirm booking{" "}
            </Grid>
          </Grid>
        )}
      </Card>
    )
  }

  render() {
    return (
      <div className="grid-warpper">
        {this.renderDetails()}
        <FormResult result={this.state.result} />
        {this.renderButton()}
      </div>
    )
  }
}

const styles = (theme) => ({
  content: {
    padding: theme.spacing.unit * 2,
  },
  proceedButton: {
    marginTop: 10,
    color: "#fff",
    //backgroundColor : '#37be5f'
  },
})

const mapStateToProps = ({ auth, enums }, ownProps) => {
  let { isLoggedIn, getBooking, requirement_list } = ownProps
  let constants = enums.rows.constants
  let from_payment = getQueryStringValue("from_payment")
  let total = 0
  requirement_list.map((row) => {
    let { service_rate } = getBooking(row)
    total += parseFloat(service_rate)
  })

  let wallet = isLoggedIn ? parseFloat(auth.user.wallet) : 0
  let amount_to_pay = total - wallet
  return { from_payment, total, amount_to_pay, constants }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(RequirementPayment))
)
