import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"

import { instance } from "../../../utils"
import { FormResult } from "../../../utils/form"

const API = `phonecall/get_row`
const TRACKER_API = `phonecall/phonecall_tracking/status`

class PhonecallUpdator extends Component {
  state = {
    call_sid: this.props.call_sid,
    row: this.props.row || null,
    attemptCount: 0,
    track_count_max: this.props.track_count_max || 10,
    isLoading: false,
    result: null,
  }

  componentWillMount = () => {
    if (!this.props.row) this.fetchRow()
    else this.updateRow()
  }

  isPending = () => {
    let { row } = this.state
    let { phone_call_status } = this.props
    let call_status = parseInt(row.call_status)
    return (
      call_status > phone_call_status.PENDING &&
      call_status <= phone_call_status.COMPLETED &&
      row.call_duration <= 0
    )
  }

  fetchRow = () => {
    let { call_sid, onComplete } = this.props
    instance({
      method: "post",
      url: API,
      data: { filter: { call_sid } },
    }).then((result) => {
      let { row, res } = result
      this.setState({ row })
      if (row == null || res != 1) {
        this.setState({ result })
      } else {
        if (parseInt(row.call_status) > 4)
          onComplete && setTimeout(onComplete, 2000)
        this.setState({ row }, this.updateRow)
      }
    })
  }

  updateRow = () => {
    let { updateRow } = this.props
    if (this.isPending()) setTimeout(this.trackCall, 1000)
    updateRow && updateRow(this.state.row)
  }

  trackCall = () => {
    let { track_count, track_count_max_max, call_sid, row } = this.state
    track_count++
    if (track_count >= track_count_max_max) return
    this.setState({ track_count, isLoading: true })
    instance({
      method: "post",
      url: TRACKER_API,
      data: { call_sid },
    }).then((result) => {
      this.setState({ isLoading: false })
      let { res, call_duration, call_status } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      row = Object.assign({}, row, { call_duration, call_status })
      this.setState({ row }, this.updateRow)
    })
  }

  renderContent = () => {
    let { isLoading, result } = this.state
    if (isLoading) return <LinearProgress />
    return <FormResult result={result} />
  }

  render() {
    let { visualise } = this.props
    if (!visualise) return null
    return this.renderContent()
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  let phone_call_status = enums.rows.phone_call_status.kv
  return { phone_call_status }
}
export default connect(mapStateToProps)(withStyles(styles)(PhonecallUpdator))
