import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import FooterMenu from "./menu"
class DefaultComponent extends Component {
  render() {
    return <FooterMenu />
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(DefaultComponent))
