import { applyMiddleware } from "redux"
import { routerMiddleware } from "connected-react-router"
import promise from "redux-promise-middleware"
import thunk from "redux-thunk"
import REDUX_PROMISE_AXIOS from "redux-promise-axios"

import { history } from "./"

export default applyMiddleware(
  routerMiddleware(history),
  thunk,
  promise(),
  REDUX_PROMISE_AXIOS
)
