import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Header from "./shared/Header"

const DATA = {
  user: [
    {
      q: "How do I sign up with Monkvyasa?",
      a:
        "You can Sign up with your mobile number and enter the One Time Password received in your mobile number to confirm. ",
    },
    {
      q: "Should I add a mobile number to sign up?",
      a:
        "Yes, you can sign up with an email address, add the mobile number in the sign up form. If you sign up through Facebook, can add a mobile number in user account after the registration.",
    },
    {
      q:
        "Is it possible to change my registered email ID and mobile number after registration?",
      a:
        "Yes. you can change your registered email address and a mobile number, can see the option in your MV account.",
    },
    {
      q: "How can I talk to astrologer through Monkvyasa?",
      a:
        "It is same as a normal incoming call, the astrologer\u2019s call will come to your registered mobile number, the call charges is per minute. First, you have to sign up/sign in with Monkvyasa, then recharge your MV wallet with a sufficient amount. To get the call from the astrologer, click the call button in astrologer profile, then you will get a call from the astrologer in your registered phone number, then talk to the astrologer.",
    },
    {
      q: "How do I verify my mobile number with Monkvyasa?",
      a:
        "Do a missed call to 08039512777 from registered number to verify your number with Monkvyasa.",
    },
    {
      q:
        " Is it possible to do phone consultation without verifying the phone number?",
      a:
        "No. It is not possible, you have to verify your number for the phone consultation.",
    },
    {
      q: "How does Monkvyasa verifying the astrologers?\t",
      a:
        "We have standard verification procedure to choose the astrologers to the panel. Astrologer should go through both personal and professional verification. For the person verification, the astrologer needs to submit the personal ID proof. For the professional verification, need to submit any of the documents like astrology certificate, ID card or any other documents related to their astrology profession. After completed the registration, the astrologer should pass the knowledge test in astrology to part of Monkvyasa astrology panel.",
    },
    {
      q:
        "I have recharged my MV wallet with Rs.1000 for 1-hour phone consultation and I spoke 30 minutes only, what happened to my remaining amount?",
      a:
        "The phone consultation charge is per minute, when you start the conversation the charge is deducted from your MV wallet per minute, you got charged by talk time. If you do not use the full amount, the remaining amount has kept in your MV wallet, can use it whenever you need. ",
    },
    {
      q:
        "What should I do, If call disconnect accidently during the conversation?",
      a:
        "Do not worry, you can recall the same astrologer instantly and continue the conversation. If your wallet has not sufficient balance, recharge the wallet and continue the conversation.",
    },
    {
      q:
        "When I am trying to call the astrologer, then showing \u201c the call is connecting\u201d but I did not receive any calls from the astrologer. What should I do?",
      a:
        "This situation may happen due the astrologer is in another call or astrologer do not able to pick the call. So you can try after sometimes or can call any other astrologer in the panel.",
    },
    {
      q:
        "When I trying call the astrologer, the call duration in the screen is already counting but I have not received the call, how do you calculate my total call duration?",
      a:
        "Your call charge will be calculated by the call duration in your phone, you can check your phone for actual call duration, call duration showing in the screen is symbolic number. ",
    },
    {
      q:
        "  How do I ensure the availability of the astrologer before doing the call?",
      a:
        "Astrologers are given their available time in their profile. In some cases, astrologers may do not take the call in available time because of the other calls or other personal reasons. You can try after sometimes to call the other astrologers in the panel. ",
    },
    {
      q: "How do I recharge my MV wallet?",
      a:
        "You can recharge your MV wallet by using Debit card, Credit card, Netbanking and wallets like Paytm.",
    },
    {
      q: "What is the minimum amount requirement to recharge my MV wallet?",
      a: "There is no minimum amount requirement to recharge your MV wallet .",
    },
    {
      q:
        " My wallet account is in a negative balance, how should I clear the negative balance?",
      a:
        "There is a minimum chance to your MV wallet amount fall into negative balance during the phone consultation. Sometimes the your conversation with astrologer may continue even after your wallet has zero balance and you got the extra talk time than your wallet balance, the extra time will be added as negative. You can recharge the wallet and use service again. ",
    },
    {
      q: " Can I use my wallet for other payment apart from Monkvyasa?",
      a: "No. You can use MV wallet money only for Monkvyasa services",
    },
    {
      q: "Does Monkvyasa providing international service?",
      a:
        " Yes. We are proving international service for both phone consultation and report service.",
    },
    {
      q: "Which are the countries you are offering international service?",
      a: "We are providing service in most of the countries in the world ",
    },
    {
      q: "How do I make the payment from outside India?",
      a:
        "You can use your PayPal account for making payment from outside India.",
    },
  ],
  astrologer: [
    {
      q: "I am an astrologer, how do I register with Monkvyasa?",
      a:
        "Have you excellent knowledge in astrology? Then you can be part of Monkvyasa astrologer family by going through standard verification procedure. You can do the basic registration by using the Facebook account or email address. After the basic registration, you have to fill your professional details like biography, years of experience, how you learned astrology etc. For professional verification you can submit your certificate, ID card of any astrologer association and other documents related to astrology, link/file of astrology article published by you in print/online media, your print and visual media coverage as an astrologer etc. for person verification you can submit your Aadhar card, passport, driving licence. ",
    },
    {
      q:
        "Should I pass the knowledge test in astrology to part of Monkvyasa astrology panel?",
      a:
        "Yes. after completed the registration process, will send a test booking with birth details of a person and you should give the prediction on it. Then verification team will go through your prediction and check the accuracy of the prediction if the accuracy of the prediction is more than 75%, verification team will activate the account.",
    },
    {
      q:
        "If I am not passed the knowledge test, can I apply for another chance immediately? ",
      a:
        "No. if you have not passed the knowledge test, you can apply again after 6 months.",
    },
    {
      q:
        "How do I submit the supporting documents for the registration process?",
      a:
        "You can upload the supporting documents through your MV account or can send the documents through Whatsapp.",
    },
    {
      q: "Which are the information of astrologers visible to customers?",
      a:
        "The astrologer\u2019s name, picture, place, biography, services, consultation fee are visible to the public, all the other information you gave during registration are for verification purpose only. ",
    },
    {
      q: "How do I receive my earnings in Monkvyasa?",
      a:
        "You have to submit your bank account details with cancelled check in your MV account. You will receive the payment in either 1st week of the month or last week of the month.",
    },
    {
      q: "What is the minimum earnings amount to receive the payment?",
      a:
        "The minimum payable amount is Rs.5000, if your amount did not reach the Rs.5000 in three months, then will transfer whatever amount you earned.",
    },
    {
      q: "How much is the Monkvyasa part in each transaction?",
      a:
        "Monkvyasa part is 25% for each transaction, there are no other hidden charges.",
    },
    {
      q:
        "Does Monkvyasa take any service charges and transaction charges from astrologer earnings?",
      a:
        "No. we do not take any service charges and transaction charges from astrologer earnings.",
    },
    {
      q: " Is there any registration charges for astrologers?",
      a:
        "No. the astrologer registration absolutely free and we always keep the same.",
    },
    {
      q:
        "Should I pay any subscription/rental charges for my astrologer profile in Monkvyasa? ",
      a:
        "No. there is no subscription/rental for your Monkvyasa account, it\u2019s absolutely free and we always keep the same.",
    },
    {
      q: "Can I delete my astrologer profile in Monkvyasa, if required?",
      a:
        "Yes. you can officially inform our team to delete your account if required, MV team will help you to do that.",
    },
    {
      q: "How do I share Monkvyasa profile in my network? ",
      a:
        "You can share your Monkvyasa profile through social medias and networking platforms. Can find a sharing button in your profile for this purpose. ",
    },
    {
      q:
        "I got a phone consultation during the available time mentioned in profile, but I am not available to speak, what should I do? ",
      a:
        " In such cases you have two options, you can leave the call or attend the call and tell the matter to customer in less than one minute and can fix a mutually agreed time to call again. The conversation less than one minute would not be charged. ",
    },
    {
      q: "Does Monkvyasa have privilege to deactivate my account?",
      a:
        "We would not like to deactivate any astrologer account. But in the case malpractice, misbehaving with customers, unauthentic profile and services, we have taken necessary action to prevent it and may cause to deactivation of the account. ",
    },
    {
      q: " How do I prepare report booking service?",
      a:
        "In report booking service you have birth details of the customer along with queries to prepare the horoscope report. If you need more details to prepare the report, can ask customer in the comment section. The sample report is demonstrated in all section of report services, please check it for the reference. ",
    },
    {
      q: "How do I prepare the horoscope matching report?",
      a:
        "In horoscope matching, the astrologer should give the matching score out of ten of the two horoscopes. The astrologer should give the report analyse of a different aspect of the life after marriage, so they can understand the pros and cons if the two matched person gets married. The horoscope matching report must contain the report of life after marriage in different section, such as sex life, health condition, wealth condition, career life, about kids, happiness, togetherness and life span. The sample report is demonstrated in all section of report services, please check it for the reference. ",
    },
    {
      q: "How do I prepare the one year report?",
      a:
        "In one year report, the astrologer should prepare the report of each month separately for one year, the report should contain the 12 months horoscope report in detail. The sample report is demonstrated in all section of report services, please check it for the reference. ",
    },
    {
      q: "How do I prepare the lifetime report?",
      a:
        "The lifetime report means the prediction about a person from present life to death with detail report for different aspects of life in different age span. The report should contain the person\u2019s health, education, career, family, wealth, love and marriage in the different span of life. The sample report is demonstrated in all section of report services, please check it for the reference. ",
    },
    {
      q: "How much time can I get to give the report service?",
      a:
        "The astrologer should giving the report in the time between 12-48 hours, the delay in give the report may cause the cancellation booking and refund to the customer. In the case of one-year and lifetime report, you can take extra time.",
    },
    {
      q: "What I do if the customer had raised a dispute on consultation?",
      a:
        "In the case of dispute, you can politely explain the situation to the customer in the comment box in the dispute section, if you are willing, provide a refund to the customer for a long term relationship. We always suggest that talk patiently and politely with customer, if they said not satisfied with the consultation, try to solve their concern with a detailed solution or offer the refund.",
    },
    {
      q: "How do you deduct the refund amount?",
      a:
        "If the customer raised the dispute regarding the issue with consultation, the refund amount will deduct from astrologer earnings. If the customer raised the dispute regarding other problems like technical issue, network issue, the refund amount will be given by Monkvyasa.",
    },
    {
      q: "How do I offer my service internationally?",
      a:
        "You do not have to do anything extra for offering your service internationally. If you are in Monkvyasa astrologer panel you can offer service internationally. ",
    },
    {
      q: "How do I set my fee for international service?",
      a:
        "For international service, your normal fee will be doubled and showing in USD. You do not have to set a separate fee for international service. ",
    },
    {
      q: "How much success fee Monkvyasa retain in international service?",
      a: "In international service, Monkvyasa retain 35% as success fee",
    },
    {
      q:
        "From which number I will get calls for the International phone consultation? ",
      a:
        "You will get a call from +1 (747) 888 6999 for international phone consultation  ",
    },
  ],
  refund: [
    {
      q: "In which case I can apply for Monkvyasa refund?",
      a:
        "If you have not received the consultation properly from the astrologer, you can apply for a refund. Both phone call consultation and Report booking services are applicable for refund.",
    },
    {
      q:
        "If I am not satisfied with the astrologer service, should I apply for the refund?",
      a:
        "Yes, you can apply for a refund by raising the dispute and asking for the refund to the astrologer. If the astrologer is not willing to do the refund, MV team will analyse the issue, if the situation is a favour to the customer, will give the refund.",
    },
    {
      q: "How much time will take to credit the refund amount in MV wallet?",
      a:
        "The refund amount will be credited instantly after initiating the procedure.",
    },
    {
      q:
        "In which time long I can apply for the refund after the consultation?",
      a:
        "In phone consultation, can apply for the refund within 24 hours. In report booking service, can apply for the refund within 24 hours.",
    },
    {
      q: "Is the refund money credited to my bank account or MV wallet?",
      a: "The refund amount will be credited in your MV account instantly ",
    },
  ],
  report_booking: [
    {
      q: "What is Report booking service?",
      a:
        "In Report booking consultation, you can get your horoscope report in detailed format. The basic report booking service contains four sections, present, cause, solution and future. The astrologer will give you the report analysis of the present situation, cause of the situation, solution for the situation and future analyse. If you do not get the enough explanation in first the report, you can continue the conversation with the astrologer in the comment section until you got the enough reply. Please see the sample report demonstrated in website for reference.",
    },
    {
      q:
        "What details do I need to give the astrologer for report service booking?",
      a:
        "Your birth details are required for the astrologer to prepare your Report. The birth details should contain a name, gender, time of birth and place of birth.",
    },
    {
      q: "How does horoscope report service work?",
      a:
        "In horoscope report service, the astrologer will cross check the horoscope of boy & girl, give you matching score out 10. Also, get the detail report of the future life of the partners if they live together. The report contains partner\u2019s different aspect of life after marriage, such as sex life, health condition, wealth condition, career life, about kids, happiness, togetherness and life span.  If you do not get the enough explanation in the first report, you can continue the conversation with the astrologer in the comment section until you got the enough reply.Please see the sample report demonstrated in website for reference",
    },
    {
      q: "How does one-year report service work? ",
      a:
        "In one-year report booking service, you will get the horoscope report for the next one year from the date of booking, it contains each month report for 12 months. Example: If you book in November, you will get the report from December in the current year to November in next year.  If you do not get the enough explanation in the first report, you can continue the conversation with the astrologer in the comment section until you got the enough reply.Please see the sample report demonstrated in website for reference",
    },
    {
      q: " How does life report service work?",
      a:
        "In lifetime report, you will get a complete prediction report from present life to death. It includes the different span of life, it will give a clear picture of your entire life. It is the complete story of your life. \n\t\tExample: if you are 25 years old, your lifetime report include your different age span, like ages 25-35, ages 35-45, ages 45-55 ages, ages 55-65.  If you do not get the enough explanation in the first report, you can continue the conversation with the astrologer in the comment section until you got the enough reply.Please see the sample report demonstrated  in website for reference  ",
    },
    {
      q: "What should I do, if the report has not enough explanation?",
      a:
        "In such case, you can tell about this with the astrologer in the comment section, the astrologer will reply in the comment section and you can continue the conversation until you got enough reply. ",
    },
    {
      q: "How does Monkvyasa verifying the astrologers?",
      a:
        "We have standard verification procedure to choose the astrologers to the panel. Astrologer should go through both personal and professional verification. For the person verification, the astrologer needs to submit the personal ID proof. For the professional verification, need to submit any of the documents like astrology certificate, ID card or any other documents related to their astrology profession. After completed the registration, the astrologer should pass the knowledge test in astrology to part of Monkvyasa astrology panel.",
    },
    {
      q:
        "Can I do the review and rating for the astrologer after completed the consultation?",
      a:
        "Yes. you can give the rating and write the review for the astrologer after the consultation. It will appear in astrologer\u2019s profile.",
    },
    {
      q: "How do I choose the astrologer for consultation?",
      a:
        "You can go through astrologer\u2019s profile and analyse their rating, review, biography, available time, consultation fee etc, then you can select the astrologer depends on your preferences.",
    },
    {
      q: "How much time will astrologer take to prepare the report?",
      a:
        "The astrologer takes 12-48 hours to prepare the horoscope report, in some cases it may take more than usual time because of astrologer\u2019s busy schedule or in few case astrologer will take extra time to analyse the horoscope, depends on the enquiry. In the case of a one-year report and lifetime report astrologer will take extra time to prepare the report, because its needs detail analyse. ",
    },
  ],
}

function FAQ(props) {
  const { classes } = props

  const renderQa = (x) => (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.queation}>{x.q}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{x.a}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )

  return (
    <div>
      <Header title="FAQs" />
      {DATA.user.map((x) => renderQa(x))}
      <Typography
        color="secondary"
        variant="subtitle1"
        className={classes.title}
      >
        Report Booking Consultation
      </Typography>
      {DATA.report_booking.map((x) => renderQa(x))}
      <Typography
        color="secondary"
        variant="subtitle1"
        className={classes.title}
      >
        Refund Policy
      </Typography>
      {DATA.refund.map((x) => renderQa(x))}
    </div>
  )
}

const styles = (theme) => ({
  queation: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    margin: 20,
  },
})

export default withStyles(styles)(FAQ)
