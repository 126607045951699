import React from "react"
import Header from "./shared/Header"
import DisplayData from "./shared/DisplayData"

const DATA = `<h5 class="strong text-uppercase">TERMS OF USE</h5>
<p class="strong">This is a legal agreement between you, the potential user of this website ("you") and Astrolytic Private Limited ("Us" "Monkvyasa.com") the owner and controller of www.monkvyasa.com. Your use of Monkvyasa.com products, services and web sites or any of the products and services offered on this site (collectively, the "Services") is conditioned on your acceptance without modification of these Terms of Service ("Terms").</p>
<p class="strong"> Whenever you avail any of the services rendered by MONKVYASA.COM by clicking I AGREE button it is implied that you agree with the stated TERMS OF SERVICE. Please note that these terms of services may be amended by MONKVYASA.COM at anytime based on their discretion and/ or keeping in tune with the changing scenarios and technology.</p>
<h5 class="mt20 text-secondary">DESCRIPTION</h5>
<p>MONKVYASA.COM is a marketplace website. The astrologers on the website are not employees of MONKVYASA.COM. The services offered by the astrologers are their own and MONKVYASA.COM is not responsible for any problem that may arise between customers and the astrologers. The customers have to verify the credentials of the advisor and take a decision. MONKVYASA.COM only offers you a platform for interaction with the astrologers. MONKVYASA.COM provides two types of services. </p>
<p>By telephone customer interacts with astrologer through telephone
   Direct service where the customer consults and transacts through the profile of the astrologer selected by the customer. 
</p>
<p>The report of the consultation will be provided by the astrologer as payment method mentioned in clause 11.</p>
<h5 class="mt20 text-secondary">NATURE OF CONTRACT</h5>
<p>The persons seeking services from MONKVYASA is termed as customers. The nature of relationship between customer and MONKVYASA is a contract. By clicking the agree button your unqualified acceptance of complete terms and conditions are implied. You fully declare and undertake with sound mind and without any undue force, pressure, influence or coercion, that you shall be fully bound by the terms of the website.
   The company reserves the right to terminate or suspend the account of the astrologer at any time without notice.
</p>
<h5 class="mt20 text-secondary">ELIGIBILITY.</h5>
<p>The services provided by the website can be availed only by the individuals who are capable of entering into contracts under the Indian Contract Act 1872. Without prejudice to the above, the services are not permitted to be used by minors or temporarily or indefinitely suspended members of MONKVYASA.COM</p>
<h5 class="mt20 text-secondary">TERM AND TERMINATION</h5>
<p>This Contract will remain in full force and effect while the website is used either as a guest or a member. You may terminate the membership at any time for any reason after intimation. In the event of termination of membership you will not be entitled to refund of any unutilized subscription fee or access to digital contents or reports purchased through MONKVYASA.COM. The website may terminate access and/ or your membership to the website which shall be effective upon sending notice of termination to your email address provided at the time of registration or later provided to MONKVYASA.COM. Upon termination of your membership on the ground of breach of contract, you will not be entitled to refund of any unutilized subscription fee or access to digital contents or reports purchased through MONKVYASA.COM.</p>
<h5 class="mt20 text-secondary">CONFIDENTIALITY</h5>
<p>The personal details and associated data of the customers will be safe and secure. Since the payments are made through a secured payment gateway, the transactions are secured. The customer astrologer interactions are end to end encrypted.</p>
<h5 class="mt20 text-secondary">INCLUSIVE OF THIS AGREEMENT</h5>
<p>In order to avail services the user has to register himself with MONKVYASA.COM and it  reserves all rights to change the service, delete features, cancel or suspend your service at any time and for any reason without notice. In case of service cancellation or suspension, right to use the services ceases immediately and any data you may have stored on the website may not be retrieved later. If the service is cancelled in its entirety without cause you will be eligible for a refund on a pro-rata basis.  </p>
<p>MONKYAVASA.COM has the right to alter the commission payable by the astrologer at any time.</p>
<h5 class="mt20 text-secondary">DISPUTES</h5>
<p>If there is any dispute arises, about or involving the website and/ or the service, by using the services you further agree that the disputed matter will be governed by concerned Indian laws. Any claim in relation to this contract or in connection therewith shall be filed with the opposite party within 24 months from the date on which the issue arose.</p>
<h5 class="mt20 text-secondary">ASSIGNMENT</h5>
<p>MONKVYASA.COM may assign this contract to anybody with or without notice to you. You cannot assign this contract to anybody without getting prior approval or consent from MONKVYASA.COM. Any such transfer or assignment by you without prior approval shall be void.</p>
<h5 class="mt20 text-secondary">INDEMNITY</h5>
<p>You agree to Indemnify, defend and hold harmless MONKVYASA.COM and its parent, subsidiaries, affiliates, officers, directors, employees, suppliers, consultants and agents from any and all third party claims, liability, damages and/ or costs (including, but not limited to attorney fees) arising from your use of the services in violation of this contract and / or any breach of your representations and warranties set forth above.</p>
<h5 class="mt20 text-secondary">PROPRIETARY RIGHTS TO THE CONTENT</h5>
<p>You acknowledge that the content, including but not limited to text, software, music, sound, photographs, video, graphics or other material contained in either sponsor advertisement or sponsor advertisement distributed via e-mail, commercially produced information presented to the member by MONKVYASA.COM, its suppliers, and/ or advertisers, is protected by copyrights, trademarks, service marks, patents and/ or other proprietary rights and laws. You  are not permitted to copy, use, reproduce, distribute, perform, display or create derivative works from the content unless expressly authorized by MONKVYASA.COM, its suppliers or advertisers.</p>
<h5 class="mt20 text-secondary">REFUND AND CANCELLATION</h5>
<p>Customers are requested to exercise due care and discretion while ordering reports, because once the report has been allocated to an astrologer for processing, no refund request will be entertained. However, if you wish to cancel an order for a report, you can do so within one hour of placing the order, in which case you have to E-mail our customer care team at hello@monkvyasa.com</p>
<p>If you are found to be eligible for a refund, it will be made after deducting the bank transaction charges and/ or payment gateway, shipping cost or courier charges (to and fro), customs duty and/ or any other charges that may have been borne by the MONKVYASA.COM in processing and delivering the product or service. Multiple payments made against the same order will be refunded in full (without deducting any charges on the duplicate payments) but after retaining the cost of the original order.</p>
<h5 class="mt20 text-secondary">ONLINE PAYMENT</h5>
<p>In order to facilitate payments for products and services through MONKVYASA.COM at the request of the sellers or service providers offers an online payment gateway facility that will facilitate you to make payments in respect thereof to the sellers/ company using net banking, credit/debit card or any other acceptable mode and made available to the customers from time to time, subject to your complete acceptance and adherence to the terms and conditions hereof as well as the terms and conditions of the website of the payment gateway service provider. It is hereby clarified that the company does not itself collect any amount from the purchasers and the entire payment transaction (including collection from the purchasers and distribution to the company, vendor/seller/service providers’) is conducted by the online payment gateway facility provided and the account from which payments are done will be verified by the website at the time of registration.</p>
<h5 class="mt20 text-secondary">DEALING WITH ADVERTISERS</h5>
<p>Any of your correspondence or business dealings with/ or participation in promotions of, advertisers found on or through the service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings are solely between you and such advertiser. You agree that MONKVYAS.COM shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the service.</p>
<h5 class="mt20 text-secondary border-bottom">ASTROLOGER REQUISITION</h5>
<ul class="astrologers-terms">
   <li>The astrologers on the website are not employees of MONKVYASA.COM. </li>
   <li>Each Astrologer will have his own independent profile for which the listing is free. The name of Astrologers profile should be same as that in the Identity proofs and bank details uploaded for verification. The profile name cannot be changed at any point of time after activation. </li>
   <li>Any Astrologer can register with MONKVYASA.COM but it does not entail activation of the account. The registered Astrologer has to undergo a verification procedure to activate his/her account. The Astrologer should pass a knowledge test in Astrology in order to register with MONKVYASA.COM. The evaluation test will be conducted by verification team of MONKVYASA.COM. Acceptance or rejection of an Astrologers application will depend on the evaluation report of the verification team.On the successful completion of the verification procedure, the registered  Astrologer will become part of MONKVYASA.COM</li>
   <li>The Astrologer can his fix his/her own fees for consultation. If the consultation is found to be not feasible, MONKVYASA.COM has the right to suggest alteration in the fee structure </li>
   <li>The personal details of the astrologer are not publically available in the website. But name, place, consultation time, consultation fee can be publically viewed for customer convenience.</li>
   <li>Review, rating and biography options are available for the Astrologers profile. Based on these options, the customer can choose the best astrologer he seems fit. The review and rating once posted by the customer will be visible permanently in the Astrologers profile. Editing or deletion of the review is not possible unless it is abusive or defamatory. </li>
   <li>The website has 'pay' option on which the customer can click for paying various charges. </li>
   <li>A call button is available in the Astrologers profile which can be used for customer interaction. The call charges will be per minute. Separate charges will be levied from customers for return calls from Astrologer. Call charges can also be paid through the payment option available in the website.</li>
   <li>The Astrologer will be paid by MONKVYASA.COM in every month upon satisfying the following criteria.</li>
   <li>The minimum payable amount is Rs. 5000.</li>
   <li>Payment transfer is possible only when the Astrologers earnings reaches the minimum payable amount mentioned above.</li>
   <li>The payment will be made to the account of the Astrologer. If any discrepancy is found in the bank details, the payment will be withheld until resolved.</li>
   <li>MONKVYASA.COM does not charge any  Transaction charges and Service tax to the amount paid. But the website reserves the right to deduct transaction charges and service charges from the amount payable if deemed necessary.</li>
   <li>MONKVYASA.COM reserves the right to alter the payment limit at any time.</li>
   <li>Demanding personal contact details and personal monitory transactions by the Astrologer shall be considered as grounds for termination of account.</li>
   <li>The customer can view his/ her reports relating to marriage, health, wealth etc.. in th e 'My Reports' option available within the Astrologers profile. The customer can select the desired option and enter the birth details to receive the reply. If the reply seems to be non satisfactory the customer can ask for a refund from the Astrologer. MONKVYASA.COM reserves the right to cancel the consultation of the Astrologer and provide refund to the customer in the above mentioned scenario.</li>
   <li>MONKVYASA.COM reserves the right to access the Astrologers account if required.</li>
   <li>The Service is rendered directly by the Astrologer and the Website will not be responsible for erroneous services.</li>
   <li>The MONKVYASA.COM reserves the right to terminate or suspend the account of the astrologer at any time without notice.</li>
   <li>MONKVYASA reserves the future right to charge service charges and other allied taxes. </li>
   <li>The customer can review and rate the astrologer based on the satisfaction of service rendered by him. Defamatory and abusive comments are strictly prohibited in the review section. Astrologers account can be permanently deleted by MONKVYASA.COM if any abusive or defamatory activity against any other astrologer is found.  In case of any dissatisfaction, the customer may intimate the same to the astrologer and get it resolved. MONKVYAS.COM will not be responsible for any grievances relating to delay in consultation and technical error in payment gateway. </li>
   <li>MONKVYASA.COM may use Astrologer profile for promotional and advertisement purposes.</li>
</ul>`

function Terms() {
  return (
    <>
      <Header title="Terms And Conditions" />
      <DisplayData data={DATA} />
    </>
  )
}

export default Terms
