import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"

import PhonecallButton from "../components/button"
import ReportBookingButton from "../../requirement/components/button"
import LiveChatButton from "../../../components/live-chat/button"

class PhonecallInteractionNextOptions extends Component {
  render() {
    let { row } = this.props
    return (
      <Card>
        <CardContent>
          <Grid container spacing={24} align="center" justofy="center">
            <Grid item xs={4}>
              <Typography> Like to call again ? </Typography>
              <PhonecallButton id={row.astrologer_id} />
            </Grid>
            <Grid item xs={4}>
              <Typography> Book a report </Typography>
              <ReportBookingButton id={row.astrologer_id} />
            </Grid>
            <Grid item xs={4}>
              <Typography> Chat with astrologer </Typography>
              <LiveChatButton id={row.astrologer_id} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <br />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.props.history.push("/astrologers")}
              >
                Try other astrologers
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PhonecallInteractionNextOptions))
)
