export const COUPONS = [
  {
    code: "panchang100",
    value: 100,
    minimumRechargeValue: 100,
  },
  {
    code: "mv20",
    value: 20,
    minimumRechargeValue: 100,
  },
  {
    code: "mv50",
    value: 50,
    minimumRechargeValue: 200,
  },
  {
    code: "mv75",
    value: 75,
    minimumRechargeValue: 250,
  },
  {
    code: "mv100",
    value: 100,
    minimumRechargeValue: 500,
  },
  {
    code: "mk200",
    value: 200,
    minimumRechargeValue: 100,
  },
  {
    code: "monk100",
    value: 100,
    minimumRechargeValue: 100,
  },
]

export const COUNTRIES = {
  IN: {
    name: "India",
    phone: "91",
    currency: "INR",
  },
  US: {
    name: "United States",
    phone: "1",
    currency: "USD",
  },
}
