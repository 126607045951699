import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import StarRatingComponent from "react-star-rating-component"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import StarIcon from "@material-ui/icons/Star"
import EditIcon from "@material-ui/icons/Edit"

import { ModalPop } from "../../utils/components"
import { instance } from "../../utils"
import ReviewForm from "./components/form"
const FETCH_ROW_API = `review/get_row`

class ReviewRoot extends Component {
  state = {
    isLoading: true,
    row: null,
    onEdit: false,
    result: null,
    actionLoading: false,
    modalOpen: false,
  }

  componentWillMount = () => this.fetchRow()

  _setState = (newState) => this.setState(newState)

  fetchRow = () => {
    let { reference_id, service_id } = this.props
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: FETCH_ROW_API,
      data: { filter: { reference_id, service_id } },
    }).then((result) => {
      if (result.res != 1)
        this.setState({ isLoading: false, result, modalOpen: true })
      else {
        let row = result.row
        this.setState({ isLoading: false, row, modalOpen: row == null })
      }
    })
  }

  renderBlock = (row) => (
    <div style={{ textAlign: "center" }}>
      <StarRatingComponent
        name="review_rating"
        value={parseInt(row.review_rating)}
        onStarClick={this.handleStarChange}
        renderStarIcon={() => <StarIcon fontSize="large" />}
        editing={true}
      />
      <Typography color="textSecondary" paragraph>
        {row.review_text}
      </Typography>
      <Button
        style={{ float: "right" }}
        onClick={() => this.setState({ modalOpen: true })}
      >
        <EditIcon />
      </Button>
    </div>
  )

  renderContent = () => {
    let { row } = this.state
    return (
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          {row == null && (
            <Typography
              color="textSecondary"
              paragraph
              style={{ textAlign: "center" }}
            >
              Please review this astrologer to help other customers
            </Typography>
          )}
          {row != null && this.renderBlock(row)}
          {row == null && this.renderForm()}
        </CardContent>
      </Card>
    )
  }

  renderForm = () => {
    let { service_id, reference_id, astrologer_id } = this.props
    let { row } = this.state
    return (
      <ReviewForm
        onSuccess={() => {
          this.fetchRow()
          this.setState({ modalOpen: false })
        }}
        row={row}
        service_id={service_id}
        reference_id={reference_id}
        astrologer_id={astrologer_id}
      />
    )
  }

  renderDrawer = () => {
    let { modalOpen, isLoading, row } = this.state
    if (isLoading) return null
    let title =
      row == null
        ? "Please review this astrologer to help other customers"
        : "Update review"
    return (
      <Drawer
        anchor="bottom"
        open={modalOpen}
        onClose={() => this.setState({ modalOpen: false })}
      >
        <div
          tabIndex={0}
          role="button"
          //onClick={()=>this.setState({modalOpen:false})}
          //onKeyDown={()=>this.setState({modalOpen:false})}
        >
          <Typography
            color="primary"
            align="center"
            variant="subheading"
            style={{ padding: 10 }}
          >
            {title.toUpperCase()}
          </Typography>
          <Divider />
          <div style={{ padding: 20 }}>{this.renderForm()}</div>
        </div>
      </Drawer>
    )

    return (
      <ModalPop
        title={
          row == null
            ? "Please review this astrologer to help other customers"
            : "Update review"
        }
        modalOpen={modalOpen}
        content={this.renderForm}
        _setState={this._setState}
      />
    )
  }

  render() {
    let { isLoading, row } = this.state
    if (isLoading && row == null) return null
    return (
      <div>
        {this.renderContent()}
        {this.renderDrawer()}
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ReviewRoot))
