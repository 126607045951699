import React from "react"
import { Typography } from "@material-ui/core"

function DisplayData({ data }) {
  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: data }}
      style={{
        padding: "20px 50px",
        backgroundColor: "#fff",
        border: "1px solid #efe2e2",
        boxShadow: "4px 3px 15px #c7c6c6",
      }}
    />
  )
}

export default DisplayData
