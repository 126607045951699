import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import selectAstro from "../../../images/select-astrologer.png"
import enterBirthDetails from "../../../images/enter-birth-details.png"
import getReport from "../../../images/get-report.png"
import chatWithAstro from "../../../images/chat-with-astro.png"

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
})

function getSteps() {
  return [
    {
      head: "SELECT THE ASTROLOGER",
      content: "Go through list of expert astrolgers and choose one astrologer",
      img: selectAstro,
    },
    {
      head: "ENTER BIRTH DETAILS",
      content: "Enter your birth details and queries",
      img: enterBirthDetails,
    },
    {
      head: "GET REPORT",
      content:
        "Receive your report from the astrolger and you can download as PDF file",
      img: getReport,
    },
    {
      head: "CHAT WITH ASTROLOGER",
      content:
        "Have you still queries on same Report? Then continue your Chatting with astrologer.",
      img: chatWithAstro,
    },
  ]
}

class ReportSteps extends React.Component {
  state = {
    activeStep: 0,
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    })
  }

  render() {
    const { classes } = this.props
    const steps = getSteps()
    const { activeStep } = this.state

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((item, index) => (
            <Step key={index}>
              <StepLabel>{item.head}</StepLabel>
              <StepContent>
                <img src={item.img} style={{ width: 200 }} alt="" />
                <Typography>{item.content}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}
      </div>
    )
  }
}

ReportSteps.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ReportSteps)
