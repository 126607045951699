import React from "react"
import { Typography } from "@material-ui/core"

function Header({ title }) {
  return (
    <Typography variant="headline" color="primary" style={{ margin: 15 }}>
      {title}
    </Typography>
  )
}

export default Header
