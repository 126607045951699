import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing.unit,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -12,
  },
})

function FormButton(props) {
  const { classes, label, loading, ...rest } = props
  return (
    <div>
      <div className={classes.wrapper}>
        <Button variant="contained" disabled={loading} {...rest}>
          {label || `Submit`}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  )
}

FormButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormButton)
