import { io } from "socket.io-client"

const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST

const socketClient = io(SOCKET_HOST, {
  autoConnect: false,
})

if (process.env.NODE_ENV !== "production") {
  socketClient.onAny((event, ...args) => {
    console.log(event, args)
  })
}

export default socketClient
