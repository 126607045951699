import React, { useState } from "react"
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { createUrl, _toFixed } from "../../../utils/functions"

function FormOne(props) {
  const { wallet, chat_rate, currency, classes, history, closeModal } = props
  const [isRehargeDialogOpen, setRechargeDialogOpen] = useState(false)
  const [otherAmount, setOtherAmount] = useState(0)
  const [errorText, setErrorText] = useState("")
  const MINUTES = [10, 20, 30]
  const recharge = (amount) => {
    if (amount <= 0) {
      setErrorText("Enter some amount")
      return
    }
    const redirect = createUrl("/astrologers")
    history.push(createUrl("/payment", { redirect, amount }))
    closeModal()
  }

  return (
    <div>
      <div className={classes.callRate}>
        <Typography>Chat Rate: {chat_rate} INR/min</Typography>{" "}
      </div>
      <div className={classes.content}>
        <Typography>
          A/C Balance: {wallet} {currency}
        </Typography>
        <Typography color="error">
          You have insufficient balance, you must have minimum of {currency}{" "}
          {_toFixed(chat_rate * 10)} (10 mins minimum) to chat with astrologer.
        </Typography>
        <Typography className={classes.quickLink}>Quick Links</Typography>
        <div className={classes.quickLinkContent}>
          {MINUTES.map((minutes) => {
            const amount = chat_rate * minutes
            return (
              <div>
                <Button variant="outlined" onClick={(_e) => recharge(amount)}>
                  {_toFixed(amount)} {currency}
                </Button>
                <Typography variant="caption">{minutes} minutes</Typography>
              </div>
            )
          })}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.otherAmountBtn}
          onClick={(_e) => setRechargeDialogOpen(true)}
        >
          Other Amount
        </Button>
        <Dialog
          open={isRehargeDialogOpen}
          onClose={(_e) => setRechargeDialogOpen(false)}
        >
          <DialogTitle>Other Amount</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Amount"
              type="text"
              fullWidth
              onChange={(e) => setOtherAmount(e.target.value)}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  recharge(otherAmount)
                }
              }}
              required
            />
            <Typography
              color="error"
              style={{ visibility: errorText ? "visible" : "hidden" }}
            >
              {errorText}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              onClick={(_e) => recharge(otherAmount)}
            >
              Recharge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

const styles = (_theme) => ({
  callRate: {
    margin: "5px 0",
    padding: "20px 20px",
    background: "#f0e9f5",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  },
  content: {
    textAlign: "center",
    padding: "20px 0px",
  },
  quickLink: {
    margin: "20px 0px 10px 0px",
  },
  quickLinkContent: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
  },
  otherAmountBtn: {
    marginTop: "30px",
  },
})

export default withRouter(withStyles(styles)(FormOne))
