import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { withStyles, Button, TextField } from "@material-ui/core"
import { createUrl } from "../../../utils/functions"
import { pushMsg } from "../../../navigation/actions"

class WalletForm extends Component {
  state = {
    amount: 0,
  }

  handleProceed = () => {
    const { history, dispatch } = this.props
    const { amount } = this.state
    if (!parseInt(amount)) {
      dispatch(pushMsg("Please enter amount", "error"))
      return
    }
    history.push(createUrl("/payment", { redirect: "/wallet", amount }))
  }

  render() {
    const { classes } = this.props

    return (
      <div style={{ maxWidth: 400, margin: "0 auto" }}>
        <form
          style={{ padding: 30, textAlign: "center" }}
          onSubmit={this.handleProceed}
        >
          <TextField
            fullWidth
            style={{ textAlign: "center" }}
            value={this.state.amount || ``}
            autoFocus
            onChange={(event) =>
              this.setState({ amount: parseInt(event.target.value) })
            }
            placeholder="Enter amount to recharge"
          />
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.proceedButton}
            type="submit"
          >
            proceed
          </Button>
        </form>
      </div>
    )
  }
}

const styles = () => ({
  proceedButton: {
    marginTop: 20,
    color: "#fff",
    backgroundColor: "#37be5f",
  },
})

export default withRouter(withStyles(styles)(WalletForm))
