import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"

import { createUrl, _toFixed } from "../../../utils/functions"

const rcOtption = [5, 10, 15, 20, 25, 30]

class phonecallRechargeOptions extends Component {
  state = {
    minute: 0,
    amount: 0,
    open: this.props.talktime <= 5,
  }

  changeMinute = (minute) => {
    if (!minute || parseInt(minute) <= 0 || isNaN(minute)) {
      this.setState({ minute: "", amount: "" })
      return
    }
    let phone_service_rate = this.props.astrologer.phone_service_rate
    let amount = _toFixed(phone_service_rate * minute)
    this.setState({ amount, minute }, () =>
      window.scrollTo(0, document.body.scrollHeight)
    )
  }

  changeAmount = (amount) => {
    if (!amount || parseInt(amount) <= 0 || isNaN(amount)) {
      this.setState({ minute: "", amount: "" })
      return
    }
    let phone_service_rate = this.props.astrologer.phone_service_rate
    let minute = _toFixed(amount / phone_service_rate)
    this.setState({ amount, minute }, () =>
      window.scrollTo(0, document.body.scrollHeight)
    )
  }

  renderCustomOptions = () => {
    let { classes } = this.props
    let { minute, amount } = this.state
    return (
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Typography variant="subtitle1" color="secondary">
          Select custom
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              className={classes.input}
              variant="outlined"
              value={minute}
              onChange={(event) => this.changeMinute(event.target.value)}
              placeholder="Minutes to talk"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.input}
              variant="outlined"
              value={amount}
              onChange={(event) => this.changeAmount(event.target.value)}
              placeholder="Recharge amount"
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  renderRcOption = (minute) => {
    let { astrologer, currency, classes } = this.props
    let { phone_service_rate } = astrologer
    return (
      <div key={minute} className={classes.button}>
        <Button
          variant={this.state.minute == minute ? `contained` : `outlined`}
          color="primary"
          onClick={() => this.changeMinute(minute)}
        >
          {_toFixed(phone_service_rate * minute)} {currency}
        </Button>
        <Typography variant="caption">{minute} minutes</Typography>
      </div>
    )
  }

  handleProceed = () => {
    let { amount } = this.state
    let { astrologer_id, history } = this.props
    let redirect = createUrl("/phonecall/create", { astrologer_id })
    history.push(createUrl(`/payment`, { redirect, amount }))
  }

  renderContent = () => {
    let { minute } = this.state
    let { talktime } = this.props
    let newTalktime = parseFloat(talktime) + parseFloat(minute)
    return (
      <div style={{ textAlign: "center" }}>
        <Divider style={{ margin: "20px 0" }} />
        <Typography variant="subtitle1" color="secondary">
          {talktime > 0
            ? `Extend the call duration to`
            : `Please select from the options`}
        </Typography>
        {rcOtption.map((minute) => this.renderRcOption(minute))}
        {this.renderCustomOptions()}
        {this.state.minute > 0 && (
          <div>
            <br />
            <Typography variant="caption">
              Total {newTalktime} minutes of talktime
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleProceed}
            >
              Recharge and call
            </Button>
          </div>
        )}
      </div>
    )
  }

  render = () => {
    let { open } = this.state
    if (!open)
      return (
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          onClick={() => {
            this.setState({ open: true }, () =>
              window.scrollTo(0, document.body.scrollHeight)
            )
          }}
        >
          Add extra time
        </Button>
      )
    return this.renderContent()
  }
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit / 2,
    display: "inline-block",
  },
  input: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 150,
  },
})

const mapStateToProps = ({ auth, astrologers, enums }, { astrologer_id }) => {
  let user = auth.user
  let astrologer = astrologers.rows[astrologer_id]
  let { constants } = enums.rows
  let currency = constants.CURRENCY
  let talktime =
    parseFloat(user.wallet) > 0
      ? Math.floor(
          parseFloat(user.wallet) / parseFloat(astrologer.phone_service_rate)
        )
      : 0
  return { user, currency, talktime, astrologer }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(phonecallRechargeOptions))
)
