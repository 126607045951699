import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

class ConfirmationDialog extends React.Component {
  handleClickOpen = () => {
    this.props._setState({ confimrationOpen: true })
  }

  handleClose = () => {
    this.props._setState({ confimrationOpen: false })
  }

  handleSuccess = () => {
    this.props._setState({ confimrationOpen: false })
    this.props.onSuccess && this.props.onSuccess()
  }

  render() {
    let { confimrationOpen, title, description } = this.props
    return (
      <div>
        <Dialog
          open={confimrationOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSuccess} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default ConfirmationDialog
