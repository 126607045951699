import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Typography,
  LinearProgress,
  withStyles,
  CircularProgress,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { apiv2Instance, socketClient } from "../../../utils"

function FormThree(props) {
  const {
    chat_rate,
    astrologer_id,
    astrologerName,
    user,
    closeModal,
    classes,
    history,
    setChatSession,
  } = props
  const [isAvailable, setIsAvailable] = useState(true)
  const [isSendingRequest, setIsSendingRequest] = useState(false)
  const [text, setText] = useState("Request astrologer for chat")
  const requestTimeoutRef = useRef(null)

  useEffect(() => {
    if (!socketClient.connected) {
      socketClient.auth = { id: user.id }
      socketClient.connect()
    }
    return () => {
      socketClient.off("CHAT_CANCELLED")
      socketClient.off("CHAT_REQUEST_ACCEPTED")
      clearTimeout(requestTimeoutRef.current)
    }
  }, [])

  async function handleSendRequest(_e) {
    try {
      setIsSendingRequest(true)
      const { data } = await apiv2Instance.get(
        `/astrologer/status?id=${astrologer_id}`
      )
      const { isAvailable, isUsingApp } = data
      if (!isAvailable) {
        setText(
          "Astrologer not available at this moment, try some other astrologers..."
        )
        setIsAvailable(isAvailable)
        return
      }

      const msg = JSON.parse(sessionStorage.getItem("beforeChatMeta"))
      const chatReqPayload = {
        astrologerId: astrologer_id,
        userId: user.id,
        userName: user.first_name,
        chatTimeInMins: msg.chatTimeInMins,
        firstMessage: `Date Of Birth: ${msg.dateOfBirth}\nPlace Of Birth: ${msg.placeOfBirth}\nTime Of Birth: ${msg.timeOfBirth}\nPurpose: ${msg.purposeOfChat}`,
      }

      setText("Please wait, connecting with astrologer....")
      socketClient.on("CHAT_CANCELLED", function handleChatCancelled() {
        setText(
          "Astrologer not available at this moment, try some other astrologers..."
        )
        setIsAvailable(false)
        setIsSendingRequest(false)
        clearTimeout(requestTimeoutRef.current)
        requestTimeoutRef.current = null
      })
      socketClient.on(
        "CHAT_REQUEST_ACCEPTED",
        function handleChatAccept(payload) {
          setText("Request accepted, redirecting to chat...")
          const { chatRoomId } = payload
          payload.astrologerName = astrologerName
          sessionStorage.setItem("chatRoomInfo", JSON.stringify(payload))
          sessionStorage.removeItem("beforeChatMeta")
          socketClient.emit("JOIN_ROOM", {
            chatRoomId: chatRoomId,
          })
          setChatSession(true)
          clearTimeout(requestTimeoutRef.current)
          requestTimeoutRef.current = null
        }
      )

      if (!isUsingApp) {
        await apiv2Instance.post("/chat/request", chatReqPayload)
        requestTimeoutRef.current = setTimeout(async () => {
          try {
            await apiv2Instance.post("/chat/cancel", {
              astrologerId: astrologer_id,
              userId: user.id,
              reason: "Astrologer did not come online",
            })
            setText(
              "Astrologer not available at this moment, try some other astrologers..."
            )
            setIsAvailable(false)
            setIsSendingRequest(false)
          } catch (e) {
            console.error(e)
          }
        }, 120000)
      } else {
        socketClient.emit("CHAT_REQUEST", chatReqPayload)
        requestTimeoutRef.current = setTimeout(() => {
          setText(
            "Astrologer not available at this moment, try some other astrologers..."
          )
          setIsAvailable(false)
          setIsSendingRequest(false)
        }, 120000)
      }
    } catch (e) {
      console.error(`Unhandled error: ${e}`)
    }
  }

  return (
    <div>
      <div className={classes.callRate}>
        <Typography>Chat Rate: {chat_rate} INR/min</Typography>{" "}
      </div>
      {isSendingRequest ? <LinearProgress color="secondary" /> : null}
      <div className={classes.header}>
        <Typography variant="button">Send Chat Request</Typography>
      </div>
      <div className={classes.content}>
        <Typography variant="h6">{text}</Typography>
        {isSendingRequest ? (
          <Typography variant="subtitle2">
            Do not close the window, or click back!
          </Typography>
        ) : null}
        {isAvailable ? (
          <Button
            color="primary"
            variant="contained"
            className={classes.btn}
            onClick={handleSendRequest}
            disabled={isSendingRequest}
          >
            {isSendingRequest ? (
              <CircularProgress size={20} style={{ marginRight: "15px" }} />
            ) : null}
            {isSendingRequest ? "Requesting...." : "Request"}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            className={classes.btn}
            onClick={(_e) => {
              closeModal ? closeModal() : history.push("/astrologers")
            }}
          >
            Astrologers
          </Button>
        )}
      </div>
    </div>
  )
}

const styles = (_theme) => ({
  callRate: {
    margin: "5px 0",
    padding: "20px 20px",
    background: "#f0e9f5",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  },
  header: {
    padding: "20px 0px",
  },
  content: {
    marginTop: "20px",
    textAlign: "center",
  },
  btn: {
    marginTop: "20px",
  },
})

export default withRouter(withStyles(styles)(FormThree))
