import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import ProfileBasic from "./profile"
import WalletLeftCard from "../wallet/components/leftCard"

class AccountProfile extends Component {
  render() {
    return (
      <div>
        <WalletLeftCard />
        <ProfileBasic />
      </div>
    )
  }
}

const styles = (theme) => ({})

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(AccountProfile))
