import React from "react"
import Header from "./shared/Header"
import DisplayData from "./shared/DisplayData"

const DATA = `<div style="font-size: 18px;color: #7f48a1;" >Astrolytic Pvt. Ltd.</div>
<div>506, 5th Floor, Corporate Corner,</div>
<div>Sunder Nagar, Malad (West), Mumbai - 400064</div>
<div>Maharashtra, India</div>
 <p>hello@monkvyasa.com </p>
<p>+91-7777065664</p>`

function Contact() {
  return (
    <>
      <Header title="Contact Us" />
      <DisplayData data={DATA} />
    </>
  )
}

export default Contact
