import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  withStyles,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core"
import { logOut } from "../../../../modules/auth/actions"
import AstrologerShareButtons from "../../../../modules/astrologer/components/shareButtons"
import PersonIcon from "@material-ui/icons/Person"
import HomeIcon from "@material-ui/icons/Home"
import PeopleIcon from "@material-ui/icons/People"
import InfoIcon from "@material-ui/icons/Info"
import BookIcon from "@material-ui/icons/Book"
import ContactsIcon from "@material-ui/icons/Contacts"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import PhoneIcon from "@material-ui/icons/Phone"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import ChatIcon from "@material-ui/icons/Chat"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"

const styles = () => ({
  drawerHeader: {
    height: 60,
    display: "flex",
    background: "#7b1fa2",
    color: "#fff",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  iconRoot: {
    "& > svg": {
      fontSize: 16,
    },
  },
  drawer: {
    width: "70vw",
    maxWidth: 400,
  },
  menuTex: {
    cursor: "pointer",
    color: "#414141",
    paddingBottom: "2px",
  },
  contactIfo: {
    padding: "20px 30px",
    background: "#ece4f1",
  },
})

const menuItemsDefault = [
  {
    text: "Home",
    icon: <HomeIcon />,
    href: "/",
  },
  {
    text: "Astrologers",
    icon: <PeopleIcon />,
    href: "/astrologers",
  },
  {
    text: "About Us",
    icon: <InfoIcon />,
    href: "/about",
  },

  {
    text: "Blog",
    icon: <BookIcon />,
    href: "https://blog.monkvyasa.com",
    external: true,
  },
  {
    text: "Contact Us",
    icon: <ContactsIcon />,
    href: "/contact-us",
  },
  {
    divider: true,
  },
  {
    text: "FAQ",
    icon: <QuestionAnswerIcon />,
    href: "/faq",
  },
  {
    text: "Privacy Policy",
    icon: <ChromeReaderModeIcon />,
    href: "/privacy-policy",
  },
  {
    text: "Terms & Conditions",
    icon: <LibraryBooksIcon />,
    href: "/terms-and-conditions",
  },
]

const menuItemsUser = [
  {
    text: "My Account",
    icon: <PersonIcon />,
    href: "/profile",
  },
  {
    text: "Wallet",
    icon: <AccountBalanceWalletIcon />,
    href: "/wallet",
  },
  {
    text: "Report Bookings",
    icon: <QuestionAnswerIcon />,
    href: "/report-booking-list",
  },
  {
    text: "Phone calls",
    icon: <PhoneIcon />,
    href: "/phonecall-list",
  },
  {
    text: "Chats",
    icon: <ChatIcon />,
    href: "/chat",
  },
]

const menuLogout = [
  {
    text: "Log out",
    icon: <PowerSettingsNewIcon />,
    href: "/logout",
  },
]

class SwipeableTemporaryDrawer extends React.Component {
  state = {
    open: false,
    menuItems: menuItemsDefault,
  }

  componentDidUpdate = (prevProps) => {
    const { user } = this.props
    if (user == null || prevProps.user == this.props.user) return
    let menuItems = [...menuItemsDefault]
    menuItems.splice(4, 0, ...menuItemsUser)
    menuItems.splice(14, 0, ...menuLogout)
    this.setState({ menuItems })
  }

  toggleDrawer = (open) => () => {
    this.setState({ open })
  }

  handleLougout = () => {
    this.props.dispatch(logOut())
  }

  renderHead = () => {
    const { classes, user } = this.props

    return (
      <div className={classes.drawerHeader}>
        <PersonIcon />
        <Typography
          style={{ color: "#fff", width: "55%", fontSize: 16, marginLeft: -15 }}
          variant="subtitle1"
        >
          {user ? user.first_name : "Login & Sign Up"}
        </Typography>
        <img
          style={{
            width: 33,
            background: "#fff",
            padding: 5,
            borderRadius: "100%",
          }}
          src={"/favicon.png"}
          alt=""
        />
      </div>
    )
  }

  render() {
    const { classes, history, isLoggedIn } = this.props
    const { menuItems } = this.state
    const sideList = (
      <div className={classes.list}>
        <List>
          {menuItems.map((item, index) =>
            item.divider ? (
              <Divider style={{ margin: "6px 0" }} key={index} />
            ) : item.external ? (
              <ListItem
                button
                dense
                key={item.text}
                style={{ padding: "6px 6px 6px 20px" }}
                onClick={() => window.open(item.href)}
              >
                <ListItemIcon style={{ marginRight: 0 }}>
                  <div className={classes.iconRoot}>{item.icon}</div>
                </ListItemIcon>
                <ListItemText
                  secondary={item.text}
                  classes={{ secondary: classes.menuTex }}
                />
              </ListItem>
            ) : (
              <ListItem
                button
                dense
                key={item.text}
                style={{ padding: "6px 6px 6px 20px" }}
                onClick={() => history.push(item.href)}
              >
                <ListItemIcon style={{ marginRight: 0 }}>
                  <div className={classes.iconRoot}>{item.icon}</div>
                </ListItemIcon>
                <ListItemText
                  secondary={item.text}
                  classes={{ secondary: classes.menuTex }}
                />
              </ListItem>
            )
          )}
        </List>
      </div>
    )

    return (
      <React.Fragment>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
          onClick={this.toggleDrawer(true)}
        >
          <ViewHeadlineIcon />
        </IconButton>

        <SwipeableDrawer
          open={this.state.open}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
          classes={{
            paper: classes.drawer,
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {this.renderHead()}
            <Divider />
            {sideList}

            {!isLoggedIn && (
              <React.Fragment>
                <Divider />
                <List>
                  <ListItem
                    onClick={() =>
                      (window.location =
                        "https://www.astrologer.monkvyasa.com/")
                    }
                  >
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <OpenInNewIcon style={{ fontSize: 16 }} />
                    </ListItemIcon>
                    <ListItemText
                      secondary="Astrologer Account"
                      classes={{ secondary: classes.menuTex }}
                      style={{ paddingTop: "4.12px" }}
                    />
                  </ListItem>
                </List>
              </React.Fragment>
            )}
            <Divider />
            <div className={classes.contactIfo}>
              <Typography variant="caption">Call Us :- 24x7</Typography>
              <a href="tel:+917777065664" style={{ textDecoration: "none" }}>
                <Typography color="secondary">+91-777065664</Typography>
              </a>
              <Typography color="secondary">hello@monkvyasa.com</Typography>
              <AstrologerShareButtons title="" />
            </div>
          </div>
          <div style={{ textAlign: "center", padding: 20 }}>
            <a href="https://play.google.com/store/apps/details?id=com.monkvyasa&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                style={{ width: "100%", maxWidth: 150 }}
              />
            </a>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    )
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth }) => {
  const { user, isLoggedIn } = auth
  const onRegistration = user && user.service_status === 3
  return { user, isLoggedIn, onRegistration }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(SwipeableTemporaryDrawer))
)
