import React, { Component } from "react"
import { connect } from "react-redux"

import { withStyles } from "@material-ui/core/styles"

import ChatRow from "./row"
const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  borderBottom: {
    borderBottom: "1px solid #efe7f5",
  },
})

class ChatList extends Component {
  render() {
    const { classes, rows, isLoading } = this.props
    return (
      <div>
        {Object.values(rows).map((row, idx) => (
          <div
            key={idx}
            className={`${
              idx != rows.length - 1 ? classes.borderBottom : null
            }`}
          >
            <ChatRow row={row} />
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = ({ requirement, astrologers, enums }, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(ChatList))
