import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import EditIcon from "@material-ui/icons/Edit"

import PhoneMissedCall from "../components/missedCall"
import AvailableNumbers from "./availableNumbers"
import { COUNTRIES as countries } from "../../../constants"
import { updatePhoncallColumn } from "../actions"
import { instance } from "../../../utils"

const API = `phonecall/phonecall_public/validate_phone`

class PhoneNumberConfirm extends Component {
  state = {
    confirmed: false,
    phone_valid: false,
    whitelisted: false,
    isLoading: false,
  }

  componentDidMount = () => {
    let { country_code, call_phone_number } = this.props.row
    if (parseInt(call_phone_number) > 0) setTimeout(this.validatePhone, 100)
  }

  onSelectPhone = (country_code, call_phone_number) => {
    let { dispatch } = this.props
    dispatch(updatePhoncallColumn("country_code", country_code))
    dispatch(updatePhoncallColumn("call_phone_number", call_phone_number))
    dispatch(updatePhoncallColumn("whitelisted", false))
    dispatch(updatePhoncallColumn("phone_valid", false))
    setTimeout(this.validatePhone, 100)
  }

  handleChange = (name) => (event) => {
    let { dispatch } = this.props
    dispatch(updatePhoncallColumn([name], event.target.value))
    dispatch(updatePhoncallColumn("whitelisted", false))
    dispatch(updatePhoncallColumn("phone_valid", false))
  }

  validatePhone = () => {
    let { dispatch, row } = this.props
    let { country_code, call_phone_number } = row
    this.setState({ isLoading: true })
    instance
      .post(API, { country_code, phone: call_phone_number })
      .then((result) => {
        let { res, msg, whitelisted, phone_valid } = result
        this.setState({ whitelisted, phone_valid, isLoading: false })
        //dispatch( pushMsg( msg ) )
        dispatch(updatePhoncallColumn("whitelisted", whitelisted))
        dispatch(updatePhoncallColumn("phone_valid", phone_valid))
      })
  }

  renderForm = () => {
    let { row, classes } = this.props
    let { isLoading } = this.state
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={4}>
            <Select
              className={classes.input}
              value={row.country_code || 0}
              onChange={this.handleChange("country_code")}
              fullWidth
            >
              {Object.keys(countries).map((key) => (
                <MenuItem
                  key={key}
                  value={countries[key].phone}
                >{`${key} +${countries[key].phone}`}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={8} className={classes.helpButton}>
            <TextField
              fullWidth
              className={classes.input}
              value={row.call_phone_number}
              onChange={this.handleChange("call_phone_number")}
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          onClick={this.validatePhone}
          className={classes.confirmButton}
          variant="contained"
          disabled={isLoading}
          size="small"
        >
          Confirm phone number
        </Button>
        <AvailableNumbers row={row} onSelect={this.onSelectPhone} />
      </React.Fragment>
    )
  }

  renderBlock = () => {
    let { row, classes, dispatch } = this.props
    let { country_code, call_phone_number } = row
    return (
      <React.Fragment>
        <Typography
          align="center"
          color="primary"
          fontWeight="fontWeightMedium"
          variant="subtitle1"
        >
          {" "}
          {`+${country_code} ${call_phone_number}`}
        </Typography>
        <Button
          color="secondary"
          style={{ margin: 0 }}
          onClick={() => dispatch(updatePhoncallColumn("phone_valid", false))}
        >
          Edit
          <EditIcon className={classes.icon} color="primary" />
        </Button>
      </React.Fragment>
    )
  }

  render() {
    let { row, classes, dispatch } = this.props
    let { whitelisted, phone_valid, country_code, call_phone_number } = row
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ textAlign: "center", paddngTop: 10 }}>
          <Typography color="textSecondary">
            Your call from astrologer is connecting to
          </Typography>
        </div>
        {phone_valid ? this.renderBlock() : this.renderForm()}
        {!whitelisted && phone_valid && (
          <PhoneMissedCall
            country_code={country_code}
            phone={call_phone_number}
            onSuccess={(whitelisted) =>
              dispatch(updatePhoncallColumn("whitelisted", whitelisted))
            }
          />
        )}
      </div>
    )
  }
}

const styles = (theme) => ({
  input: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  confirmButton: {
    margin: theme.spacing.unit,
  },
})

const mapStateToProps = ({ phonecall }, ownProps) => {
  let row = phonecall.row
  return { row }
}
export default connect(mapStateToProps)(withStyles(styles)(PhoneNumberConfirm))
