import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import emptyBox from "../../../images/empty-box.png"

class EmptyCard extends Component {
  renderContent = () => {
    let { classes, msg } = this.props

    return (
      <div style={{ textAlign: "center", marginTop: "20vh" }}>
        <img src={emptyBox} style={styles.img} alt="" />
        <Typography className={classes.text} align="center">
          {msg || `List empty!`}
        </Typography>
      </div>
    )
  }

  render() {
    let { card } = this.props
    return this.renderContent()
    if (card)
      return (
        <Card>
          <CardContent>{this.renderContent()}</CardContent>
        </Card>
      )
    return this.renderContent()
  }
}

const styles = () => ({
  icon: {
    fontSize: 50,
    color: "#fd9a9a",
  },
  text: {
    color: "#9c2525",
    fontSize: 20,
    fontWeight: 500,
  },
  img: {
    width: "100%",
    maxWidth: 250,
    margin: 10,
  },
})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(EmptyCard))
