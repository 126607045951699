import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import ReportBookingListRow from "./row"
import { instance } from "../../../utils"
import { PaginationComponent, Progress } from "../../../utils/components"
import { EmptyCard } from "../../../utils/components/misc"
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty"
import HourglassFullIcon from "@material-ui/icons/HourglassFull"
import ListIcon from "@material-ui/icons/List"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"

const API = `requirement/get_rows`

class ReportBookingList extends Component {
  state = {
    tabVal: "all",
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 5,
  }

  componentWillMount = () => this.fetchRows()

  handleChange = (event, value) =>
    this.setState({ tabVal: value, page: 0 }, this.fetchRows)

  fetchRows = () => {
    let { page, limit } = this.state
    let start = page * limit
    let { user } = this.props
    let order_by = "requirement_join_date-DESC"
    let filter = { user_id: user.id }
    let requirement_status = this.getReqStatusArray()
    if (requirement_status)
      filter.requirement_status = {
        name: "requirement_status",
        value: requirement_status.join(","),
      }
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter, order_by },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  renderPagination = () => {
    return (
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    )
  }

  getReqStatusArray = () => {
    let requirement_status_arr = false
    let { tabVal } = this.state
    switch (tabVal) {
      case "pending":
        requirement_status_arr = [1]
        break
      case "active":
        requirement_status_arr = [2, 3, 4, 5, 6, 7, 8]
        break
      case "completed":
        requirement_status_arr = [9]
        break
      default:
        requirement_status_arr = false
        break
    }
    return requirement_status_arr
  }

  renderTabs = () => (
    <Tabs
      value={this.state.tabVal}
      onChange={this.handleChange}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
      variant="fullWidth"
    >
      <Tab value="all" icon={<ListIcon />} label="ALL" />
      <Tab value="pending" icon={<HourglassEmptyIcon />} label="PENDING" />
      <Tab value="active" icon={<QuestionAnswerIcon />} label="ACTIVE" />
      <Tab value="completed" icon={<HourglassFullIcon />} label="COMPLETED" />
    </Tabs>
  )

  renderContent = () => {
    return null
  }

  render() {
    let { rows, isLoading } = this.state
    let cont = rows.length ? (
      rows.map((x) => <ReportBookingListRow key={x.requirement_id} row={x} />)
    ) : isLoading ? (
      <Progress />
    ) : (
      <EmptyCard card />
    )

    return (
      <div>
        {this.renderTabs()}
        {cont}
        {this.renderPagination()}
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ auth }) => {
  let { user } = auth
  return { user }
}

export default connect(mapStateToProps)(withStyles(styles)(ReportBookingList))
