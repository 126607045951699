import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import PhonecallListRow from "./row"
import { instance } from "../../../utils"
import { PaginationComponent, Progress } from "../../../utils/components"
import { EmptyCard } from "../../../utils/components/misc"

const API = `phonecall/get_rows`

class PhonecallList extends Component {
  state = {
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    limit: 5,
  }

  componentWillMount = () => this.fetchRows()

  fetchRows = () => {
    let { page, limit } = this.state
    let start = page * limit
    let { user } = this.props
    let filter = { user_id: user.id }
    this.setState({ isLoading: true })
    instance({
      method: "post",
      url: API,
      data: { start, limit, filter, order_by: "call_init_time-desc" },
    }).then((result) => {
      let { rows, total } = result
      this.setState({ isLoading: false, rows, total })
    })
  }

  renderPagination = () => {
    return (
      <PaginationComponent
        {...this.state}
        onPageChange={({ selected }) => {
          this.setState({ page: selected }, this.fetchRows)
        }}
      />
    )
  }

  render() {
    let { rows, isLoading } = this.state
    let cont = rows.length ? (
      rows.map((x) => <PhonecallListRow key={x.phonecall_id} row={x} />)
    ) : isLoading ? (
      <Progress />
    ) : (
      <EmptyCard card />
    )

    return (
      <div>
        {cont}
        {this.renderPagination()}
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ auth }) => {
  let { user } = auth
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(PhonecallList))
