import React, { useState, Fragment } from "react"
import { connect } from "react-redux"

import { FormButton, FormResult } from "../../../utils/form"
import { instance } from "../../../utils/functions"
import { pushMsg } from "../../../navigation/actions"

const API = `requirement/cancel/`

function ReportCancelButton({ requirement_id, dispatch, onSuccess }) {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0)
  const [result, setResult] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setIsLoading(true)
    instance.post(`${API}${requirement_id}`).then((result) => {
      let { res, msg } = result
      if (res !== 1) {
        setIsLoading(false)
        setResult(res)
        return
      }
      dispatch(pushMsg(msg, "success"))
      onSuccess && onSuccess()
    })
  }

  return (
    <Fragment>
      <FormResult result={result} />
      <FormButton
        loading={isLoading}
        label="Cancel booking"
        variant="outlined"
        color="default"
        onClick={handleClick}
      />
    </Fragment>
  )
}

const mapStateToProps = ({}) => ({})

export default connect(mapStateToProps, {})(ReportCancelButton)
