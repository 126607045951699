import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import astrologersReducer from "../modules/astrologer/reducer"
import authReducer from "../modules/auth/reducer"
import msgReducer from "../navigation/layouts/msg/reducer"
import enumsReducer from "./enums/reducer"
import requirementReducer from "../modules/requirement/reducer"
import birthDetailsReducer from "../modules/birthdetails/reducer"
import phonecallReducer from "../modules/phonecall/reducer"

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    msg: msgReducer,
    enums: enumsReducer,
    astrologers: astrologersReducer,
    auth: authReducer,
    requirement: requirementReducer,
    birthDetails: birthDetailsReducer,
    phonecall: phonecallReducer,
  })
