import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button"
import PhoneIcon from "@material-ui/icons/Phone"
import PhonePausedIcon from "@material-ui/icons/PhonePaused"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"

import { createUrl } from "../../../utils/functions"
import ModalComponent from "../../../utils/components/modal"
import PhonecallCreatePage from "../create"
import { pushMsg } from "../../../navigation/actions"

class PhonecallButton extends Component {
  state = {
    modalOpen: false, //this.props.id == 1466, //false,//false
    msg: "",
    btnColor: "#ccc",
    btnTxt: "CallNow",
    btnICon: <PhoneIcon style={{ marginRight: 10, fontSize: 14 }} />,
  }

  _setState = (newState) => this.setState(newState)

  getStatus = () => {
    let {
      phone_service_rate,
      available,
      phone_service_in_call,
      can_talk,
    } = this.props.row.phone_service
    let isAvailbale = parseFloat(phone_service_rate) > 0 && available
    if (!isAvailbale) {
      return {
        btnColor: `linear-gradient(45deg, #6bbf61 30%, #6bbf61 90%)`,
        btnTxt: `Offline`,
        msg: ` is offline`,
        btnICon: <PhonePausedIcon style={{ marginRight: 10, fontSize: 14 }} />,
      }
    }
    let btnTxt = `Call`
    let btnColor = `linear-gradient(45deg, #6bbf61 30%, #6bbf61 90%)`
    let msg = `not available now`
    let btnICon = <PhoneIcon style={{ marginRight: 10, fontSize: 14 }} />

    if (phone_service_in_call == 1) {
      btnTxt = `In call`
      btnICon = <PhoneInTalkIcon style={{ marginRight: 10, fontSize: 14 }} />
      btnColor = `#c2ffea`
      msg = ` in other call`
    }

    return { isAvailbale, btnColor, btnTxt, btnICon, msg }
  }

  renderModal = () => (
    <ModalComponent
      _setState={this._setState}
      modalOpen={this.state.modalOpen}
      title="Phone calling"
      content={() => <PhonecallCreatePage astrologer_id={this.props.id} />}
    />
  )

  handleClick = () => {
    let { msg } = this.getStatus()
    let { isLoggedIn, dispatch, history, id, phone_service } = this.props
    let { first_name } = this.props.row
    let naMsg = `Sorry, Astrologer ${first_name} ${msg}`

    if (!phone_service.can_talk) this.props.dispatch(pushMsg(naMsg))
    else if (!isLoggedIn) {
      let redirect = createUrl("/phonecall/create", { astrologer_id: id })
      history.push(createUrl(`/authentication`, { redirect }))
    } else this.setState({ modalOpen: true })
  }

  renderButton = () => {
    let { btnTxt, btnColor, btnICon } = this.getStatus()
    return (
      <Button
        size="small"
        fullWidth
        variant="contained"
        className={this.props.classes.button}
        onClick={this.handleClick}
        style={{
          background: btnColor,
          opacity: this.props.phone_service.available ? 1 : ".8",
        }}
      >
        {btnICon} {btnTxt}
      </Button>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderButton()}
        {this.renderModal()}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  root: {
    background: "#f8f8f8",
    width: "100%",
    overflowX: "hidden",
  },
  flex: {
    flex: 1,
  },
  button: {
    borderRadius: 0,
    paddingRight: 10,
    width: "90%",
    margin: theme.spacing.unit,
    color: "#fff",
  },
})

const mapStateToProps = ({ astrologers, enums, auth }, { id }) => {
  let row = astrologers.rows[id]
  let phone_service = row.phone_service
  let { isLoggedIn } = auth
  return { row, isLoggedIn, phone_service }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PhonecallButton))
)
