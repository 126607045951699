import React from "react"
import { Modal, Typography, Button, withStyles } from "@material-ui/core"

function Dialog(props) {
  const { classes, isOpen, handleClose, title, content } = props

  return (
    <Modal style={{ borderRadius: "5px" }} open={isOpen} onClose={handleClose}>
      <div className={classes.paper}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{content}</Typography>
        <Button color="primary" variant="contained" onClick={handleClose}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

const styles = (theme) => ({
  paper: {
    margin: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    height: "160px",
  },
})

export default withStyles(styles)(Dialog)
