let initialState = {
  isLoading: true,
  isLoggedIn: false,
  user_id: null,
  user: null,
  error: false,
  lastFetch: null,
  lastFetchValid: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_AUTH":
      let today = new Date()
      let diffMs = state.lastFetch != null ? today - state.lastFetch : false
      let diffMins = diffMs
        ? Math.round(((diffMs % 86400000) % 3600000) / 60000)
        : false
      let lastFetchValid = !state.error && diffMins !== false && diffMins <= 10
      return {
        ...state,
        isLoading: true,
        error: false,
        lastFetchValid,
      }
    case "FETCH_AUTH_DONE":
      let { res, user, grouped_in } = action.payload
      let isLoggedIn = res == 1 && grouped_in == 3
      let error = res == undefined
      return {
        ...state,
        isLoading: false,
        isLoggedIn,
        user,
        error,
        lastFetch: error ? null : new Date(),
        lastFetchValid: !error,
      }

    case "LOGOUT_FULFILLED":
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user_id: null,
        user: null,
      }

    case "UPDATE_AUTH_COLUMN":
      let column = action.payload.column
      let column_val = action.payload.column_val
      return {
        ...state,
        details: {
          ...state.details,
          [column]: column_val,
        },
      }
    default:
      return state
  }
}

export default authReducer
