import React, { useState } from "react"
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
} from "@material-ui/core"

function InputWrapper(props) {
  return (
    <TextField
      {...props}
      variant="outlined"
      required
      style={{
        display: "block",
        marginBottom: "20px",
        minWidth: "320px",
      }}
    />
  )
}

function FormTwo(props) {
  const { user, chat_rate, setFormStep, classes } = props
  let initialData = {
    name: user.first_name,
    dateOfBirth: "",
    placeOfBirth: "",
    timeOfBirth: "",
    purposeOfChat: "",
  }
  const totalChatTime = Math.floor(
    parseFloat(user.wallet) / parseFloat(chat_rate)
  )
  const beforeChatMeta = JSON.parse(sessionStorage.getItem("beforeChatMeta"))
  if (beforeChatMeta) initialData = { ...beforeChatMeta }

  const [data, setData] = useState(initialData)
  const [dialog, setDialog] = useState(false)

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleClose = (_e) => {
    setDialog({ ...dialog, isOpen: false })
  }

  const handleProceed = (e) => {
    e.preventDefault()
    if (parseInt(data.chatTimeInMins) <= 0) {
      setDialog({
        isOpen: true,
        text: "Minimum time must be 1 minute",
      })
      return
    }
    if (totalChatTime < parseInt(data.chatTimeInMins)) {
      setDialog({
        isOpen: true,
        text: `Insufficient balance to chat for ${data.chatTimeInMins} minutes.
                You can chat for maximum of ${totalChatTime} minutes`,
      })
      return
    }
    sessionStorage.setItem("beforeChatMeta", JSON.stringify(data))
    setFormStep(3)
  }

  return (
    <div>
      <div className={classes.callRate}>
        <Typography>Chat Rate: {chat_rate} INR/min</Typography>{" "}
      </div>
      <div className={classes.content}>
        <Typography variant="caption">
          You can chat for {totalChatTime} minutes.
        </Typography>
        <br />
        <Typography variant="button">Details for chat:</Typography>
        <form className={classes.form} onSubmit={handleProceed}>
          <InputWrapper type="text" label="Name" value={data.name} disabled />
          <InputWrapper
            label="Chat time"
            type="number"
            placeholder="20"
            value={data.chatTimeInMins}
            name="chatTimeInMins"
            onChange={handleChange}
            autoFocus
          />
          <InputWrapper
            type="text"
            label="Place Of Birth"
            value={data.placeOfBirth}
            name="placeOfBirth"
            onChange={handleChange}
          />
          <InputWrapper
            type="text"
            label="Date of Birth"
            value={data.dateOfBirth}
            name="dateOfBirth"
            onChange={handleChange}
          />
          <InputWrapper
            type="text"
            label="Time of Birth"
            value={data.timeOfBirth}
            name="timeOfBirth"
            onChange={handleChange}
          />
          <InputWrapper
            label="Purpose of chat"
            multiline
            rows="4"
            rowsMax="4"
            value={data.purposeOfChat}
            name="purposeOfChat"
            onChange={handleChange}
          />
          <br />
          <div className={classes.submitBtnRoot}>
            <Button type="submit" variant="contained" color="primary">
              Proceed
            </Button>
          </div>
        </form>
      </div>
      <Dialog open={dialog.isOpen} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{dialog.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const styles = (theme) => ({
  callRate: {
    margin: "5px 0",
    padding: "20px 20px",
    background: "#f0e9f5",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  },
  content: {
    padding: "20px 0px",
  },
  form: {
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  submitBtnRoot: {
    marginRight: "30px",
  },
})

export default withStyles(styles)(FormTwo)
