import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import ReactPaginate from "react-paginate"
import LinearProgress from "@material-ui/core/LinearProgress"

class PaginationComponent extends Component {
  render() {
    let { classes, total, limit, onPageChange, isLoading, page } = this.props
    let pageCount = Math.ceil(total / limit)
    if (pageCount <= 1) return null
    if (isLoading) return <LinearProgress />
    return (
      <ReactPaginate
        forcePage={page}
        containerClassName={classes.pagination}
        pageClassName={classes.paginationLi}
        breakClassName={classes.paginationBreak}
        nextClassName={classes.inlineBlock}
        previousClassName={classes.inlineBlock}
        pageLinkClassName={classes.pageLink}
        activeLinkClassName={classes.activeLink}
        pageCount={pageCount}
        nextLabel={<span> >> </span>}
        previousLabel={<span> {`<<`} </span>}
        onPageChange={onPageChange}
      />
    )
  }
}

const styles = () => ({
  inlineBlock: {
    display: "inline-block",
    padding: 10,
  },
  pagination: {
    textAlign: "center",
  },
  paginationLi: {
    display: "inline-block",
  },
  paginationBreak: {
    display: "inline-block",
  },
  pageLink: {
    padding: 10,
    color: "blue",
    border: "1px solid #f8f4fb",
    background: "#eedafb",
  },
  activeLink: {
    background: "#7f48a2",
    color: "#fff",
  },
})

const mapStateToProps = ({}) => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(PaginationComponent))
