import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import CloudOffIcon from "@material-ui/icons/CloudOff"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import CssBaseline from "@material-ui/core/CssBaseline"
import Button from "@material-ui/core/Button"

import Header from "./components/header/index"
import Footer from "./components/footer"
import Msg from "./msg"

import { fetchRows } from "../../modules/astrologer/actions"
import { fetchAuth } from "../../modules/auth/actions"
import { fetchRows as fetchEnums } from "../../store/enums/actions"
import { createUrl } from "../../utils/functions"
import { Progress } from "../../utils/components"

const styles = (theme) => ({
  root: {},

  heroContent: {
    margin: "0 auto",
  },
  layout: {
    width: "auto",
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
})

class DefaultComponent extends Component {
  componentWillMount = () => this.fetchData()

  fetchData = () => {
    let { dispatch } = this.props
    dispatch(fetchRows())
    dispatch(fetchAuth())
    dispatch(fetchEnums())
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.pathname != this.props.pathname) {
      alert("ROUTE CHANGED")
    }
    if (
      !nextProps.isLoading &&
      nextProps.isProtected &&
      !nextProps.isLoggedIn
    ) {
      let { pathname, search } = nextProps.location
      let redirect = `${pathname}${search}`
      this.props.history.replace(createUrl("/authentication", { redirect }))
    }
  }

  renderContent = () => {
    let {
      isLoggedIn,
      isProtected,
      isHome,
      isMobile,
      classes,
      children,
    } = this.props
    if (!isLoggedIn && isProtected) return null
    let mainStyleExtra = {}
    if (!isHome) mainStyleExtra.maxWidth = 1370

    return (
      <main className={classes.layout} style={mainStyleExtra}>
        <div className={classes.heroContent}>{children}</div>
      </main>
    )
  }

  renderError = () => (
    <div style={{ padding: 50, textAlign: "center" }}>
      <CloudOffIcon fontSize="large" color="disabled" />
      <Typography color="secondary" variant="subtitle2" gutterBottom>
        Something went wrong !
      </Typography>
      <Button variant="contained" onClick={this.fetchData}>
        RETRY
      </Button>
    </div>
  )

  render() {
    const { classes, isLoading, lastFetchValid, error } = this.props
    let cont = (
      <React.Fragment>
        <LinearProgress color="secondary" />
        <Progress />
      </React.Fragment>
    )

    if (error) cont = this.renderError()

    if (lastFetchValid) cont = this.renderContent()

    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        <Msg />
        <Header />
        {cont}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = ({ enums, astrologers, auth, router }) => {
  const isHome = router.location.pathname == "/"
  const isMobile = window.innerWidth < 500
  let isLoading = auth.isLoading || enums.isLoading || astrologers.isLoading
  let error = auth.error || enums.error || astrologers.error
  let lastFetchValid =
    auth.lastFetchValid && enums.lastFetchValid && astrologers.lastFetchValid
  let isLoggedIn = auth.isLoggedIn

  return {
    isLoading,
    auth,
    isLoggedIn,
    isHome,
    isMobile,
    error,
    lastFetchValid,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(DefaultComponent))
)
