import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import ReportBookingButton from "../../requirement/components/button"
import PhonecallButton from "../../phonecall/components/button"
import LiveChatButton from "../../../components/live-chat/button"
import AstrologerMainCard from "./mainCard"

class AstrologerListCard extends Component {
  renderButtons = () => {
    const { phone_service_rate } = this.props.row.phone_service
    const { row } = this.props
    const { report_service_rate, currency } = row
    const service_rate = report_service_rate ? (
      <span>
        {report_service_rate} <span style={{ color: "#999" }}>{currency}</span>
      </span>
    ) : (
      `-NA-`
    )
    let phonecall_rate = phone_service_rate ? (
      <span>
        {row.phone_service_rate}{" "}
        <span style={{ color: "#999" }}>{currency}</span>/min{" "}
      </span>
    ) : (
      `-NA-`
    )
    let chat_rate = phone_service_rate ? (
      <span>
        {row.phone_service_rate}{" "}
        <span style={{ color: "#999" }}>{currency}</span>/min{" "}
      </span>
    ) : (
      `-NA-`
    )

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={this.props.classes.actions}
      >
        <Grid item xs={4}>
          <Typography>{service_rate}</Typography>
          <ReportBookingButton id={this.props.id} />
        </Grid>
        <Grid item xs={4}>
          <Typography>{phonecall_rate}</Typography>
          <PhonecallButton id={this.props.id} />
        </Grid>
        <Grid item xs={4}>
          <Typography>{chat_rate}</Typography>
          <LiveChatButton
            id={this.props.id}
            chat_rate={row.phone_service_rate}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.card}>
        <AstrologerMainCard id={this.props.id} />
        {this.renderButtons()}
      </div>
    )
  }
}

AstrologerListCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = ({ astrologers, enums }, { id }) => {
  return {
    row: astrologers.rows[id],
    languages: enums.rows.languages.vt,
    professions: enums.rows.professions.vt,
  }
}

const styles = (theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "#fff",
    border: "1px solid #efe2e2",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    width: "90%",
    borderRadius: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  actions: {
    textAlign: "center",
  },
})

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AstrologerListCard)
)
