import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { withRouter } from "react-router-dom"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import {
  getUploadImge,
  convertMysqlDateTime,
  createUrl,
} from "../../../utils/functions"
import { getBookingServiceDetails } from "../functions"

class ReportBookingListRow extends Component {
  showBooking = () => {
    let { history, row } = this.props
    if (row.requirement_status < 2)
      history.push(
        createUrl("/requirement/create", {
          requirement_group_ref: row.requirement_group_ref,
        })
      )
    else
      history.push(
        createUrl("/report-booking", { requirement_id: row.requirement_id })
      )
  }

  getBooking = (row) => {
    let { astrologer, my_report_services, report_services } = this.props
    return getBookingServiceDetails(
      row,
      astrologer,
      my_report_services,
      report_services
    )
  }

  renderContent = () => {
    const { classes, row, astrologer, report_booking_status } = this.props
    let { title } = this.getBooking(row)
    let isActive = [2, 3, 4, 5, 6, 7, 8].includes(
      parseInt(row.requirement_status)
    )
    let isPending = parseInt(row.requirement_status) == 1
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            src={getUploadImge(astrologer.image, "user/avatar")}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <Typography className={classes.name}>
            {astrologer.first_name}
          </Typography>
          <Typography color="primary" variant="subheading">
            {title}
          </Typography>
          {row.requirement_join_date && (
            <Typography color="textSecondary" variant="caption">
              {convertMysqlDateTime(row.requirement_join_date, true)}
            </Typography>
          )}
          <Typography
            variant="body1"
            color={isPending ? "error" : isActive ? "secondary" : "textPrimary"}
          >
            {report_booking_status[row.requirement_status]}({" "}
            {row.report_confirm_date
              ? convertMysqlDateTime(row.report_confirm_date, true)
              : `in progress`}{" "}
            )
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    if (!this.props.astrologer) return null
    let { classes } = this.props
    return (
      <Card style={{ marginTop: 5, marginBottom: 5 }}>
        <CardContent>{this.renderContent()}</CardContent>
        <CardActions
          disableActionSpacing
          style={{ justifyContent: "flex-end", paddingTop: 0 }}
          onClick={this.showBooking}
        >
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            onClick={this.showBooking}
          >
            View <ChevronRightIcon />
          </Button>
        </CardActions>
      </Card>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "0 auto",
    maxWidth: 140,
  },
  leftGrid: {
    padding: 10,
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  name: {
    color: "#31708f",
    fontSize: 18,
  },
  actionButton: {
    padding: "0 20px",
  },
})

const mapStateToProps = ({ astrologers, enums }, { row }) => {
  let { astrologer_id } = row
  let astrologer = astrologers.rows[astrologer_id]
  let report_booking_status = enums.rows.report_booking_status.vt
  let my_report_services = enums.rows.my_report_services.raw
  let report_services = enums.rows.report_services.raw
  return {
    astrologer,
    report_booking_status,
    my_report_services,
    report_services,
  }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ReportBookingListRow))
)
