import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import BirthDetailsForm from "./form"
import ConfirmationDialog from "../../../utils/components/confirmation"
import ModalComponent from "../../../utils/components/modal"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import PortraitIcon from "@material-ui/icons/Portrait"
import FaceIcon from "@material-ui/icons/Face"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import DateRangeIcon from "@material-ui/icons/DateRange"

import { removeBDRow, saveBDRowDB } from "../actions"
import { pushMsg } from "../../../navigation/actions"
import { updateRequirementBD } from "../../requirement/actions"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const formatDate = function (date) {
  date = new Date(date)
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  let str = [
    "<span style=''>",
    monthNames[date.getMonth()],
    date.getDate(),
    date.getFullYear(),
    "</span>",
    " - <span style='color:#000'>",
    strTime,
    "</span>",
  ].join(" ")
  return str
}

class BirthDetailsBlock extends Component {
  state = {
    confimrationOpen: false,
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  componentDidMount = () => {
    let { row, auth, isSaved } = this.props
    if (row == null) return
    if (!isSaved && !row.isLoading && auth.isLoggedIn)
      this.props.dispatch(saveBDRowDB(row))
  }

  handleSuccessBDForm = (birth_id) => {
    this.props.dispatch(pushMsg("Birth details updated !", "success"))
    this.setState({ modalOpen: false })
  }

  handleSelect = () => {
    let { canSelectMore, isSelected, requirement_id, birth_id } = this.props
    if (canSelectMore || isSelected)
      this.props.dispatch(updateRequirementBD(requirement_id, birth_id))
    else
      this.props.dispatch(pushMsg("Max allowed birth details added!", "info"))
  }

  renderActions = () => {
    let { canEdit, row } = this.props
    if (!canEdit || row.isLoading) return null
    return (
      <div style={{ width: "100%" }}>
        <Divider
          variant="middle"
          style={{ backgroundColor: this.props.isSelected ? "green" : "#000" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.isSelected}
              onChange={this.handleSelect}
            />
          }
          label="Select"
        />
        <div style={{ float: "right" }}>
          <IconButton
            color="primary"
            onClick={() => this.setState({ modalOpen: true })}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => this.setState({ confimrationOpen: true })}>
            <DeleteIcon />
          </IconButton>
          <ConfirmationDialog
            _setState={this._setState}
            confimrationOpen={this.state.confimrationOpen}
            description="Are you sure to delete this birth details?"
            onSuccess={() => {
              //this.props.dispatch( removeRequirementBD( this.props.birth_id ) )
              this.props.dispatch(removeBDRow(this.props.birth_id))
              this.props.dispatch(pushMsg("Success!", "success"))
            }}
          />
          <ModalComponent
            _setState={this._setState}
            modalOpen={this.state.modalOpen}
            title="Edit birth details"
            content={() => (
              <Card>
                <CardContent>
                  <BirthDetailsForm
                    id={this.props.birth_id}
                    onSuccess={this.handleSuccessBDForm}
                  />
                </CardContent>
              </Card>
            )}
          />
        </div>
      </div>
    )
  }
  render() {
    let { row, classes, isSelected, canEdit } = this.props
    if ((!canEdit && !isSelected) || row == null) return null
    return (
      <div>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {row.birth_gender == 1 ? (
              <PortraitIcon style={{ fontSize: 40 }} />
            ) : (
              <FaceIcon style={{ fontSize: 40 }} />
            )}
            <Typography>{row.birth_gender == 1 ? "Male" : "Female"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.name}>{row.birth_name}</Typography>
            <Typography
              className={classes.city}
              color="textSecondary"
              gutterBottom
            >
              <LocationOnIcon style={{ fontSize: 14, marginRight: 5 }} />
              {row.birth_pob}
            </Typography>
            <Typography
              className={classes.city}
              color="textSecondary"
              gutterBottom
            >
              <DateRangeIcon style={{ fontSize: 14, marginRight: 5 }} />
              <span
                dangerouslySetInnerHTML={{ __html: formatDate(row.birth_dob) }}
              />
            </Typography>
            {this.renderActions()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme) => ({
  name: {
    color: "#408b90",
    fontSize: 18,
  },
  city: {
    fontSize: 14,
    color: "#4c402b",
  },
})

const mapStateToProps = (
  { birthDetails, enums, requirement, auth },
  { birth_id, requirement_id }
) => {
  requirement = requirement.rows[requirement_id]
  let row = birthDetails.rows[birth_id]
  let isSelected = requirement && requirement.requirement_bds.includes(birth_id)
  let isSaved = row && row.birth_id == parseInt(row.birth_id)
  return { row, isSelected, isSaved, auth }
}
export default connect(mapStateToProps)(withStyles(styles)(BirthDetailsBlock))
