import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { withRouter } from "react-router-dom"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import {
  convertMysqlDateTime,
  createUrl,
  _toFixed,
} from "../../../utils/functions"

class WalletListRow extends Component {
  showBooking = () => {
    let { history, row } = this.props
    history.push(
      createUrl("/phonecall/interaction", { phonecall_id: row.phonecall_id })
    )
  }

  renderContent = () => {
    const { classes, row, constants } = this.props
    let positive = row.payment_amount > 0
    let amount = constants.ROAMING
      ? parseFloat(row.payment_amount) / parseFloat(constants.USD_RATE)
      : row.payment_amount
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: positive ? `green` : `orange` }}
          >
            {amount > 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Avatar>
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <Typography
            className={classes.name}
            style={{ color: positive ? `#000` : `#797575` }}
          >
            {_toFixed(amount)} {constants.CURRENCY}
          </Typography>
          <Typography variant="body1" color="primary">
            {row.payment_remarks}
          </Typography>
          <Typography color="textSecondary">
            {convertMysqlDateTime(row.payment_join_date, true)}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <Card style={{ marginTop: 5, marginBottom: 5 }}>
        <CardContent>{this.renderContent()}</CardContent>
      </Card>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  avatar: {
    margin: "0 auto",
    backgroundColor: "#7f48a2",
  },
  leftGrid: {
    padding: 10,
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  name: {
    fontSize: 18,
  },
  actionButton: {
    padding: "0 20px",
  },
})

const mapStateToProps = ({ enums }) => {
  let constants = enums.rows.constants
  return { constants }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(WalletListRow))
)
