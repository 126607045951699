import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
  wrapper: {
    textAlign: "center",
  },
})

function Progress(props) {
  const { classes, loading, ...rest } = props
  if (loading == false) return null
  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.progress} {...rest} />
    </div>
  )
}

Progress.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Progress)
