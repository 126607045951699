import React from "react"
import { Route } from "react-router-dom"
import DefaultComponent from "./default"

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <DefaultComponent {...rest}>
        <Component {...props} />
      </DefaultComponent>
    )}
  />
)

export default Layout
