import { api_base } from "../../config"
import instance from "../instance"
export const guid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  )
}

export const getQueryStringValue = function (key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  )
}

export const timeToHumanReadable = function (a) {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  let year = a.getFullYear()
  let month = months[a.getMonth()]
  let date = a.getDate()
  let hour = a.getHours()
  let min = a.getMinutes()
  let ap = "AM"
  if (hour > 12) {
    hour = hour - 12
    ap = "PM"
  }
  let time = date + " " + month + " " + year + " " + hour + ":" + min + " " + ap
  return time
}

export const convertMysqlDateTime = function (
  mysql_string,
  humanRedable = false
) {
  var t,
    result = null
  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/)
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0)
  }

  if (humanRedable) result = timeToHumanReadable(result)
  return result
}

export const convertToBDTime = function (date) {
  let isoDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString()
  return isoDate.slice(0, -5)
}

export const callDuration = function (time) {
  var hrs = ~~(time / 3600)
  var mins = ~~((time % 3600) / 60)
  var secs = ~~time % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ""

  if (hrs > 0) {
    ret += "" + hrs + " hours :" + (mins < 10 ? "0" : "")
  }

  ret += "" + mins + " minutes :" + (secs < 10 ? "0" : "")
  ret += "" + secs + " seconds"
  return ret
}

const params = function (data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&")
}

export const createUrl = (pathname, search = {}, replace = false) =>
  `${pathname}?${params(search)}`

export const _toFixed = (amount, points = 2) => parseFloat(amount).toFixed(2)

export const getUploadImge = (name, folder) => {
  return `${api_base.replace("index.php", "")}assets/uploads/${folder}/${
    name || "default.png"
  }`
  //return `https://www.monkvyasa.com/assets/uploads/${folder}/${ name || 'default.png' }`
}

export const timeAgo = (time) => {
  switch (typeof time) {
    case "number":
      break
    case "string":
      time = +new Date(time)
      break
    case "object":
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }
  var time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  var seconds = (+new Date() - time) / 1000,
    token = "ago",
    list_choice = 1

  if (seconds === 0) {
    return "Just now"
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = "from now"
    list_choice = 2
  }
  var i = 0,
    format
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice]
      else
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token
    }
  return time
}

export const shuffleArray = (arr) =>
  arr.sort(function (_a, _b) {
    return Math.random() - 0.5
  })

// Function ref from https://stackoverflow.com/a/2450976
export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const prioritizeAstrologers = (astrologers) => {
  let firstList = []
  let secondList = []
  let thirdList = []
  let fourthList = []

  for (let i = 0; i < astrologers.length; i++) {
    const { phone_service, report_service_rate } = astrologers[i]
    const isPhoneAvailable = phone_service.available
    const isReportAvailable = report_service_rate

    if (isPhoneAvailable && isReportAvailable) {
      firstList.push(astrologers[i])
      continue
    } else if (isPhoneAvailable && !isReportAvailable) {
      secondList.push(astrologers[i])
      continue
    } else if (!isPhoneAvailable && isReportAvailable) {
      thirdList.push(astrologers[i])
      continue
    } else {
      fourthList.push(astrologers[i])
    }
  }
  const firstArray = shuffle(firstList)
  const secondArray = shuffle(secondList)
  const thirdArray = shuffle(thirdList)
  const fourthArray = shuffle(fourthList)

  return [...firstArray, ...secondArray, ...thirdArray, ...fourthArray]
}

export { instance }
