import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Switch } from "react-router-dom"
import { connect } from "react-redux"
import { instance } from "../utils/functions"
import Layout from "./layouts"
import Home from "../modules/page/home/"
import NotFound from "../modules/page/notFound"
import Authentication from "../modules/auth"
import AuthChangePassword from "../modules/auth/changePassword"
import LogoutPage from "../modules/auth/logoutPage"
import AccountProfile from "../modules/account"
import AstrologerList from "../modules/astrologer"
import AstrologerProfile from "../modules/astrologer/profile"
import RequirementCreate from "../modules/requirement/create"
import WalletPayment from "../modules/wallet/payment"
import WalletList from "../modules/wallet/list"
import ReportBookingRoot from "../modules/report"
import ReportBookingList from "../modules/requirement/list"
import PhonecallRoot from "../modules/phonecall"
import PhonecallCreate from "../modules/phonecall/create"
import PhonecallList from "../modules/phonecall/list"
import FAQ from "../components/FAQ"
import Terms from "../components/Terms"
import Privacy from "../components/Privacy"
import Contact from "../components/Contact"
import AboutUs from "../components/About"
import ReactGA from "react-ga"
import Chats from "../components/chat"

ReactGA.initialize("UA-55398416-01")

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/profile",
    component: AccountProfile,
    isProtected: true,
  },
  {
    path: "/faq",
    component: FAQ,
  },
  {
    path: "/privacy-policy",
    component: Privacy,
  },
  {
    path: "/terms-and-conditions",
    component: Terms,
  },
  {
    path: "/about",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    component: Contact,
  },
  {
    path: "/astrologers",
    component: AstrologerList,
  },
  {
    path: "/astrologer",
    component: AstrologerProfile,
  },
  {
    path: "/authentication",
    component: Authentication,
  },
  {
    path: "/change-password",
    component: AuthChangePassword,
  },
  {
    path: "/forgot-password",
    component: AuthChangePassword,
  },
  {
    path: "/logout",
    component: LogoutPage,
  },
  {
    path: "/requirement/create",
    component: RequirementCreate,
  },
  {
    path: "/payment",
    component: WalletPayment,
    isProtected: true,
  },
  {
    path: "/wallet",
    component: WalletList,
    isProtected: true,
  },
  {
    path: "/report-booking",
    component: ReportBookingRoot,
    isProtected: true,
  },
  {
    path: "/report-booking-list",
    component: ReportBookingList,
    isProtected: true,
  },
  {
    path: "/phonecall/create",
    component: PhonecallCreate,
    isProtected: true,
  },
  {
    path: "/phonecall/interaction",
    component: PhonecallRoot,
    isProtected: true,
  },
  {
    path: "/phonecall-list",
    component: PhonecallList,
    isProtected: true,
  },
  {
    path: "/chat",
    component: Chats,
    isProtected: true,
  },
  {
    path: "*",
    component: NotFound,
  },
]

const API = `utils/clear_pending_works`

class AppRouter extends Component {
  componentWillMount = () => this.onRouteChanged()

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    let pageviewName =
      window.location.pathname == "/astrologer"
        ? window.location.pathname + window.location.search
        : window.location.pathname
    ReactGA.pageview(pageviewName) // + window.location.search
    let { isLoggedIn } = this.props
    if (isLoggedIn) {
      instance.post(API)
    }
  }

  render() {
    return (
      <Switch>
        {routes.map(({ exact, path, component, ...rest }, idx) => (
          <Layout
            key={idx}
            exact={exact}
            path={path}
            component={component}
            {...rest}
          />
        ))}
      </Switch>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  let { isLoggedIn } = auth
  return { isLoggedIn }
}

export default withRouter(connect(mapStateToProps)(AppRouter))
