import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Slider from "@material-ui/lab/Slider"
import Typography from "@material-ui/core/Typography"

const styles = {
  root: {
    width: 300,
  },
  slider: {
    padding: "22px 0px",
  },
}

class StepSlider extends React.Component {
  state = {
    value: 3,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { classes, value, title, onChange, onEdit } = this.props
    return (
      <div className={classes.root}>
        <Typography variant="subheading" color="secondary">
          {title} - {value || 0}
        </Typography>
        {onEdit && (
          <Slider
            classes={{ container: classes.slider }}
            value={parseInt(value)}
            min={0}
            max={10}
            step={1}
            onChange={onChange}
          />
        )}
      </div>
    )
  }
}

StepSlider.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(StepSlider)
