import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import fields from "../fields"
import ReportRow from "../row"

const row_fields = fields["4"]

class ReportSolutionLifetime extends Component {
  state = {
    isLoading: true,
    rows: this.props.rows || [],
    result: null,
    report: {},
    tabVal: null,
  }

  componentWillMount = () => {
    let { rows } = this.props
    if (rows && rows.length) this.setState({ tabVal: rows[0].lifetime_id })
  }

  renderTabs = () => {
    let { rows } = this.state
    return (
      <Tabs
        value={this.state.tabVal}
        onChange={(e, value) => this.setState({ tabVal: value })}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
      >
        {rows.map((item) => (
          <Tab
            value={item.lifetime_id}
            key={item.lifetime_id}
            label={`${item.lifetime_agefrom} - ${item.lifetime_ageto}`}
          />
        ))}
      </Tabs>
    )
  }

  renderTabContainers = () => {
    let { rows, tabVal } = this.state
    return (
      <React.Fragment>
        {rows.map(
          (item) =>
            tabVal === item.lifetime_id && (
              <React.Fragment key={`${item.lifetime_agefrom}-container`}>
                {Object.keys(row_fields).map((x) => (
                  <ReportRow
                    key={x}
                    title={row_fields[x]}
                    text={item[x] || null}
                  />
                ))}
              </React.Fragment>
            )
        )}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        {this.renderTabs()}
        {this.renderTabContainers()}
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(
  withStyles(styles)(ReportSolutionLifetime)
)
