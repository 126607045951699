import React, { Component, useState } from "react"
import { Grid } from "@material-ui/core"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getQueryStringValue } from "../../utils/functions"
import FormOne from "./components/FormOne"
import FormTwo from "./components/FormTwo"
import FormThree from "./components/FormThree"
import FormFour from "./components/FormFour"
import AstrologerLeftCard from "../../modules/astrologer/components/leftCard"
import LiveChat from "./components/live-chat-modal"
import ChatScreen from "./components/live-chat-modal/ChatScreen"

function ChatForm(props) {
  let initialStep = 1
  const { wallet, chat_rate } = props
  const MIN_MINUTES = 10
  const hasSufficientBalance = chat_rate * MIN_MINUTES <= wallet ? true : false
  if (hasSufficientBalance) initialStep = 2
  const beforeChatMeta = localStorage.getItem("beforeChatMeta")
  if (beforeChatMeta) initialStep = 3
  const [formStep, setFormStep] = useState(initialStep)
  const [chatSession, setChatSession] = useState(false)

  if (chatSession) {
    return (
      <LiveChat
        chatSession={chatSession}
        setChatSession={setChatSession}
        setFormStep={setFormStep}
        content={() => <ChatScreen closeModal={() => setChatSession(false)} />}
      />
    )
  }

  if (formStep === 1) return <FormOne {...props} />

  if (formStep === 2) return <FormTwo setFormStep={setFormStep} {...props} />

  if (formStep === 3)
    return (
      <FormThree
        setChatSession={setChatSession}
        setFormStep={setFormStep}
        {...props}
      />
    )

  return <FormFour {...props} />
}

function CreateChat(props) {
  const { astrologer_id } = props

  return (
    <Grid container spacing={16}>
      <Grid item md={3} xs={12}>
        <AstrologerLeftCard id={astrologer_id} />
      </Grid>
      <Grid item md={9} xs={12}>
        <ChatForm {...props} />
      </Grid>
    </Grid>
  )
}

class CreateChatWrapper extends Component {
  render() {
    return <CreateChat {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const { auth, astrologers, enums } = state
  let { astrologer_id = null, chat_rate = null, closeModal = null } = ownProps
  if (!astrologer_id) {
    astrologer_id = getQueryStringValue("astrologer_id")
  }
  const {
    first_name = "",
    last_name = "",
    phone_service_rate,
  } = astrologers.rows[astrologer_id]
  if (!chat_rate) {
    chat_rate = phone_service_rate
  }
  const { constants } = enums.rows
  const currency = constants.CURRENCY
  const { user } = auth
  const { wallet } = user
  const astrologerName = `${first_name}${last_name}`
  return {
    wallet,
    astrologer_id,
    chat_rate,
    currency,
    user,
    closeModal,
    astrologerName,
  }
}

export default withRouter(connect(mapStateToProps)(CreateChatWrapper))
