import React, { Component } from "react"
import { connect } from "react-redux"

import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import { FormResult } from "../../../utils/form"
import { getQueryStringValue } from "../../../utils/functions"
import { Progress } from "../../../utils/components"
import { EmptyCard } from "../../../utils/components/misc"
import { pushMsg } from "../../../navigation/actions"
import { fetchReqGroupDB } from "../actions"
import { getBookingServiceDetails } from "../functions"
import AstrologerLeftCard from "../../astrologer/components/leftCard"
import RequirementBlock from "../block"
import RequirementPayment from "./payment"
import EditIcon from "@material-ui/icons/Edit"

class RequirementCreate extends Component {
  state = {
    error: null,
    onEdit: getQueryStringValue("requirement_added") ? false : true,
    total: 0,
  }

  componentWillMount = () => {
    let {
      isLoggedIn,
      requirement_list,
      requirement_group_ref,
      dispatch,
    } = this.props
    if (isLoggedIn && !requirement_list.length)
      dispatch(fetchReqGroupDB(requirement_group_ref))
  }

  getBooking = (row) => {
    let { astrologer, my_report_services, report_services } = this.props
    return getBookingServiceDetails(
      row,
      astrologer,
      my_report_services,
      report_services
    )
  }

  renderBlocks = () => {
    let { requirement_list } = this.props
    let rows = requirement_list.map((row, idx) => {
      let { title } = this.getBooking(row)
      return (
        <div key={idx} style={{ marginBottom: 20 }}>
          <Typography
            color="primary"
            variant="subheading"
            style={{ margin: 10 }}
          >
            {title}
          </Typography>

          <RequirementBlock
            requirement_id={row.requirement_id}
            onEdit={this.state.onEdit}
          />
        </div>
      )
    })
    return <div>{rows}</div>
  }

  handleSaveRequirement = () => {
    this.setState({ error: null })
    let { requirement_list } = this.props
    let error = {}
    requirement_list.map((row) => {
      let { bds_min, title } = this.getBooking(row)
      let canSelectMore = bds_min > row.requirement_bds.length
      if (canSelectMore) {
        error[
          `${row.requirement_id}-bd`
        ] = `Need to select ${bds_min} birth details for ${title}`
      }
      if (!row.requirement_requirement || row.requirement_requirement == "") {
        error[
          `${row.requirement_id}-req`
        ] = `Please add requirement for ${title}`
      }
    })

    if (Object.values(error).length) {
      this.setState({ error })
      return
    }

    this.setState({ onEdit: false })
    this.props.dispatch(pushMsg("Success", "success"))
  }

  renderButtons = () => {
    let { classes, isLoggedIn, bdsSaved } = this.props
    let { error, onEdit } = this.state
    if (onEdit)
      return (
        <React.Fragment>
          <FormResult result={{ error }} />
          <Button
            className={classes.proceedButton}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={this.handleSaveRequirement}
          >
            Save requirement
          </Button>
        </React.Fragment>
      )
    return (
      <React.Fragment>
        <Button
          fullWidth
          variant="text"
          onClick={() => this.setState({ onEdit: true })}
          style={{ color: "blue", textAlign: "right" }}
        >
          <EditIcon />
          Edit Booking
        </Button>
        {isLoggedIn}
        {(!isLoggedIn || bdsSaved) && (
          <RequirementPayment getBooking={this.getBooking} {...this.props} />
        )}
      </React.Fragment>
    )
  }

  renderContent = () => {
    let { classes, astrologer } = this.props

    return (
      <Grid container spacing={16} className={classes.content}>
        <Grid item md={4} xs={12}>
          <AstrologerLeftCard id={astrologer.id} />
        </Grid>
        <Grid item md={8} xs={12}>
          {this.renderBlocks()}
          {this.renderButtons()}
        </Grid>
      </Grid>
    )
  }

  render = () => {
    let { listIsLoading, requirement_list } = this.props
    if (listIsLoading && !requirement_list.length)
      return <Progress loading={true} />
    if (!requirement_list.length) return <EmptyCard msg="No bookings found !" />
    return this.renderContent()
  }
}

const styles = () => ({
  content: {},
  proceedButton: {
    marginTop: 10,
    color: "#fff",
    //backgroundColor : '#37be5f'
  },
  details: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    color: "green",
  },
})

const mapStateToProps = ({ requirement, astrologers, enums, auth }) => {
  let requirement_group_ref = getQueryStringValue("requirement_group_ref")

  let requirement_list = Object.values(requirement.rows).filter(
    (x) => x.requirement_group_ref == requirement_group_ref
  )
  let my_report_services = enums.rows.my_report_services.raw
  let report_services = enums.rows.report_services.raw
  let isLoggedIn = auth.isLoggedIn

  let listIsLoading = requirement.isLoading && isLoggedIn

  let reportIsLoading = false
  let bdsSaved = true
  let astrologer = null

  requirement_list.map((row) => {
    if (row.isLoading && !reportIsLoading) reportIsLoading = true
    if (row.requirement_bds.find((x) => x != parseInt(x)) && bdsSaved)
      bdsSaved = false
    astrologer = astrologers.rows[row.astrologer_id]
  })

  return {
    requirement_group_ref,
    enums,
    requirement_list,
    my_report_services,
    report_services,
    isLoggedIn,
    listIsLoading,
    reportIsLoading,
    bdsSaved,
    astrologer,
  }
}
export default connect(mapStateToProps)(withStyles(styles)(RequirementCreate))
