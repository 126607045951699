import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withStyles, Button, Icon } from "@material-ui/core"
import { pushMsg } from "../../navigation/actions"
import { createUrl } from "../../utils/functions"
import ModalComponent from "./components/modal"
import CreateChat from "./"
import ForumIcon from "@material-ui/icons/Forum"

class ReportBookingButton extends React.Component {
  state = {
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  handleClickOpen = () => {
    const { auth, isAvailbale } = this.props
    if (!isAvailbale) {
      const { dispatch, row } = this.props
      const { first_name } = row
      dispatch(pushMsg(`Sorry ${first_name} is not available now`))
    } else if (!auth.isLoggedIn) {
      const { history } = this.props
      const redirect = createUrl("/astrologers")
      history.push(createUrl(`/authentication`, { redirect }))
    } else {
      this.setState({ modalOpen: true })
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { isAvailbale } = this.props
    return (
      <React.Fragment>
        <Button
          size="small"
          variant="contained"
          className={this.props.classes.button}
          onClick={this.handleClickOpen}
          style={{ opacity: isAvailbale ? 1 : 0.5 }}
        >
          <ForumIcon className={this.props.classes.leftIcon} />
          Chat
        </Button>
        <ModalComponent
          _setState={this._setState}
          modalOpen={this.state.modalOpen}
          title="Live Chat"
          content={() => (
            <CreateChat
              astrologer_id={this.props.id}
              auth={this.props.auth}
              chat_rate={this.props.chat_rate}
              closeModal={this.handleClose}
            />
          )}
        />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "hidden",
  },
  flex: {
    flex: 1,
  },
  leftIcon: {
    marginRight: 10,
    fontSize: 14,
  },
  button: {
    background: "#7b1fa2",
    color: "#fff",
    "&:hover": {
      background: "#7b1fa2",
    },
    borderRadius: 0,
    paddingRight: 10,
    width: "90%",
    margin: theme.spacing.unit,
  },
})

ReportBookingButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { id, chat_rate } = ownProps
  const { astrologers, auth } = state
  const row = astrologers.rows[id]
  const phone_service = row.phone_service
  const { phone_service_rate, available } = phone_service
  const isAvailbale = parseFloat(phone_service_rate) > 0 && available
  return { isAvailbale, row, auth, id, chat_rate }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ReportBookingButton))
)
