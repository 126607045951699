let initialState = {
  isLoading: false,
  rows: {},
  total: 0,
  ids: [],
}

let rows, row, id, ids, rowId, birth_id, newRows
const requirementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PUSH_REPORT_BOOKING":
      row = action.payload.row
      id = `${row.requirement_id}`
      return {
        ...state,
        rows: {
          ...state.rows,
          [id]: row,
        },
        ids: [...state.ids, id],
      }
    case "REMOVE_REQUIREMENT_ROW":
      id = action.payload
      let { [id]: _, ...rest } = state.rows
      return {
        ...state,
        rows: rest,
        ids: state.ids.filter((x) => x !== id),
      }

    case "FETCH_REQUIREMENT_PENDING":
    case "FETCH_REQUIREMENT_GROUP_PENDING":
      return {
        ...state,
        isLoading: true,
      }
    case "FETCH_REQUIREMENT_FULFILLED":
    case "FETCH_REQUIREMENT_GROUP_FULFILLED":
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_REQUIREMENT":
      rowId = action.payload.rowId
      row = action.payload.row
      return {
        ...state,
        rows: {
          ...state.rows,
          [rowId]: row,
        },
      }

    case "UPDATE_REQUIREMENT_COLUMN":
      rowId = action.payload.rowId
      let { value, field } = action.payload
      return {
        ...state,
        rows: {
          ...state.rows,
          [rowId]: {
            ...state.rows[rowId],
            [field]: value,
          },
        },
      }
    case "UPDATE_REQUIREMENT_ID":
      let { id_new, id_old } = action.payload
      let newRow = { ...state.rows[id_old] }
      newRow.isLoading = false
      newRow.requirement_id = id_new
      let { [id_old]: x, ...newRows } = state.rows
      ids = state.ids.filter((x) => x !== id_old)
      ids.push(id_new)
      return {
        ...state,
        rows: {
          ...newRows,
          [id_new]: newRow,
        },
        ids,
      }
    case "CLEAR_REQUIREMENT_NOT_SAVED":
      return {
        ...state,
        rows: {},
        ids: [],
      }
    case "UPDATE_REQUIREMENT_BD":
      rowId = action.payload.rowId
      birth_id = action.payload.birth_id
      row = { ...state.rows[rowId] }
      if (!row.requirement_bds.includes(birth_id))
        row.requirement_bds.push(birth_id)
      else
        row.requirement_bds = row.requirement_bds.filter(
          (item) => item !== birth_id
        )

      return {
        ...state,
        rows: {
          ...state.rows,
          [rowId]: row,
        },
      }
    case "REMOVE_REQUIREMENT_BD":
      birth_id = action.payload.birth_id
      rows = { ...state.rows }
      newRows = {}
      Object.values(rows).map((x) => {
        row = { ...x }
        if (row.requirement_bds.includes(birth_id))
          row.requirement_bds = row.requirement_bds.filter(
            (item) => item !== birth_id
          )
        newRows[row.requirement_id] = row
      })
      return {
        ...state,
        rows: newRows,
      }
    case "REPLACE_REQUIREMENT_BD":
      let { birth_id_new, birth_id_old } = action.payload
      rows = { ...state.rows }
      newRows = {}
      Object.values(rows).map((x) => {
        row = { ...x }
        if (row.requirement_bds.includes(birth_id_old)) {
          row.requirement_bds = row.requirement_bds.filter(
            (item) => item !== birth_id_old
          )
          row.requirement_bds.push(birth_id_new)
        }
        newRows[row.requirement_id] = row
      })
      return {
        ...state,
        rows: newRows,
      }

    default:
      return state
  }
}

export default requirementReducer
