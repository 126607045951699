import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import fields from "../../../report/solution/fields"

const loremIspsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `
const loremIspsumSmall = `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium`

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

class ReportRow extends Component {
  render() {
    let { title, text, classes } = this.props
    return (
      <List
        style={{
          width: "100%",
          borderBottom: "1px solid #efe7f5",
        }}
      >
        <ListItem alignItems="flex-start">
          <ListItemText secondary={text || `Not added!`} primary={title} />
        </ListItem>
      </List>
    )
  }
}

class ReportSolution extends Component {
  state = {
    row_fields: fields[this.props.serviceSelected.val],
    tabVal: 2,
  }

  componentWillMount = () => {
    var d = new Date()
    let row = { year_year: d.getFullYear(), year_month_: d.getMonth() + 1 }
    this.setState({ row })
  }

  renderYearReport = () => {
    let { row } = this.state
    let { year_month_, year_year } = row
    let rows = []
    for (var i = 1; i <= 12; i++) {
      if (year_month_ >= 12) {
        year_month_ = 0
        year_year++
      }
      rows.push(
        <ReportRow
          key={i}
          title={`${monthNames[year_month_]} - ${year_year}`}
          text={loremIspsum}
        />
      )
      year_month_++
    }
    rows.push(
      <ReportRow key={i++} title={`Conclusion`} text={loremIspsumSmall} />
    )
    return <React.Fragment>{rows}</React.Fragment>
  }

  renderTabs = () => {
    return (
      <Tabs
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
        value={this.state.tabVal}
        onChange={(e, value) => this.setState({ tabVal: value })}
      >
        {[2, 3].map((item) => (
          <Tab
            value={item}
            key={item}
            label={`AGE (${item * 15} - ${parseInt(item * 15) + 20})`}
          />
        ))}
      </Tabs>
    )
  }

  render() {
    let { classes, serviceSelected } = this.props
    let { row_fields } = this.state

    if (serviceSelected.val == 3) return this.renderYearReport()

    return (
      <React.Fragment>
        {serviceSelected.val == 4 && this.renderTabs()}
        {Object.keys(row_fields).map((k) => {
          if (k === "horoscope_match_score")
            return (
              <Typography
                variant="subheading"
                color="secondary"
                key={k}
                align="center"
              >
                Match Score : 7/10
              </Typography>
            )
          else
            return (
              <ReportRow key={k} title={row_fields[k]} text={loremIspsum} />
            )
        })}
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #efe7f5",
  },
  inline: {
    display: "inline",
  },
})

export default withStyles(styles)(ReportSolution)
