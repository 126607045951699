const getBookingServiceDetails = (
  row,
  astrologer,
  my_report_services,
  report_services
) => {
  const custom_services = astrologer.custom_services
  let title, bds_min, bds_mx, service_rate
  if (row.acs_id > 0) {
    const custom_service = custom_services.find(
      (service) => service.acs_id === row.acs_id
    )
    title = custom_service.acs_name
    bds_min = custom_service.acs_bd_min
    bds_mx = custom_service.acs_bd_max
    service_rate = custom_service.acs_service_rate
  } else {
    let service_selected = Object.values(report_services).find(
      (x) => x.val == row.mr_service_id
    )
    let service_selected_astrologer = Object.values(
      astrologer.report_services
    ).find((x) => x.mr_service_id == row.mr_service_id)
    if (
      !service_selected_astrologer ||
      !service_selected ||
      service_selected == undefined ||
      service_selected_astrologer == undefined
    ) {
      return { title: `-NA-`, bds_mx: 1, bds_min: 1, service_rate: 0 }
    }
    service_rate = parseFloat(service_selected_astrologer.mr_service_rate)
    title = service_selected.text
    if (row.my_report_id > 0) {
      let service_selected_mr = Object.values(my_report_services).find(
        (x) => x.val == row.my_report_id
      )
      if (service_selected_mr) {
        service_selected = service_selected_mr
        title += ` - ${service_selected.text}`
      }
    }
    bds_mx = service_selected.bds_mx
    bds_min = service_selected.bds_min
  }
  return { bds_mx, bds_min, title, service_rate }
}

export { getBookingServiceDetails }
