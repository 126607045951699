import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import {
  withStyles,
  Avatar,
  TextField,
  Paper,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Input,
  Grid,
} from "@material-ui/core"
import LockIcon from "@material-ui/icons/LockOutlined"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import EditIcon from "@material-ui/icons/Edit"
import { fetchAuth } from "./actions"
import { FormButton, FormResult } from "../../utils/form"
import { instance } from "../../utils"
import { getQueryStringValue } from "../../utils/functions"
import PhoneInput from "../../utils/components/misc/phoneInput"
import OtpComponent from "../../utils/components/misc/otpComponent"
import { pushMsg } from "../../navigation/actions"

const CHECK_PHONE_API = `utils/check_phone_exists`
const CHECK_LOGIN_API = `auth/check_submit`
const REGISTRATION_API = `user/user_registration/register`

class Authentication extends Component {
  state = {
    step: 1,
    isLoading: false,
    showPassword: false,
    country_code: 91,
    phone: "",
    password: "",
    first_name: "",
    otp: "",
  }

  componentWillMount = () => this.checkRedirect()

  componentDidUpdate = (prevProps) => {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.checkRedirect()
    }
  }

  handleChange = (name) => (event) => {
    let user = Object.assign({}, this.state.user)
    user[name] = event.target.value
    this.setState({ [name]: event.target.value })
  }

  checkRedirect = () => {
    const redirect = getQueryStringValue("redirect") || "/"
    if (this.props.isLoggedIn) {
      this.props.history.replace(redirect)
    }
  }

  checkPhone = () => {
    this.setState({ isLoading: true, result: null })
    const { phone, country_code } = this.state
    instance.post(CHECK_PHONE_API, { phone, country_code }).then((result) => {
      this.setState({ isLoading: false })
      const { res, exists } = result
      if (res !== 1) {
        this.setState({ result })
        return
      }
      const step = exists ? 2 : 3
      this.setState({ step })
    })
  }

  checkLogin = () => {
    this.setState({ isLoading: true, result: null })
    const { dispatch } = this.props
    instance
      .post(CHECK_LOGIN_API, {
        phone: this.state.phone,
        password: this.state.password,
      })
      .then((result) => {
        const newState = { isLoading: false }
        if (result.res === 1) {
          dispatch(pushMsg("Logged In", "success"))
          this.props.dispatch(fetchAuth())
        } else newState.result = result
        this.setState(newState)
      })
  }

  register = () => {
    this.setState({ isLoading: true, result: null })
    const { first_name, country_code, phone, password, otp } = this.state
    const { dispatch } = this.props
    instance
      .post(REGISTRATION_API, {
        first_name,
        country_code,
        phone,
        password,
        otp,
      })
      .then((result) => {
        const newState = { isLoading: false }
        if (result.res === 1) {
          dispatch(pushMsg(result.msg, "success"))
          this.props.dispatch(fetchAuth())
        } else newState.result = result
        this.setState(newState)
      })
  }

  renderPhone = () => {
    const { phone, country_code } = this.state

    return (
      <PhoneInput
        onCountryChange={this.handleChange("country_code")}
        onPhoneChange={this.handleChange("phone")}
        phone={phone}
        country_code={country_code}
      />
    )
  }

  renderPhoneDisplay = () => {
    const { phone, country_code } = this.state

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={10}>
          <Typography
            color="textSecondary"
            variant="title"
            align="center"
          >{`+${country_code}-${phone}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => this.setState({ step: 1 })}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  renderPassword = () => (
    <Input
      fullWidth
      style={{ marginTop: 20 }}
      placeholder="Enter your password"
      type={this.state.showPassword ? "text" : "password"}
      value={this.state.password}
      onChange={(event) => this.setState({ password: event.target.value })}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() =>
              this.setState((state) => ({ showPassword: !state.showPassword }))
            }
          >
            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  )

  title = () => {
    return this.state.step === 1
      ? "Authentication"
      : this.state.step === 2
      ? "Login"
      : "Registration"
  }

  renderTextField = (name, label) => {
    const { classes } = this.props

    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={this.state[name] || ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { step } = this.state
    if (step === 1) this.checkPhone()
    if (step === 2) this.checkLogin()
    if (step === 3) this.register()
  }

  render() {
    const { classes } = this.props
    const { isLoading, result, country_code, phone, step } = this.state

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="title">{this.title()}</Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            {step === 1 && this.renderPhone()}
            {step !== 1 && this.renderPhoneDisplay()}
            {step === 3 && this.renderTextField("otp", "OTP")}
            {step === 3 && (
              <OtpComponent country_code={country_code} phone={phone} />
            )}
            {step === 3 && this.renderTextField("first_name", "Name")}
            {step !== 1 && this.renderPassword()}

            <FormResult result={result} />
            <FormButton
              label="Submit"
              fullWidth
              loading={isLoading}
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
            />
            {(step === 1 || step === 2) && (
              <Button
                variant="text"
                color="secondary"
                align="center"
                size="small"
                fullWidth
                onClick={() => this.props.history.push("/forgot-password")}
              >
                Forgot Password
              </Button>
            )}
          </form>
        </Paper>
      </main>
    )
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  editPhoneButton: {
    float: "right",
    margin: 0,
  },
})

const mapStateToProps = ({ auth }) => {
  const { isLoggedIn } = auth
  return { isLoggedIn }
}

Authentication.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(Authentication))
)
