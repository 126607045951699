let initialState = {
  isLoading: false,
  row: {},
}

let rows, row, id, ids, rowId, birth_id, newRows, newRow
const phonecallReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_PHONECALL":
      row = action.payload.row
      newRow = Object.assign({}, row)
      return {
        ...state,
        row: newRow,
      }
    case "UPDATE_PHONECALL_COLUMN":
      let { value, field } = action.payload
      return {
        ...state,
        row: {
          ...state.row,
          [field]: value,
        },
      }
    case "UPDATE_PHONECALL_ROW":
      rowId = action.payload.rowId
      row = action.payload.row
      return {
        ...state,
        rows: {
          ...state.rows,
          [rowId]: row,
        },
      }
    default:
      return state
  }
}

export default phonecallReducer
