import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core"

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ModalComponent extends React.Component {
  state = {
    modalOpen: false,
    dialogOpen: false,
  }

  handleClickOpen = () => {
    this.props._setState({ modalOpen: true })
  }

  handleClose = () => {
    const chatRoomInfo = JSON.parse(sessionStorage.getItem("chatRoomInfo"))
    if (chatRoomInfo) {
      this.setState({ dialogOpen: true })
    } else {
      this.props._setState({ modalOpen: false })
    }
  }

  handleEndChat = () => {
    this.setState({ dialogOpen: false })
    this.props._setState({ modalOpen: false })
  }

  renderContent = () => null

  render() {
    const { classes, content } = this.props
    return (
      <div>
        <Dialog
          fullScreen
          open={this.props.modalOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography
                variant="subtitle1"
                color="inherit"
                className={classes.flex}
              >
                {this.props.title || `update`}
              </Typography>
              <IconButton color="inherit" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <div className={classes.content}>{content && content()}</div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
        >
          <DialogContent>
            <DialogContentText>
              Your chat session may end, Do you want to exit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogOpen: false })}>
              Cancel
            </Button>
            <Button onClick={this.handleEndChat} color="primary" autoFocus>
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    background: "#f8f8f8",
    overflowX: "hidden",
    padding: theme.spacing.unit,
    minHeight: "92vh",
  },
  flex: {
    flex: 1,
  },
  content: {
    maxWidth: 1200,
    margin: "0 auto",
  },
})

ModalComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ModalComponent)
