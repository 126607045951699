export const createPhonecall = (astrologer_id) => (dispatch, getState) => {
  let user = getState().auth.user
  let { country_code, phone } = user
  country_code = country_code > 0 ? country_code : 91
  let row = {
    astrologer_id,
    country_code: country_code,
    call_phone_number: phone,
  }
  return dispatch({
    type: "CREATE_PHONECALL",
    payload: { row },
  })
}

export const updatePhoncallColumn = (field, value) => (dispatch) => {
  return dispatch({
    type: "UPDATE_PHONECALL_COLUMN",
    payload: { field, value },
  })
}

export const updatePhoncallRow = (rowId, row) => (dispatch) => {
  return dispatch({
    type: "UPDATE_PHONECALL_ROW",
    payload: { rowId, row },
  })
}
