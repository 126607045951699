import React, { useEffect, useState } from "react"
import { apiv2Instance } from "../../../utils"
import { Typography, CircularProgress } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"

function ChatScreen(props) {
  const { userId, chatRoomId } = props
  const [hasMore, setHasMore] = useState(false)
  const [nextPageRef, setNextPageRef] = useState(1)
  const [lastMessageRef, setLastMessageRef] = useState(null)
  const [messages, setMessages] = useState([])

  async function loadMoreChats() {
    const { data } = await apiv2Instance.get(
      `chat?chatRoomRef=${chatRoomId}&lastMessageId=${lastMessageRef}&page=${nextPageRef}`
    )
    const { chats, hasMore, nextPage } = data
    const { _id } = chats[chats.length - 1]
    setMessages([...messages, ...chats])
    setHasMore(hasMore)
    setNextPageRef(nextPage)
    setLastMessageRef(_id)
  }

  useEffect(() => {
    async function getChats() {
      const { data } = await apiv2Instance.get(`chat?chatRoomRef=${chatRoomId}`)
      const { chats, hasMore, nextPage } = data
      const { _id } = chats[chats.length - 1]
      setMessages([...messages, ...chats])
      setHasMore(hasMore)
      setNextPageRef(nextPage)
      setLastMessageRef(_id)
    }
    getChats()
  }, [])

  function Message(props) {
    const { body, from, sentAt } = props.message
    const parsedDate = Date.parse(sentAt)
    const timestamp = new Date(parsedDate)

    return (
      <div
        style={{
          whiteSpace: "pre-wrap",
          padding: "10px",
          borderRadius: "4px",
          maxWidth: "90%",
          marginTop: "10px",
          marginLeft: from === userId ? "auto" : null,
          marginRight: from !== userId ? "auto" : null,
          backgroundColor: from === userId ? "#7b1fa2" : "#dedede",
        }}
      >
        <Typography
          style={{
            color: from === userId ? "white" : null,
          }}
        >
          {body}
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: from === userId ? "#c9c9c9" : null,
            textAlign: from === userId ? "right" : null,
          }}
        >
          {`${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString()}`}
        </Typography>
      </div>
    )
  }

  return (
    <InfiniteScroll
      dataLength={messages.length}
      next={loadMoreChats}
      style={{ display: "flex", flexDirection: "column-reverse" }}
      inverse={true}
      hasMore={hasMore}
      loader={
        <CircularProgress style={{ margin: "0 auto", marginTop: "20px" }} />
      }
      scrollableTarget="scrollable"
      scrollThreshold={0.8}
    >
      {messages.map((message) => (
        <Message key={message._id} message={message} />
      ))}
    </InfiniteScroll>
  )
}

export default ChatScreen
