import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import ModalComponent from "../../../../utils/components/modal"
import BirthDetailsBlock from "./birthDetailsBlock"
import ChatRow from "./chatRow"
import ReportSolution from "./solution"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

const loremIspsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `
const loremIspsumSmall = `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium`

const user = {
  first_name: "USER",
}

const astrologer = {
  first_name: "ASTROLOGER",
}

const bd1 = {
  birth_gender: 1,
  birth_name: "Your Name",
  birth_pob: "Your Location",
  birth_dob: "Birth Time",
}

const bd2 = {
  birth_gender: 2,
  birth_name: "Your Partner Name",
  birth_pob: "Your Partner Location",
  birth_dob: "Birth Time",
}

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
})

class CardWarpper extends React.Component {
  render() {
    return (
      <Card>
        <CardContent>{this.props.children}</CardContent>
      </Card>
    )
  }
}

class RequirementSample extends React.Component {
  state = {
    expand: false,
    modalOpen: false,
    serviceSelected: false,
  }

  _setState = (newState) => this.setState(newState)

  sectionHeader = (text, color) => (
    <Typography
      color={color || `primary`}
      variant="subheading"
      style={{ margin: 10 }}
    >
      {text}
    </Typography>
  )

  renderSample = () => {
    let { serviceSelected } = this.state
    return (
      <div>
        {this.sectionHeader("Requirement")}
        <CardWarpper>
          <BirthDetailsBlock row={bd1} />
          {serviceSelected.bds_min > 1 && (
            <React.Fragment>
              <Divider style={{ margin: 10 }} />
              <BirthDetailsBlock row={bd2} />
            </React.Fragment>
          )}
          <Divider style={{ margin: 10 }} />
          <Typography variant="caption">
            YOUR QUERY GOES HERE - {loremIspsum}
          </Typography>
        </CardWarpper>
        {this.sectionHeader("Discussion on Requirement", "secondary")}
        <CardWarpper>
          <ChatRow user={astrologer} msg={loremIspsumSmall} timeAgo={55} />
          <ChatRow user={astrologer} msg={loremIspsum} timeAgo={32} isUser />
        </CardWarpper>
        {this.sectionHeader("Report")}
        <CardWarpper>
          <ReportSolution serviceSelected={serviceSelected} />
        </CardWarpper>
        {this.sectionHeader("Discussion on Report", "secondary")}
        <CardWarpper>
          <ChatRow user={astrologer} msg={loremIspsum} timeAgo={12} isUser />
          <ChatRow user={astrologer} msg={loremIspsumSmall} timeAgo={5} />
          <ChatRow
            user={astrologer}
            msg={loremIspsumSmall}
            timeAgo={2}
            isUser
          />
          <ChatRow user={astrologer} msg={loremIspsumSmall} timeAgo={1} />
        </CardWarpper>
      </div>
    )
  }

  showModal = (serviceSelected) =>
    this.setState({ serviceSelected, modalOpen: true })

  renderModal = () => (
    <ModalComponent
      _setState={this._setState}
      modalOpen={this.state.modalOpen}
      title={`${this.state.serviceSelected.text} - Sample `}
      content={() => this.renderSample()}
    />
  )

  render() {
    let { classes, report_services } = this.props
    let { expand, modalOpen, serviceSelected } = this.state
    return (
      <React.Fragment>
        <List className={classes.root} dense>
          <ListItem
            selected={expand}
            onClick={() => this.setState({ expand: !expand })}
          >
            <ListItemText primary="View sample report" />
            <ListItemSecondaryAction
              onClick={() => this.setState({ expand: !expand })}
            >
              {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemSecondaryAction>
          </ListItem>
          {expand &&
            Object.keys(report_services).map((x) => (
              <ListItem
                key={x}
                onClick={() => this.showModal(report_services[x])}
                divider
              >
                <ListItemText primary={report_services[x].text} />
                <ListItemSecondaryAction
                  onClick={() => this.showModal(report_services[x])}
                >
                  <ChevronRightIcon />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        {serviceSelected && this.renderModal()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ astrologers, enums }, { id }) => {
  return {
    report_services: enums.rows.report_services.raw,
    my_report_services: enums.rows.my_report_services.raw,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(RequirementSample))
)
