import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import EditIcon from "@material-ui/icons/Edit"

import PhoneInput from "../../../utils/components/misc/phoneInput"
import { FormResult, FormButton } from "../../../utils/form"
import { instance } from "../../../utils"
import { pushMsg } from "../../../navigation/actions"
import { refreshAuth } from "../../auth/actions"

const API = `user/user_profile/update_basic`
const API_CITY_FETCH = `utils/cities_json`

class ProfileBasic extends Component {
  state = {
    user: this.props.user,
    cities: {},
    isLoading: false,
    result: null,
    onEdit: false,
  }

  componentWillMount = () => this.fetchCity()

  handleSubmit = () => {
    this.setState({ isLoading: true, result: null })
    let {
      phone,
      country_code,
      first_name,
      last_name,
      email,
      city,
    } = this.state.user
    let { dispatch, history } = this.props
    let data = { phone, country_code, first_name, last_name, email, city }
    instance.post(API, data).then((result) => {
      this.setState({ isLoading: false })

      let { res, msg } = result
      if (res != 1) {
        this.setState({ result })
        return
      }
      dispatch(pushMsg(msg, "success"))
      dispatch(refreshAuth())
      this.setState({ onEdit: false })
    })
  }

  fetchCity = () => {
    instance({
      url: API_CITY_FETCH,
    }).then((cities) => {
      this.setState({ cities })
    })
  }

  handleChange = (name) => (event) =>
    this.changeUserState(name, event.target.value)

  changeUserState = (key, val) => {
    let user = Object.assign({}, this.state.user)
    user[key] = val
    this.setState({ user })
  }

  renderRow = (label, val) => {
    return (
      <React.Fragment key={label}>
        <Typography variant="caption">{label}</Typography>
        <Typography variant="subtitle1">{val || `-NA-`}</Typography>
        <Divider style={{ margin: "10px 0" }} />
      </React.Fragment>
    )
  }

  renderTextField = (name, label) => {
    let { classes } = this.props
    let { user } = this.state
    let val = user[name] != null ? user[name] : ``
    if (!this.state.onEdit) return this.renderRow(label, val)
    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={val}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  renderPhone = () => {
    let { phone, country_code } = this.state.user
    if (!this.state.onEdit)
      return this.renderRow(`phone`, `+${country_code} ${phone}`)
    return (
      <PhoneInput
        className={this.props.classes.textField}
        onCountryChange={this.handleChange("country_code")}
        onPhoneChange={this.handleChange("phone")}
        phone={phone}
        country_code={country_code}
      />
    )
  }

  render() {
    let { result, isLoading, user, onEdit } = this.state
    const { classes } = this.props
    return (
      <React.Fragment>
        <Typography variant="h6" color="primary" style={{ margin: 10 }}>
          Personal Information
        </Typography>
        <Card>
          <CardContent>
            {this.renderTextField("first_name", "First Name")}
            {this.renderTextField("last_name", "Last Name")}
            {this.renderTextField("email", "Email")}
            {this.renderPhone()}
            {!onEdit && (
              <Button
                variant="text"
                onClick={() => this.setState({ onEdit: true })}
                fullWidth
                color="primary"
              >
                <EditIcon fontSize="small" style={{ marginRight: 10 }} />
                Edit
              </Button>
            )}
            {onEdit && (
              <React.Fragment>
                <FormResult result={result} />
                <FormButton
                  loading={isLoading}
                  label="Save"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={this.handleSubmit}
                  className={classes.submitButton}
                />
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  textField: {
    marginTop: theme.spacing.unit,
  },
  submitButton: {
    maxWidth: 600,
    margin: 0,
  },
})

const mapStateToProps = ({ auth, enums }, ownProps) => {
  let { user } = auth
  return { user }
}
export default connect(mapStateToProps)(withStyles(styles)(ProfileBasic))
