import React, { Component } from "react"
import { connect } from "react-redux"
import { Typography } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { Progress } from "../../../utils/components"
import { instance } from "../../../utils"
import { FormResult } from "../../../utils/form"
import { pushMsg } from "../../../navigation/actions"

const DECODE_PAYMENT_API = `wallet/transaction/decode_payment`

class WalletPaymentDecoder extends Component {
  state = {
    isLoading: true,
    result: null,
    trn_status: null,
  }

  componentDidMount = () => this.fetchTransaction()

  fetchTransaction = () => {
    let { redirect } = this.props
    const { trn_id, dispatch, history, constants, rzparams } = this.props
    const {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
    } = rzparams
    instance
      .post(DECODE_PAYMENT_API, {
        trn_id,
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
      })
      .then((result) => {
        if (result.res !== 1) {
          this.setState({ isLoading: false, result })
          dispatch(pushMsg(`Payment not Success`, `error`))
        } else {
          const { trn_amount } = result
          const gtag_report_conversion = window.gtag_report_conversion
          gtag_report_conversion &&
            gtag_report_conversion(
              trn_amount,
              constants.CURRENCY,
              `${trn_id}-success`
            )
          dispatch(pushMsg(`Payment Success`, `success`))
          redirect = redirect ? `${redirect}` : `/wallet`
          history.replace(redirect)
        }
      })
  }

  renderLoader() {
    return (
      <div style={{ textAlign: "center" }}>
        <Typography color="textSecondary" variant="title">
          Please don't refresh page.
        </Typography>
        <Typography color="secondary" variant="caption">
          Payment in Progress
        </Typography>
        <Progress loading="true" />
      </div>
    )
  }

  render() {
    const { result, isLoading } = this.state

    return (
      <div>
        {isLoading && this.renderLoader()}
        <FormResult result={result} />
      </div>
    )
  }
}

const mapStateToProps = ({ auth, enums }) => {
  const constants = enums.rows.constants
  return { auth, constants }
}

export default withRouter(connect(mapStateToProps)(WalletPaymentDecoder))
