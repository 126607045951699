import React, { Component } from "react"
import { connect } from "react-redux"
import {
  withStyles,
  Typography,
  TextField,
  Input,
  InputAdornment,
  IconButton,
  Paper,
  Avatar,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import LockIcon from "@material-ui/icons/LockOutlined"
import { FormButton, FormResult } from "../../utils/form"
import { instance } from "../../utils"
import { pushMsg } from "../../navigation/actions"
import PhoneInput from "../../utils/components/misc/phoneInput"
import OtpComponent from "../../utils/components/misc/otpComponent"
const CHECK_PHONE_API = `utils/check_phone_exists`
const CHANGE_PASSWORD_API = `auth/change_password`

class AuthChangePassword extends Component {
  state = {
    step: 1,
    isLoading: false,
    showPassword: false,
    country_code: this.props.user ? this.props.country_code : 91,
    phone: this.props.user ? this.props.user.phone : ``,
    password: "",
    otp: "",
    otpSend: false,
    result: null,
  }

  handleChange = (name) => (event) => {
    let user = Object.assign({}, this.state.user)
    user[name] = event.target.value
    this.setState({ [name]: event.target.value })
  }

  renderTextField = (name, label) => {
    const { classes } = this.props

    return (
      <TextField
        key={name}
        label={label}
        className={classes.textField}
        value={this.state[name] || ``}
        onChange={this.handleChange(name)}
        fullWidth
      />
    )
  }

  checkPhone = () => {
    this.setState({ isLoading: true, result: null })
    const { phone, country_code } = this.state
    const { dispatch } = this.props
    instance.post(CHECK_PHONE_API, { phone, country_code }).then((result) => {
      this.setState({ isLoading: false })
      const { res, exists } = result
      if (res !== 1) {
        this.setState({ result })
        return
      }
      if (!exists)
        dispatch(
          pushMsg(
            "Sorry! This phone number is not registered with us.",
            "error"
          )
        )
      else this.setState({ step: 2 })
    })
  }

  changePassword = () => {
    this.setState({ isLoading: true, result: null })
    const { phone, country_code, password, otp } = this.state
    const { dispatch, history } = this.props
    instance
      .post(CHANGE_PASSWORD_API, { phone, country_code, password, otp })
      .then((result) => {
        this.setState({ isLoading: false })

        const { res, msg } = result
        if (res !== 1) {
          this.setState({ result })
          return
        }
        dispatch(pushMsg(msg, "success"))
        history.replace("/authentication")
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { step } = this.state
    if (step === 1) this.checkPhone()
    else this.changePassword()
  }

  title = () => {
    return this.state.step === 1 ? "Set your new password" : "New password"
  }

  renderPassword = () => (
    <Input
      fullWidth
      style={{ marginTop: 20 }}
      placeholder="Enter your password"
      type={this.state.showPassword ? "text" : "password"}
      value={this.state.password}
      onChange={(event) => this.setState({ password: event.target.value })}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() =>
              this.setState((state) => ({ showPassword: !state.showPassword }))
            }
          >
            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  )

  renderPhoneInput = () => {
    const { phone, country_code } = this.state

    return (
      <PhoneInput
        onCountryChange={this.handleChange("country_code")}
        onPhoneChange={this.handleChange("phone")}
        phone={phone}
        country_code={country_code}
      />
    )
  }

  renderOtp = () => {
    return (
      <TextField
        margin="normal"
        required
        fullWidth
        label="OTP"
        helpertext="OTP sent to Mobile"
        value={this.state.otp}
        onChange={(event) => this.setState({ otp: event.target.value })}
      />
    )
  }

  render() {
    const { classes } = this.props
    const { isLoading, result, phone, step, country_code } = this.state

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="subheading" align="center" color="primary">
            {this.title()}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            {step === 1 && (
              <PhoneInput
                onCountryChange={this.handleChange("country_code")}
                onPhoneChange={this.handleChange("phone")}
                phone={phone}
                country_code={country_code}
              />
            )}

            {step === 2 && this.renderOtp()}
            {step === 2 && this.renderPassword()}
            {step === 2 && (
              <OtpComponent country_code={country_code} phone={phone} />
            )}
            <FormResult result={result} />
            <FormButton
              label="Submit"
              fullWidth
              loading={isLoading}
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
            />
          </form>
        </Paper>
      </main>
    )
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  editPhoneButton: {
    float: "right",
    margin: 0,
  },
})

const mapStateToProps = ({ auth }) => {
  const { user } = auth
  return { user }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(AuthChangePassword))
)
