import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { instance } from "../../"
import { pushMsg } from "../../../navigation/actions"
import { FormResult } from "../../form"
const API_OTP = `utils/send_otp/`

class OtpComponent extends Component {
  state = {
    isLoading: false,
  }

  componentWillMount = () => this.sendOtp()

  sendOtp = () => {
    this.setState({ isLoading: true })
    let { country_code, phone, dispatch } = this.props
    instance.post(API_OTP, { country_code, phone }).then((result) => {
      this.setState({ isLoading: false })
      if (result.res != 1) this.setState(result)
      else dispatch(pushMsg(result.msg))
    })
  }

  render() {
    let { isLoading, result } = this.state
    return (
      <div style={{ textAlign: "right" }}>
        <FormResult result={result} />
        {isLoading && (
          <Typography variant="caption" color="textSecondary">
            sending OTP...
          </Typography>
        )}
        {!isLoading && (
          <Button onClick={this.sendOtp} variant="text">
            {" "}
            Resend OTP{" "}
          </Button>
        )}
      </div>
    )
  }
}

const styles = () => ({})

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps)(withStyles(styles)(OtpComponent))
