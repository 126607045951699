import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core"

const AppUpdate = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (userAgent.includes("wv")) {
      if (!userAgent.includes("monkvyasa")) {
        setIsOpen(true)
      }
    }
  }, [])

  return (
    <Dialog open={isOpen} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>New Update!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update your app to get access to new features!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          href="https://play.google.com/store/apps/details?id=com.monkvyasa"
          target="_blank"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppUpdate
