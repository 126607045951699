import { instance } from "../../utils"
const SAVE_REQ_API = `requirement/update`
const FETCH_REQ_ROW_API = `requirement/get_row`
const FETCH_REQ_ROWS_API = `requirement/get_rows`

const shapeArrayColumn = (column) => {
  if (column instanceof Array) return column
  if (column == null || column == undefined) return []
  return column.split(",").map((item) => `${item}`)
}

export const pushBooking = (row) => (dispatch, getState) => {
  row.requirement_bds = shapeArrayColumn(row.requirement_bds)
  row.requirement_languages = shapeArrayColumn(row.requirement_languages)
  row.requirement_status = row.requirement_status
    ? parseInt(row.requirement_status)
    : 1
  return dispatch({
    type: "PUSH_REPORT_BOOKING",
    payload: { row },
  })
}

export const removeBooking = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_REQUIREMENT_ROW",
    payload: id,
  })
}

export const updateRequirement = (rowId, row) => (dispatch) => {
  row.requirement_bds = shapeArrayColumn(row.requirement_bds)
  row.requirement_languages = shapeArrayColumn(row.requirement_languages)
  row.requirement_status = row.requirement_status
    ? parseInt(row.requirement_status)
    : 1
  return dispatch({
    type: "UPDATE_REQUIREMENT",
    payload: { rowId, row },
  })
}

export const updateField = (rowId, field, value) => (dispatch) => {
  return dispatch({
    type: "UPDATE_REQUIREMENT_COLUMN",
    payload: { rowId, field, value },
  })
}

export const clearUnsavedBookings = () => (dispatch) => {
  return dispatch({
    type: "CLEAR_REQUIREMENT_NOT_SAVED",
  })
}

export const updateRequirementBD = (rowId, birth_id) => (dispatch) => {
  return dispatch({
    type: "UPDATE_REQUIREMENT_BD",
    payload: { rowId, birth_id },
  })
}

export const removeRequirementBD = (birth_id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_REQUIREMENT_BD",
    payload: { birth_id },
  })
}

export const saveReqRowDB = (row) => (dispatch) => {
  dispatch(updateField(row.requirement_id, "isLoading", true))
  dispatch({
    type: "SAVE_REQUIREMENT",
    payload: instance({
      method: "post",
      url: SAVE_REQ_API,
      data: row,
    }).then((result) => {
      let { requirement_id_old, requirement_id_new } = result
      if (requirement_id_old === requirement_id_new) {
        dispatch(updateField(row.requirement_id, "isLoading", false))
        return
      }
      dispatch({
        type: "UPDATE_REQUIREMENT_ID",
        payload: {
          id_old: `${requirement_id_old}`,
          id_new: `${requirement_id_new}`,
        },
      })
    }),
  })
}

export const fetchReqRowDB = (requirement_id) => (dispatch) => {
  dispatch(updateField(requirement_id, "isLoading", true))
  dispatch({
    type: "FETCH_REQUIREMENT",
    payload: instance({
      method: "post",
      url: FETCH_REQ_ROW_API,
      data: { filter: { requirement_id } },
    }).then(({ row }) => {
      dispatch(updateField(requirement_id, "isLoading", false))
      dispatch(updateRequirement(requirement_id, row))
    }),
  })
}

export const fetchReqGroupDB = (requirement_group_ref) => (
  dispatch,
  getState
) => {
  let user_id = getState().auth.user.id
  let ids = getState().requirement.ids
  dispatch({
    type: "FETCH_REQUIREMENT_GROUP",
    payload: instance({
      method: "post",
      url: FETCH_REQ_ROWS_API,
      data: { filter: { requirement_group_ref, user_id } },
    }).then(({ rows }) => {
      Object.values(rows).map((row) => {
        let id = row.requirement_id
        if (ids.includes(id)) dispatch(updateRequirement(id, row))
        else dispatch(pushBooking(row))
      })
    }),
  })
}

export const fetchReqRowsDB = () => (dispatch, getState) => {
  let user_id = getState().auth.user.id
  let ids = getState().requirement.ids
  dispatch({
    type: "FETCH_REQUIREMENTS",
    payload: instance({
      method: "post",
      url: FETCH_REQ_ROWS_API,
      data: { order_by: "report_confirm_date-desc", filter: { user_id } },
    }).then(({ rows }) => {
      Object.values(rows).map((row) => {
        let id = row.requirement_id
        if (ids.includes(id)) dispatch(updateRequirement(id, row))
        else dispatch(pushBooking(row))
      })
    }),
  })
}
