import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

class RequirementDisplay extends Component {
  render() {
    let { requirement } = this.props
    return <Typography>{requirement.requirement_requirement}</Typography>
  }
}

const styles = () => ({})

const mapStateToProps = (
  { requirement: requirementList },
  { requirement_id }
) => {
  let requirement = requirementList.rows[requirement_id]
  return { requirement, requirement_id }
}
export default connect(mapStateToProps)(withStyles(styles)(RequirementDisplay))
