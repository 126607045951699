import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import NotificationsIcon from "@material-ui/icons/Notifications"
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge"

import { instance } from "../../../utils/functions"
import { ModalComponent } from "../../../utils/components"
import NotificationList from "./list"
const API = `notification/notification_data/get_count`

class DefaultComponent extends Component {
  state = {
    total: 0,
    modalOpen: false,
  }

  _setState = (newState) => this.setState(newState)

  componentWillMount = () => this.fetchCount()

  fetchCount = () => {
    let { user_id } = this.props
    let filter = {
      1: { name: "user_id", value: user_id },
      2: { name: "notification_read!=", value: 1 },
    }
    instance.post(API, { filter }).then(({ total }) => {
      this.setState({ total })
    })
  }

  render() {
    return (
      <React.Fragment>
        <IconButton
          color="inherit"
          onClick={() => this.setState({ modalOpen: true })}
        >
          <Badge badgeContent={this.state.total} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <ModalComponent
          title="Notifications"
          modalOpen={this.state.modalOpen}
          content={() => <NotificationList _setState={this._setState} />}
          _setState={this._setState}
        />
      </React.Fragment>
    )
  }
}

const styles = () => ({})

const mapStateToProps = ({ auth }) => {
  let user_id = auth.user.id
  return { user_id }
}
export default connect(mapStateToProps)(withStyles(styles)(DefaultComponent))
