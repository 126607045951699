import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { withRouter } from "react-router-dom"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import {
  getUploadImge,
  convertMysqlDateTime,
  createUrl,
  callDuration,
} from "../../../utils/functions"
import PhonecallUpdator from "../components/updator"

class PhonecallListRow extends Component {
  showBooking = () => {
    let { history, row } = this.props
    history.push(
      createUrl("/phonecall/interaction", { phonecall_id: row.phonecall_id })
    )
  }

  renderContent = () => {
    const { classes, row, astrologer, phone_call_status } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} className={classes.leftGrid}>
          <Avatar
            src={getUploadImge(astrologer.image, "user/avatar")}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={9} className={classes.rightGrid}>
          <Typography className={classes.name}>
            {astrologer.first_name}
          </Typography>
          <Typography color="primary" variant="subheading">
            Duration : {callDuration(row.call_duration)}
          </Typography>

          <Typography variant="body1" color="secondary">
            {phone_call_status[row.call_status]}
          </Typography>
          <Typography color="textSecondary">
            {convertMysqlDateTime(row.call_join_date, true)}
          </Typography>
          <PhonecallUpdator
            visualise
            row={row}
            call_sid={row.call_sid}
            updateRow={(row) => this.setState({ row })}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    if (!this.props.astrologer) return null
    let { classes } = this.props
    return (
      <Card style={{ marginTop: 5, marginBottom: 5 }}>
        <CardContent>{this.renderContent()}</CardContent>
        <CardActions
          disableActionSpacing
          style={{ justifyContent: "flex-end", paddingTop: 0 }}
          onClick={this.showBooking}
        >
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            onClick={this.showBooking}
          >
            View <ChevronRightIcon />
          </Button>
        </CardActions>
      </Card>
    )
  }
}

const styles = (theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "0 auto",
    maxWidth: 140,
  },
  leftGrid: {
    padding: 10,
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  name: {
    color: "#31708f",
    fontSize: 18,
  },
  actionButton: {
    padding: "0 20px",
  },
})

const mapStateToProps = ({ astrologers, enums }, { row }) => {
  let { astrologer_id } = row
  let astrologer = astrologers.rows[astrologer_id]
  let phone_call_status = enums.rows.phone_call_status.vt
  return {
    astrologer,
    phone_call_status,
  }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PhonecallListRow))
)
