import { instance } from "../../utils"
import { history } from "../../store"
import { pushMsg } from "../../navigation/actions"
const FETCH_AUTH_API = `auth/is_logged_in`
const LOGOUT_API = `auth/logout`
const ASTROLOGER_URL = "https://www.astrologer.monkvyasa.com"

export const fetchAuth = (data = {}) => (dispatch) => {
  dispatch({
    type: "FETCH_AUTH",
    payload: instance({
      method: "post",
      url: FETCH_AUTH_API,
      data: data,
    }).then((result) => {
      if (result.res === 1) {
        if (parseInt(result.grouped_in) === 2) {
          dispatch(pushMsg("Redirecting to site...", "info"))
          window.location.replace(ASTROLOGER_URL)
          return
        }
      }

      dispatch({
        type: "FETCH_AUTH_DONE",
        payload: result,
      })
    }),
  })
}

export const refreshAuth = (data = {}) => (dispatch) => {
  dispatch({
    type: "REFRESH_AUTH",
    payload: instance({
      method: "post",
      url: FETCH_AUTH_API,
      data: data,
    }).then((result) => {
      if (result.res === 1) {
        if (parseInt(result.grouped_in) === 2) {
          dispatch(pushMsg("Redirecting to site...", "info"))
          window.location.replace(ASTROLOGER_URL)
          return
        }
      }

      dispatch({
        type: "FETCH_AUTH_DONE",
        payload: result,
      })
    }),
  })
}

export const logOut = () => (dispatch) => {
  dispatch({
    type: "RESET_BD",
    payload: {},
  })
  dispatch({
    type: "LOGOUT",
    payload: instance({
      url: LOGOUT_API,
    }),
  }).then(() => {
    history.replace("/")
  })
}
