import React, { useState } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import PhoneHelp from "../../../utils/components/misc/phoneHelp"
import ReportHelp from "../../../utils/components/misc/reportHelp"
import SearchIcon from "@material-ui/icons/Search"
import FilterListIcon from "@material-ui/icons/FilterList"
import HelpIcon from "@material-ui/icons/Help"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 5,
    backgroundColor: "#fde1f5",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  helpButton: {
    //background : '#e7d8ec',
    border: "1px solid #ebc9f7",
    margin: "0 auto",
  },
}

function CustomizedInputBase(props) {
  const { classes, applyStringFilter, _setState } = props
  const [help, setHelp] = useState(false)

  const renderHelperBar = () => (
    <Grid container style={{ marginBottom: 5 }}>
      <Grid item xs={6} style={styles.helpButton}>
        <PhoneHelp />
      </Grid>
      <Grid item xs={6} style={styles.helpButton}>
        <ReportHelp />
      </Grid>
    </Grid>
  )

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={1}>
        <IconButton className={classes.iconButton}>
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Search Astrologers"
          onChange={(event) => applyStringFilter("name", event.target.value)}
        />
        <Divider className={classes.divider} />
        <IconButton
          className={classes.iconButton}
          onClick={() => setHelp(!help)}
        >
          {help ? <HelpIcon /> : <HelpOutlineIcon />}
        </IconButton>
        <Divider className={classes.divider} />
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={() => _setState({ modalOpen: true })}
        >
          <FilterListIcon />
        </IconButton>
      </Paper>
      {help && renderHelperBar()}
    </React.Fragment>
  )
}

CustomizedInputBase.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CustomizedInputBase)
