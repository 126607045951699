import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { pushBooking, clearUnsavedBookings } from "../actions"
import { guid, createUrl } from "../../../utils/functions"

class CustomSelectServices extends Component {
  state = {
    bookings: [],
  }

  componentWillMount = () => this.props.dispatch(clearUnsavedBookings())

  handleClick = (row) => () => {
    const requirement_group_ref = guid()
    const { dispatch, astrologer, id, history } = this.props
    const bookingRow = { mr_service_id: 5, acs_id: row.acs_id }
    const rowExtend = {
      requirement_id: guid(),
      astrologer_id: id,
      requirement_bds: null,
      requirement_requirement: "",
      requirement_languages: astrologer.languages,
      requirement_group_ref: requirement_group_ref,
      requirement_status: 1,
      isSaved: false,
    }
    const booking = Object.assign({}, bookingRow, rowExtend)
    dispatch(pushBooking(booking))
    history.push(createUrl("/requirement/create", { requirement_group_ref }))
  }

  renderRow = (row) => {
    const { classes, astrologer } = this.props
    return (
      <div key={row.acs_id} style={{ marginBottom: 20 }}>
        <div className={classes.row}>
          <div className={classes.column} style={{ flexGrow: 4 }}>
            <Typography variant="subtitle2">{row.acs_name}</Typography>
          </div>
          <div
            className={`${classes.column} ${classes.columnRate}`}
            style={{ flexGrow: 1 }}
          >
            {`${row.acs_service_rate} ${astrologer.currency}`}
          </div>
        </div>
        <Divider />
        <Typography variant="caption" style={{ margin: 10 }}>
          {row.acs_description}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClick(row)}
        >
          Book Now
        </Button>
      </div>
    )
  }

  render() {
    let { custom_services } = this.props
    return (
      <Card>
        <CardContent>
          {!custom_services.length && (
            <Typography color="secondary">Not available now!</Typography>
          )}
          {custom_services.map((x) => this.renderRow(x))}
        </CardContent>
      </Card>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  proceedButton: {
    backgroundColor: "#37be5f",
  },
  row: {
    display: "flex",
    padding: "2px 10px",
    alignItems: "center",
    justifyItems: "center",
    borderBottom: "1px solid #f6f2f9",
  },
  columnRate: {
    textAlign: "right",
  },
})

const mapStateToProps = ({ astrologers, enums }, { id }) => {
  let astrologer = astrologers.rows[id]
  let custom_services = astrologer.custom_services
  return { astrologer, custom_services }
}
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(CustomSelectServices))
)
